import React from 'react';
import HeadingLevel from 'components/utility-components/heading-level';
import Copy from 'components/utility-components/copy';
import './footer.scss';

const FooterContent = ({ content }) => content.map((text, index) => {
  const key = `footer-${index}`;
  return <p key={key}><Copy>{text}</Copy></p>;
});

const Footer = ({ content }) => (
  <footer className="footer">
    <HeadingLevel titleLevel="h2" title={content.header} />
    <FooterContent content={content.body} />
  </footer>
);

export default Footer;
