import { createStore } from 'satcheljs';
import { mmaFeesTable, mmaRegDFeesTable } from 'stores/pages/shared';
import { device } from 'utilities';

const isDroid = device === 'android';

const tierInfo = {
  tier1: 'Less than $5,000 minimum daily balance',
  tier2: '$5,000 minimum daily balance',
  tier3: '$25,000 minimum daily balance',
};

const moneyMarketContent = {
  hero: {
    pageTitle: 'Money Market Savings',
    config: {
      bottomColor: 'money-market',
      template: 'product-page-header',
      product: 'moneyMarket',
    },
    content: {
      rateText: `%s${isDroid ? '++%++' : '%'} APY`,
      reviewText: '%s (%s Reviews)',
      a11yText: 'Rated %s out of 5 stars, based on %s reviews.',
      tiers: {
        ios: [
          'Less than $5,000',
          '$5,000 Minimum',
          '$25,000 Minimum',
        ],
        android: [
          'Less than $5,000',
          '$5,000 Minimum',
          '$25,000 Minimum',
        ],
      },
      tierInfo,
      hiddenText: tierInfo,
      cta: {
        text: 'Open Account',
        url: 'ally://account_opening/MMDA',
      },
    },
    disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s.',
  },
  intro: {
    title: 'Smarter savings.',
    ptClassName: 'padding-bottom',
    titleLevel: 'h2',
    className: 'not-nested',
    copy: 'Our rates are consistently among the most competitive in the country, and our Money Market account is available in the name of a @@Trust::https://www.ally.com/learn/trust-account/::@@. Keep in mind, rates are variable and may change after you open the account, and fees may reduce earnings.',
  },
  bullets: [
    'No minimum deposit required to open your account',
    'Grow your money faster with ##interest compounded daily::compoundedInterest::##',
    'Unlimited deposits and ATM withdrawals, and up to ##6 additional transactions::sixTransfersAndWithdrawals::##',
    'Your deposits are insured by the <span data-sr-only>F.D.I.C.</span><span aria-hidden="true">FDIC</span> up to the maximum allowed by law. @@Maximize your coverage::https://www.ally.com/bank/fdic/::@@',
  ],
  // TODO: Cleanup as part of STOR-46749
  bulletsFlag: {
    name: 'FF_reg-d-2023',
    list: [
      'No minimum deposit required to open or earn APY',
      'Grow your money faster with ##interest compounded daily::compoundedInterest::##',
      'Unlimited deposits and ATM withdrawals, and up to ##10 additional transactions::aboutTheTransactionLimit::##',
      'Your deposits are insured by the <span data-sr-only>F.D.I.C.</span><span aria-hidden="true">FDIC</span> up to the maximum allowed by law. @@Maximize your coverage::https://www.ally.com/bank/fdic/::@@',  
    ],
  },
  favItems: {
    title: 'our favorite features',
    cards: [
      {
        icon: 'savings/peaceofmind.svg',
        title: 'Peace of mind',
        copy: 'Our award-winning customer care is available 24/7.',
      },
      {
        icon: 'savings/getcash.svg',
        title: 'Get Cash',
        copy: `Use any Allpoint++®++ ATM in the U.S. for free, plus we reimburse up to $10 per statement cycle for fees charged at other ATMs nationwide. ${device === 'ios' ? '@@Find ATMs::https://www.ally.com/bank/find-atms/::@@' : ''}`,
        cardLink: '@@Find ATMs::https://www.ally.com/bank/find-atms/::@@',
      },
      {
        icon: 'savings/movemoney.svg',
        title: 'Move money',
        copy: 'Transfer funds between your Ally Bank accounts or accounts at other institutions.',
      },
      {
        icon: 'savings/makedeposits.svg',
        title: 'Make deposits',
        copy: 'Deposit checks remotely with @@Ally eCheck Deposit℠::https://www.ally.com/bank/online-banking/how-to-bank-with-ally/::@@, or transfer money from an account you have at another institution.',
      },
      {
        icon: 'savings/spendwisely.svg',
        title: 'Spend Wisely',
        copy: 'With Card Controls in our app, you can monitor and manage your Debit MasterCard with EMV chip technology you can trust.',
      },
    ],
  },
  tableSections: mmaFeesTable,
  tableSectionsFlag: {
    name: 'FF_reg-d-2023',
    sections: mmaRegDFeesTable,
  }
};

export default createStore('moneyMarketContent', moneyMarketContent);
