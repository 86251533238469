import React, { memo } from 'react';

const SubCopy = memo(({ copy, column }) => {
  const colAttrs = {
    className: copy && 'row-with-sub-copy',
    'aria-hidden': true,
  };

  return (
    <span {...colAttrs}>
      {column}
      {copy && <p className="table-sub-copy">{copy}</p>}
    </span>
  );
});

export default SubCopy;
