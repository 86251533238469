// TODO - I don't believe this needs to be a component, and instances of this
//        component can be replaced with the utility
import React from 'react';
import { swapContent } from 'utilities';

// 'children' should be a string, 'dynamicContent' can be either a string or an array
const DynamicContent = ({ children, dynamicContent }) => (
  <React.Fragment>
    {swapContent(children, dynamicContent)}
  </React.Fragment>
);

export default DynamicContent;
