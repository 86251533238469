import React from 'react';
import resolveImagePath from 'utilities/resolve-image-path';
import Copy from '../../utility-components/copy';

const CustomIcon = ({
  info, bulletIcon,
}) => {
  if (!info) {
    return null;
  }

  return (
    <span className="custom-icon">
      {info.map(listItem => (
        // Although redundant - ul and li roles are needed to fix Safari A11y
        // bug where it doesn't read as a list when ul is set to display: inline
        <li role="listitem" key={`CustomIcon-${listItem}`} className="listItem">
          <div className="imgContainer" aria-hidden="true">
            <img alt=" " src={resolveImagePath(bulletIcon)} />
          </div>
          <Copy>{listItem}</Copy>
        </li>
      ))}
    </span>
  );
};

export default CustomIcon;
