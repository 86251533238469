import React from 'react';
import { productStore } from 'stores';
import ListItem from './list-item';

const RenderList = ({ match, history }) => {
  const { params } = match;
  const { product, currentTerm } = params;
  const { terms } = productStore()[product];
  const attrs = { history, match, currentTerm };

  // index will be used to target first li in a future TODO
  return Object.keys(terms).reverse().map(key => <ListItem key={`list-item-${key}`} id={key} {...attrs} />);
};

export default RenderList;
