import React from 'react';
import resolveImagePath from 'utilities/resolve-image-path';
import Copy from '../utility-components/copy';
import SimpleList from '../simple-list';
import './valueprop-with-list.scss';

/**
 * Creates an image with a list from Props passed in through data store
 *
 * @param {String} title centered and bolded title for component `Check out this cool product`
 * @param {path} imgSrc path to image to go along side of the list `other/STK-EPR-Graph-OSA.png`
 * @param {String} imgClassName className to apply to image `img-left`
 * @param {String} copy paragraph on the right of the image (rather than bullets) `Paragraph text`
 *
 * @returns JSX element with image and copy styling
 */
const outerClass = 'listContainer';

const ValuePropWithList = ({
  title, copy, linkText, imgSrc, list, description,
}) => (
  <div className="value-prop-with-list-container">
    <div className="content">
      {imgSrc && (
        <div className="img-container-stk">
          <img alt=" " aria-hidden="true" src={resolveImagePath(imgSrc)} />
        </div>
      )}
      <div className="copy-wrapper">
        {title && <h3 className="title"><Copy>{title}</Copy></h3>}
        {copy && <p className="copy"><Copy>{copy}</Copy></p>}
        {linkText && <p className="linkText"><Copy>{linkText}</Copy></p>}
        {description && <p className="copy description"><Copy>{description}</Copy></p>}
      </div>
      {list && (
        <SimpleList
          ulClassName={outerClass}
          liClassName="listItem"
          info={list}
        />
      )}
    </div>
  </div>
);

export default ValuePropWithList;
