import React from 'react';
import ContentLoader from 'components/utility-components/content-loader';
import DynamicContent from 'components/utility-components/dynamic-content';
import './disclaimer.scss';

const Disclaimer = ({
  className,
  flag = 'rate',
  disclaimer,
  effectiveDate,
  failTextLabel,
  id,
  focusType,
}) => {
  const contentClass = `disclaimer-component ${className || ''}`;

  return (
    <ContentLoader label={failTextLabel} focusType={focusType} className={contentClass} flag={flag} id={id} loadAction="tapOnFail">
      <p>
        <DynamicContent dynamicContent={effectiveDate}>
          {disclaimer}
        </DynamicContent>
      </p>
    </ContentLoader>
  );
};

export default Disclaimer;
