// The rates that we will need for our application
const desiredProducts = [
  {
    productName: 'iraOnlineSavings',
    productCode: 'IRAOSA',
    ratingCode: 'iraosa',
  },
  {
    productName: 'checking',
    productCode: 'DDA',
    ratingCode: 'ckg',
  },
  {
    productName: 'onlineSavings',
    productCode: 'OSAV',
    ratingCode: 'osa',
  },
  {
    productName: 'moneyMarket',
    productCode: 'MMDA',
    ratingCode: 'mnymkt',
  },
  {
    productName: 'iraHighYield',
    productCode: 'IRAHY',
    ratingCode: 'irahycd',
  },
  {
    productName: 'cd',
    productCode: 'CD',
    // CDs and RYRCDs are in the same product code,
    // so separate them based on term ( RYRCDs are only 2 and 4 years )
    include: [3, 6, 9, 12, 15, 18, 36, 60],
    ratingCode: 'hycd',
  },
  {
    productName: 'noPenaltyCD',
    productCode: 'NCD',
    ratingCode: 'npcd',
  },
  {
    productName: 'iraRaiseYourRate',
    productCode: 'IRARYR',
    ratingCode: 'iraryrycd',
  },
  {
    productName: 'raiseYourRateCD',
    productCode: 'CD',
    include: [24, 48],
    ratingCode: 'ryrcd',
  },
];

// get the rates from the response data that corresponds to a given
// product from `desiredProducts`
const getRate = (rates, product) => {
  const { include } = product;
  const keys = Object.keys(rates);
  const result = Object.assign({},
    rates[keys.filter(key => rates[key].productCode === product.productCode)[0]]);

  if (include) {
    result.terms = result.terms.filter(p => include.includes(p.term));
  }

  return result;
};


/**
 * Map the rates from the response data to the products needed
 * for the application
 *
 * @param {*} { prodRates: { products } } - the product response from the products API
 * @returns {Object} the product object that will be used to display rates
 */
function processRates({ prodRates: { products } }) {
  return desiredProducts.map((product) => {
    const { effectiveDate } = products.find(({ productCode }) => productCode === product.productCode) || {};
    const { terms } = getRate(products, product);
    return {
      ...product,
      rates: {
        terms,
        effectiveDate,
      },
    };
  });
}

export default processRates;
