// capitalize first letter of each segment
const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

// turn sentence into camelCase
// E.G. "Open_Now... for a FREE PRIZE!!" -> "openNowForAFreePrize"
const toCamelCase = str => str.toLowerCase()
// strip special chars, replace with space
  .replace(/[^a-zA-Z ]/g, ' ')
  .split(' ')
  .map((seg, idx) => {
    if (idx) {
      return capitalize(seg);
    }
    return seg;
  })
  .join('');

export default toCamelCase;