/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React from 'react';
import { productStore } from 'stores';
import { observer } from 'mobx-react';
import mboxStore from 'stores/mbox';
import MarketingHero from '../marketing-hero';
import RateTableHero from '../rate-table-hero';
import HeroCard from '../hero-card';

const JsonOffer = ({ offerName, pageTitle, children: fallbackComponent }) => {
  const offer = mboxStore()[offerName];

  if (!offer) return fallbackComponent;

  switch (offer.type) {
    case 'RATE_TABLE':
      const { productName, term = 0, disclosure } = offer;
      const productInfo = productStore()[productName];
      // lookup product data that was not directly returned by json offers call
      const { effectiveDate, ratingInfo: { overallRating, reviewCount } } = productInfo;
      const { apy } = productInfo.terms[`${term}M`].tier1; // have to hardcode 'M'? no tier?
      const rateTableAttrs = {
        overallRating,
        reviewCount,
        effectiveDate,
        apy,
        disclosure,
        ...offer,
      };
      return (
        // future decision: should these always be wrapped in hero cards, or only sometimes?
        <HeroCard pageTitle={pageTitle}>
          <RateTableHero {...rateTableAttrs} />
        </HeroCard>
      );
    case 'MARKETING':
      return (
        <HeroCard pageTitle={pageTitle}>
          <MarketingHero {...offer} />
        </HeroCard>
      );
    default:
      return fallbackComponent;
  }
};

export default observer(JsonOffer);
