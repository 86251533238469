/**
 * Request the contents of an adobe mbox.
 * Provide the name of the adobe mbox and optionally and add default content
 *
 * Default will be returned if:
 * 1. window.adobe.target.getOffer() is not available
 * 2. Target executes the error callback (error to console)
 * 3. Target returns a non-array object
 * 4. Target response does not contain a setJson entry
 * 5. The async call takes more than maxWaitTime to respond
 *
 * @async
 * @param {string} mboxName
 * @param {obj} defaultContent
 */
const fetchMboxContent = ({ mboxName, defaultContent = {} }) => {
  const { adobe } = window;
  const getOffer = adobe && adobe.target && adobe.target.getOffer;
  const maxWaitTime = 5000;

  return new Promise((resolve) => {
    const resolveDefault = () => resolve(defaultContent);

    if (!getOffer) return resolveDefault();

    const timerHandle = setTimeout(() => resolveDefault(), maxWaitTime);

    // Tease out the actual json data from the response
    const getJson = (response) => {
      clearTimeout(timerHandle);
      if (!Array.isArray(response)) return resolveDefault();
      const jsonSegment = response.find(e => e.action === 'setJson');
      const jsonContent = jsonSegment
        && Array.isArray(jsonSegment.content)
        && jsonSegment.content[0];

      if (jsonContent) return resolve(jsonContent);

      resolveDefault();
    };

    return getOffer({
      mbox: mboxName,
      success: getJson,
      error: (status, err) => {
        clearTimeout(timerHandle);
        // eslint-disable-next-line no-console
        console.error(`getOffer[${mboxName}]: `, status, err);
        resolveDefault();
      },
    });
  });
};

export default fetchMboxContent;

/** Typical response body
 *
  [
    {
      "action": "signalClick",
      "clickTrackId": "315440"
    },
    {
      "action": "setJson",
      "content": [
        {
          "aoShouldShowEmployerContextualHelp": true,
          "aoRightRailTreatment": true
        }
      ]
    }
  ]
 */
