import React from 'react';

// import Copy from 'components/utility-components/copy';
import ContentLoader from 'components/utility-components/content-loader';
import StarRating from 'components/star-rating';
import Button from 'components/button';
import resolveImagePath from 'utilities/resolve-image-path';
import { device } from 'utilities';
import HeadingLevel from '../utility-components/heading-level';

import './product-header.scss';

const isIos = device === 'ios';
const isAndroid = device === 'android';

const Img = ({ image }) => <img src={resolveImagePath(image)} alt="" />;

// convenience for keeping logic out of template
const NullComponent = () => null;

const RatingContent = ({ overallRating }) => (
  <p className="rating-text sr-text-parent review-content">
    <span className="sr-text review-content">{`Rated ${overallRating} out of 5 stars.`}</span>
    <span aria-hidden="true">{overallRating}</span>
  </p>
);

const Rating = ({ product, reviewCount, overallRating }) => (
  <ContentLoader className="ratings-section hidden-on-fail" flag="rating">
    <StarRating product={product} reviewCount={reviewCount} rating={overallRating} />
    <RatingContent {...{ overallRating }} />
  </ContentLoader>
);

const ProductHeader = ({
  includeRating = true,
  product,
  reviewCount,
  overallRating,
  cta = {},
  titleLevel,
  header,
  className,
  image,
  subheading,
}) => {
  const headingClass = `product-header ${!includeRating ? 'header-no-rating' : ''}`;
  const ProductRating = includeRating ? Rating : NullComponent;
  const ProductButton = cta.url ? Button : NullComponent;
  const DroidImg = image && isAndroid ? Img : NullComponent;
  const { url, text, label } = cta;
  const headingLevel = {
    titleLevel: titleLevel || 'h2',
    titleClass: className,
    title: header,
  };

  return (
    <div className={headingClass}>
      <div className="container">
        <div className="product-content">
          <DroidImg {...{ image }} />
          <HeadingLevel {...headingLevel} />
          <ProductRating {...{ product, reviewCount, overallRating }} />
          {subheading && <span className="product-intro">{subheading}</span>}
        </div>
        {isIos && <ProductButton {...{ to: url, label, button: text }} />}
      </div>
    </div>
  );
};

export default ProductHeader;
