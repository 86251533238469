import React from 'react';

/**
 * Formats a passed in rate and appends `% APY` to the end
 *
 * @param {number} rate just the decimal rate `2.20`
 */

const RateContent = ({ rate }) => (
  <React.Fragment>
    {rate}
    <sup>%</sup>
    {' APY'}
  </React.Fragment>
);

export default RateContent;
