import { Route, Switch } from 'react-router-dom';
import React from 'react';
import investContent from 'stores/pages/invest';
import Category from 'pages/explore/category';
import { updateDDO } from 'components/utility-components/track';
import { lazyWithPreload } from '../../utilities';

const SelfDirectedTrading = lazyWithPreload(() =>
  import('pages/explore/products/invest/self-directed-trading'),
)();
const PersonalAdvice = lazyWithPreload(() =>
  import('pages/explore/products/invest/personal-advice'),
)();
const RoboPortfolios = lazyWithPreload(() =>
  import('pages/explore/products/invest/robo-portfolios'),
)();
const pageTitle = 'Ally Invest';
const lineOfBusiness = 'Invest';
// Invest
const INV = (props) => {
  updateDDO({ pageName: pageTitle, lineOfBusiness });
  return <Category title={pageTitle} categoryContent={investContent} {...props} />;
};

const SDT = (props) => {
  updateDDO({ pageTitle, pageName: 'Self-Directed Trading', lineOfBusiness });
  return <SelfDirectedTrading {...props} />;
};

const RP = (props) => {
  updateDDO({ pageTitle, pageName: 'Robo Portfolios', lineOfBusiness });
  return <RoboPortfolios {...props} />;
};

const WM = (props) => {
  updateDDO({ pageTitle, pageName: 'Personal Advice', lineOfBusiness });
  return <PersonalAdvice {...props} />;
};

const InvestRouter = ({ match: { url } }) => (
  <Switch>
    <Route exact path={url} render={INV} />
    <Route exact path={`${url}/self-directed-trading`} render={SDT} />
    <Route exact path={`${url}/personal-advice`} render={WM} />
    <Route exact path={`${url}/robo-portfolios`} render={RP} />
  </Switch>
);

export default InvestRouter;
