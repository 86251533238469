const getText = ({ innerText }) => innerText.replace(/\s\s+/g, ' ');
const getHref = ({ href }) => href.split('/').pop();
const isString = obj => typeof obj === 'string';

const getChildText = children => {
  if (isString(children)) {
    return children;
  }
  return children.map(({ props: { children } }) => (isString(children) ? children : '')).join('');
};

const linkRoleBtn = {
  updateBy: 'data-track-name',
  valFrom: getText,
  get({ text }) {
    return {
      'data-track-elem': 'link',
      'data-track-name': text,
    };
  },
};

const selectTermLength = {
  updateBy: 'data-track-name',
  valFrom: getText,
  get({ text }) {
    return {
      'data-track-trigger': 'switch',
      'data-track-elem': 'tab',
      'data-track-name': text,
    };
  },
};

const tabControl = {
  updateBy: 'data-track-name',
  valFrom: getText,
  get({ text }) {
    return {
      'data-track-elem': 'tab',
      'data-track-trigger': 'switch',
      'data-track-name': text,
    };
  },
};

const cardLink = {
  dynamic: true,
  updateBy: 'data-track-name',
  valFrom: getText,
  get({ text }) {
    return {
      'data-track-name': text,
    };
  },
};

const standardLink = {
  updateBy: 'data-track-name',
  valFrom: getText,
  get({ text }) {
    return {
      'data-track-name': text,
    };
  },
};

const downloadLink = {
  updateBy: 'data-track-file',
  valFrom: getHref,
  get({ text }) {
    return {
      'data-track-trigger': 'download',
      'data-track-file': text,
    };
  },
};

const interstitialLink = {
  updateBy: 'data-track-name',
  valFrom: getText,
  get({ text }) {
    return {
      'data-track-trigger': 'interstitial',
      'data-track-name': text,
    };
  },
};

const productRegion = {
  updateBy: 'data-track-region',
  valFrom: getText,
  get({ text }) {
    return {
      'data-track-region': text,
    };
  },
};

const dejarg = {
  updateBy: 'data-track-name',
  valFrom: getText,
  get({ text }) {
    return {
      'data-track-name': text,
      'data-track-region': 'dejarg',
    };
  },
};

export {
  linkRoleBtn,
  selectTermLength,
  tabControl,
  cardLink,
  downloadLink,
  interstitialLink,
  productRegion,
  dejarg,
  standardLink,
  getChildText,
  isString,
};
