import React, { Component } from 'react';
import HeadingLevel from 'components/utility-components/heading-level';
import { device } from 'utilities';
import tierVal from './helpers/tierVal';
import TierList from './helpers/TierList';
import './segmented-control.scss';

class SegmentedControl extends Component {
  constructor({ selectedTier = 'tier1' }) {
    super();
    this.state = {
      activePanel: tierVal(selectedTier),
    };
    this.tabClicked = this.tabClicked.bind(this);
  }

  tabClicked(target) {
    const { handleClick } = this.props;
    this.setState({ activePanel: target.key });
    handleClick(target['data-tier-level']);
  }

  render() {
    const {
      tiers,
      tierInfo,
      hiddenText,
      heading,
    } = this.props;
    const { activePanel } = this.state;
    const changeTier = this.tabClicked;
    // The hidden text is only used on controls with three buttons
    // TODO: refactor to not pass tierinfo when it is not needed
    // to render hidden text
    const tierListAttr = {
      tiers: tiers[device] || tiers,
      changeTier,
      activePanel,
      tierInfo,
      hiddenText,
    };

    return (
      <div className="segmented-control">
        {heading && <HeadingLevel {...heading} />}
        <div role="tablist">
          <TierList {...tierListAttr} />
        </div>
      </div>
    );
  }
}

export default SegmentedControl;
