import React from 'react';
import productStore from 'stores/products';
import ContentLoader from 'components/utility-components/content-loader';
import { observer } from 'mobx-react';
import './two-row-text.scss';
import FailedRatePlaceholder from 'components/utility-components/failed-rate-placeholder';

const getApyText = ({ terms }, copy, tier) => `${terms[copy.term][copy[tier]].apy.toFixed(2)}% APY`;

const ApyDisplay = ({ apyText }) => (
  <div className="text-center rate-info">
    {apyText}
  </div>
);


const TwoRowText = ({ copy, product, tier }) => {
  const copyTier = copy[tier] ? tier : 'tier1';
  const store = productStore();
  const { fetched, [product]: terms } = store;
  const { subtext } = copy;

  const didFail = fetched.rate === 'failed';
  const apyText = !didFail ? getApyText(terms, copy, copyTier) : '';
  const CardContent = !didFail ? ApyDisplay : FailedRatePlaceholder;

  return (
    <React.Fragment>
      <ContentLoader className="css-loader-card" flag="rate" loadAction="placeholder">
        <CardContent {...{ apyText }} />
      </ContentLoader>
      <div className="link-text text-center">
        <p>{subtext}</p>
      </div>
    </React.Fragment>
  );
};

export default observer(TwoRowText);
