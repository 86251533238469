import React from 'react';
import { device } from 'utilities';
import Button from 'components/button';

const AndroidButton = ({
  label,
  text,
  url,
}) => {
  if (device !== 'android') {
    return null;
  }

  return <Button label={label} button={text} to={url} />;
};

export default AndroidButton;
