import React from 'react';
import Track from 'components/utility-components/track';
import { RenderLink } from '../index';

const DynamicTrack = ({
  attrs,
  text,
  schema,
  children,
}) => <Track schema={schema} text={text} RenderComponent={RenderLink(attrs, children)} />;

export default DynamicTrack;
