import { createStore } from 'satcheljs';
import fetchLaunchDarklyFlag from '../../../../../utilities/fetch-feature-flags';

const tierInfo = {
  ios: {
    tier1: 'Less than $5,000',
    tier2: 'Between $5,000 and $24,999.99',
    tier3: '$25,000 or more',
  },
  android: {
    tier1: 'Less than $5,000',
    tier2: 'Between $5,000 and $24,999.99',
    tier3: '$25,000 or more',
  },
};

const savingsProduct = {
  hero: {
    pageTitle: 'Savings Account',
    config: {
      template: 'text-with-rate',
      product: 'onlineSavings',
    },
    content: {
      topCopy: 'Your hard-earned money deserves a harder-working savings account.',
      rateSection: {
        rateHeader: 'Annual Percentage Yield',
        rate: '%s',
        extraInfoBelow: 'No minimum balance required to open or earn APY',
        tierInfo: {
          tier1: 'On all balance tiers',
        },
      },
      cta: {
        text: 'Open Account',
        url: 'ally://account_opening/OSAV',
        skin: 'outline',
      },
    },
    disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s.',
  },
  tiers: {
    title: 'Ally Bank Savings Account balance tiers',
    titleLevel: 'h2',
    ptClassName: 'padding-bottom-small',
    className: 'not-nested',
  },
  tiersInfo: [
    'Less than $5,000',
    'Between $5,000 and $24,999.99',
    '$25,000 or more'
  ],
  stkHighlights: {
    description: 'Along with a competitive, variable rate and no monthly maintenance fees, this account comes with tools to grow your money faster. Like any savings account, there’s a limit of six withdrawals per statement cycle, but using our tools won’t count toward those limits.',
    // TODO: Cleanup as part of STOR-46749
    descriptionFlag: {
      name: 'FF_reg-d-2023',
      text: 'Along with a competitive, variable rate and no monthly maintenance fees, this account comes with tools to grow your money faster. Like any savings account, there’s a limit of 10 withdrawals per statement cycle, but using our tools won’t count toward those limits.',
    },
    imgSrc: 'savings/STK-Graph.png',
    imgClassName: 'img-left',
    ulClassName: 'custom-icon',
    bulletIcon: 'other/STK-Checks.svg',
    list: [
      'Use savings buckets to organize your money and visualize what you’re saving for',
      'Set up boosters to optimize and maximize your savings, even if the rate changes after you open the account',
      'Make changes as new priorities arise',
      'Get personalized recommendations to help you save more',
    ],
    listNoFF: [
      'Use buckets to organize your money and visualize what you’re saving for',
      'Set up boosters to optimize and maximize your savings, even if the rate changes after you open the account',
      'Make changes as new priorities arise',
      'Get personalized recommendations to help you save more',
    ],
    flag: 'FF_spending-buckets',

  },
  stkBuckets: {
    modifier: 'top-border',
    title: 'Organize using buckets.',
    subtitle: 'Divvy up your savings without multiple bank accounts or hard math.',
    imgSrc: 'savings/STK-Buckets.svg',
    imgAltText: 'A few examples of buckets: Home improvements. Vet bills. Vacation.',
    imgClassName: 'img-bucket',
    info: 'Like digital envelopes, savings buckets stash your cash for whatever you want (or want to do). Use them to track your progress, all while earning interest on your total balance. Then, carry that organizational savvy into your spending using our ##spending buckets::spendingBuckets::##.',
  },

  stkBoosters: {
    heading: 'Optimize with boosters.',
    subheading: 'Accelerate your savings and put part of your strategy on autopilot.',
    cards: [
      {
        icon: 'savings/icon-stk-recurring.svg',
        title: 'Recurring Transfers',
        content: 'Set it and forget it. Move money into your savings on a schedule that makes sense for you.',
      },
      {
        icon: 'savings/icon-roundUps.svg',
        title: 'Round Ups',
        content: 'We’ll track your Ally Bank Spending Account for transactions we can round up to the nearest dollar. Then transfer the money to your savings once you’ve accrued at least $5.',
        link: '##More about how it works::howRoundUpWorks::##',
      },
      {
        icon: 'savings/icon-stk-savings.svg',
        title: 'Surprise Savings',
        content: 'We analyze your linked checking accounts for safe-to-save money, then transfer it to your savings so you don’t have to.',
      },
    ],
  },

  favoriteFeatures: {
    imgSrc: 'savings/STK-No-Fees.svg',
    title: 'No monthly maintenance fees or minimum balance requirements.',
    copy: 'Keep in mind, with this type of account there’s a limit of 6 withdrawals per statement cycle.',
    linkText: '##Why only 6?::whySix::##',
    // TODO: Cleanup as part of STOR-46749
    flag: {
      name: 'FF_reg-d-2023',
      copy: 'Keep in mind, with this type of account there’s a limit of 10 withdrawals per statement cycle.',
      linkText: '##About the Transaction Limit::aboutTheTransactionLimit::##',
    },
  },

  favoriteFeatures2: {
    imgSrc: 'savings/STK-Interest.svg',
    title: 'Your money earns money with ##interest compounded daily::compoundedInterest::##==.==',
    copy: 'Rate is variable and may change after the account is opened, plus fees may reduce earnings. Deposits are insured by the ##FDIC::fdic::## up to the maximum allowed by law.',
    linkText: '@@More about FDIC coverage::https://www.ally.com/bank/fdic/::@@',
  },

  favoriteFeatures3: {
    imgSrc: 'savings/STK-Support.svg',
    title: '24/7 support.',
    copy: 'Call, chat or email us any time. A real person is always available to help, day or night.',
  },

  tableSections: [
    {
      titleClass: 'top-border',
      heading: 'Fees, a short story.',
      subheading: 'You shouldn’t be nickel and dimed for using your own money.',
      postTitle: 'There’s a lot we don’t charge for',
      rows: [
        {
          heading: 'Monthly maintenance fees',
          content: '$0',
        },
        {
          heading: 'Overdraft item fees',
          content: '$0',
          dejargKey: 'overdraftItem',
        },
        {
          heading: 'Electronic transfers to your account at another institution',
          content: '$0',
        },
        {
          heading: 'Statement copies online',
          content: '$0',
        },
        {
          heading: 'Incoming wires',
          subCopy: 'Domestic and International',
          content: '$0',
        },
        {
          heading: 'Postage-paid deposit envelopes',
          content: '$0',
        },
        {
          heading: "Official / cashier's checks",
          content: '$0',
          dejargKey: 'checks',
        },
        {
          heading: 'Unlimited Deposits',
          content: '$0',
        },
      ],
    },
    {
      sectionClass: 'for-a-fee',
      postTitle: '…and won’t hide the fees we do have.',
      className: 'for-a-fee-table',
      rows: [
        {
          heading: 'Excessive transactions fee',
          content: '$10.00',
          subCopy2: 'per transaction',
          dejargKey: 'excessiveTransactions',
        },
        {
          heading: 'Expedited delivery',
          content: '$15.00',
        },
        {
          heading: 'Outgoing wires',
          subCopy: 'Domestic only',
          content: '$20.00',
          dejargKey: 'domesticWire',
        },
        {
          heading: 'Account research fee',
          content: '$25.00',
          subCopy2: 'per hour',
          dejargKey: 'accountResearch',
        },
      ],
    },
  ],

  // TODO: Cleanup as part of STOR-46749
  tableSectionsFlag: {
    name: 'FF_reg-d-2023',
    sections: [
      {
        titleClass: 'top-border',
        heading: 'Fees, a short story.',
        subheading: 'You shouldn’t be nickel and dimed for using your own money.',
        postTitle: 'There’s a lot we don’t charge for',
        rows: [
          {
            heading: 'Monthly maintenance fees',
            content: '$0',
          },
          {
            heading: 'Overdraft item fees',
            content: '$0',
            dejargKey: 'overdraftItem',
          },
          {
            heading: 'Excessive transactions fee',
            content: '$0.00',
            subCopy2: 'per transaction',
            dejargKey: 'excessiveTransactionsCovidRegD',
          },
          {
            heading: 'Electronic transfers to your account at another institution',
            content: '$0',
          },
          {
            heading: 'Statement copies online',
            content: '$0',
          },
          {
            heading: 'Incoming wires',
            subCopy: 'Domestic and International',
            content: '$0',
          },
          {
            heading: 'Postage-paid deposit envelopes',
            content: '$0',
          },
          {
            heading: "Official / cashier's checks",
            content: '$0',
            dejargKey: 'checks',
          },
          {
            heading: 'Unlimited Deposits',
            content: '$0',
          },
        ],
      },
      {
        sectionClass: 'for-a-fee',
        postTitle: '…and won’t hide the fees we do have.',
        className: 'for-a-fee-table',
        rows: [
          {
            heading: 'Expedited delivery',
            content: '$15.00',
          },
          {
            heading: 'Outgoing wires',
            subCopy: 'Domestic only',
            content: '$20.00',
            dejargKey: 'domesticWire',
          },
          {
            heading: 'Account research fee',
            content: '$25.00',
            subCopy2: 'per hour',
            dejargKey: 'accountResearch',
          },
        ],
      },
    ],
  }
};

export default createStore('savingsProduct', savingsProduct);
