import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Category from 'pages/explore/category';
import cdsContent from 'stores/pages/cds';
import { lazyWithPreload } from 'utilities';
import { updateDDO } from 'components/utility-components/track';

const RyrcdProduct = lazyWithPreload(() => import('pages/explore/products/cds/ryrcd'))();
const HycdProduct = lazyWithPreload(() => import('pages/explore/products/cds/hycd'))();
const NoPenaltyCDProduct = lazyWithPreload(() => import('pages/explore/products/cds/no-penalty'))();
const pageTitle = 'CDs';
const lineOfBusiness = 'Bank';

const CD = (props) => {
  updateDDO({ pageName: pageTitle, lineOfBusiness });
  return <Category title={pageTitle} categoryContent={cdsContent} {...props} />;
};

const RYRCD = (props) => {
  updateDDO({ pageTitle, pageName: 'Raise Your Rate CD', lineOfBusiness });
  return <RyrcdProduct {...props} />;
};

const HYCD = (props) => {
  updateDDO({ pageTitle, pageName: 'High Yield CD', lineOfBusiness });
  return <HycdProduct {...props} />;
};

const NPCD = (props) => {
  updateDDO({ pageTitle, pageName: 'No Penalty CD', lineOfBusiness });
  return <NoPenaltyCDProduct {...props} />;
};


const CdRouter = ({ match: { url } }) => (
  <Switch>
    <Route exact path={`${url}/ryrcd/:tier?/:term?`} render={RYRCD} />
    <Route exact path={`${url}/hycd/:tier?/:term?`} render={HYCD} />
    <Route exact path={`${url}/no-penalty-cd/:tier?/:term?`} render={NPCD} />
    <Route exact path={`${url}/:tier?/:term?`} render={CD} />
  </Switch>
);

export default CdRouter;
