import React from 'react';
import AdaText from '../../ada-text';

/**
 * Returns a component that creates text specifically for screen readers.
 * Useful for text that is broken into multiple spans that should be read in one swipe.
 *
 * @param {object} text object that holds all of the tab a11y text
 *  ex: `{tier1: "Less than $15,000 minimum daily balance", ...}`
 * @param {*} index position of the tab inside of the segmented control
 * @param {*} children the actual text of the tab `{Less than $15,000}`
 * @returns {component}
 */

const HiddenText = ({ text, index, children }) => {
  if (!text) {
    return children;
  }

  // TODO: standardize if hiddenText is an object or array
  const content = text[Object.keys(text)[index]];
  const styles = {
    top: '18px',
    lineHeight: '0',
    letterSpacing: '-25px',
  };

  return (
    <React.Fragment>
      <AdaText {...{ styles }}>
        {content}
      </AdaText>
      <span aria-hidden="true">{children}</span>
    </React.Fragment>
  );
};

export default HiddenText;
