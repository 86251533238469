import buildRates from './build-rates';

/**
 *  Function to build out consumable term and tier object for product rate calls.
 *
 * @param {Array} termsArray '[{…}, {…}, {…}, {…}, {…}, {…}, {…}]'
 *  Array of rate objects for each term and product.
 *  Ex: {term: 3, termEndDate: null, termStartDate: null, termId: "IRAHY-3M", termCode: "3M", …}
 *
 * @returns {Object} {3M: {…}, 6M: {…}, 9M: {…}, 12M: {…}, 18M: {…}, …}
 *  Object with objects for each term and product.
 *  Ex: 3M: {term: 3, tier1: {…}, tier2: {…}, tier3: {…}}
 */

const buildTerms = (termsArray) => {
  if (termsArray.terms) {
    termsArray = termsArray.terms;
  }

  const obj = {};
  Array.prototype.forEach.call(termsArray, (term) => {
    obj[term.termCode] = buildRates(term.rates, term.term);
  });
  return obj;
};

export default buildTerms;
