import { createStore } from 'satcheljs';
import resolveImagePath from 'utilities/resolve-image-path';

const ehlLogoImg = resolveImagePath('other/ehlLogo.svg');

const footerContent = {
  // TODO: after Copy component is fixed - move punctuation outside of regex
  header: 'A FEW THINGS YOU SHOULD KNOW',
  body: [
    'Securities products and services are offered through Ally Invest Securities LLC, member @@FINRA::http://www.finra.org/::@@ and @@SIPC.::http://www.sipc.org/::@@',
    'Investment accounts provided by Ally Invest Advisors, Ally Invest Securities, and Ally Invest Forex are: NOT FDIC INSURED, NOT BANK GUARANTEED and MAY LOSE VALUE. @@View Ally Invest disclosures::ally://viewAllyInvestDisclosures::@@ ',
    `Bank accounts and mortgage products are provided by Ally Bank, a Member FDIC and %%Equal Housing Lender !!${ehlLogoImg}:: ::!!,%% NMLS ID 181005. @@View Ally Bank disclosures::ally://viewAllyBankDisclosures::@@`,
  ],
};
export default createStore('footerContent', footerContent);
