import React from 'react';
import { Link } from 'react-router-dom';

const StaticTrack = ({
  attrs,
  text,
  children,
  schema: { get: getSchema },
}) => {
  const tracking = getSchema({ text });
  const props = Object.assign(attrs, tracking);
  return <Link {...props}>{children}</Link>;
};

export default StaticTrack;
