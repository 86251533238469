import { SET_AS_LOADING } from 'actions/products';
import { focusLoader } from '@ally/auth-sales-shared/utilities';
import { productStore } from 'stores';

const Products = productStore();

/**
 *  Function used to reload products needed by the force reload button. After products are loaded
 *  it will call the focusloader to focus the element with the matching "focusType" once
 *  the react component loads.
 *
 * @param {string} focusType 'failed product'.
 *
 */

const attemptReload = (focusType) => {
  // if more reloadable items need to be added later...
  const reloadableItems = ['rate', 'rating'];

  // attempt to load unloaded items
  reloadableItems.forEach((item) => {
    if (Products.fetched[item] === 'failed') {
      SET_AS_LOADING({ flag: item });
      Products.fetch[item]();
    }
  });
  focusLoader.setFocus(focusType);
};

export default attemptReload;
