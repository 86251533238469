import React from 'react';

import resolveImagePath from 'utilities/resolve-image-path';

import './two-row-icon.scss';

const TwoRowIcon = ({ copy, image }) => (
  <React.Fragment>
    <div className="img-container">
      <img src={resolveImagePath(image)} className="horizontal-center" alt="" />
    </div>
    <div className="link-text text-center">
      <p>{copy}</p>
    </div>
  </React.Fragment>
);

export default TwoRowIcon;
