import React from 'react';
import { productStore } from 'stores';
import attemptReload from './helpers/attempt-reload';

const Products = productStore();

const ForceReloadButton = ({ className, focusType }) => {
  const { retryText } = Products.fetch;

  return (
    <button
      type="button"
      onClick={() => {
        attemptReload(focusType);
      }}
      className={className}
      ref={() => {
      }}
    >
      {retryText}
    </button>
  );
};

export default ForceReloadButton;
