/**
 * Checks if term is selected to return otherwise returns default term
 *
* @param {string} term selected term returned - 36M
 * @param {object} terms returns an object of available terms with apy and apr rates split by tiers
 *   term: 24
 *    tier1: {apy: 2.5, apr: 2.47}
 *    tier2: {apy: 2.5, apr: 2.47}
 *    tier3: {apy: 2.5, apr: 2.47}
 * @returns {string} either user selected term or default term defined below -  36M
 */

const getTerm = (term, terms) => {
  const defaultTerm = ('12M' in terms) ? '12M' : Object.keys(terms)[0];
  return (term in terms) ? term : defaultTerm;
};

export default getTerm;
