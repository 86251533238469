exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".aos-application-container .aos-application ._30R9NQ2VKfjjiuIlDSqAki{display:flex;align-items:center;font-family:\"AllyFontBold\";line-height:1;margin-bottom:0}.aos-application-container .aos-application ._1j2dcEFV9ujobxK6r8VMch{font-size:36px}.aos-application-container .aos-application ._1j2dcEFV9ujobxK6r8VMch:after{content:\"%\";vertical-align:super;font-size:.45em}.aos-application-container .aos-application .PL7VYGnLp1CmWZsIwMAJP{display:inline-block;max-width:7em;margin-left:.8em;font-size:11px;line-height:1;text-align:left;text-transform:uppercase}", "", {"version":3,"sources":["/runnertmp/builds/c3Z3HgyH/0/allyfinancial/ally-digital/applications/ui/explore-products-and-rates/src/packages/auth-sales-shared/components/Rate/Rate.scss"],"names":[],"mappings":"AAIM,qEACE,aACA,mBACA,2BACA,cACA,eAAA,CAGF,qEACE,cAAA,CACA,2EACE,YACA,qBACA,eAAA,CAIJ,mEACE,qBACA,cACA,iBACA,eACA,cACA,gBACA,wBAAA,CAAA","file":"Rate.scss","sourcesContent":[":global(.aos-application-container) {\n  :global(.aos-application) {\n    :local {\n\n      .rate {\n        display: flex;\n        align-items: center;\n        font-family: 'AllyFontBold';\n        line-height: 1; // AOS override\n        margin-bottom: 0; // AOS override\n      }\n\n      .apy {\n        font-size: 36px;\n        &:after {\n          content: \"%\";\n          vertical-align: super;\n          font-size: 0.45em;\n        }\n      }\n\n      .measure {\n        display: inline-block;\n        max-width: 7em;\n        margin-left: 0.8em;\n        font-size: 11px;\n        line-height: 1;\n        text-align: left;\n        text-transform: uppercase;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"rate": "_30R9NQ2VKfjjiuIlDSqAki",
	"apy": "_1j2dcEFV9ujobxK6r8VMch",
	"measure": "PL7VYGnLp1CmWZsIwMAJP"
};