import { createStore } from 'satcheljs';

const selfDirectedTradingContent = {
  hero: {
    pageTitle: 'Self Directed Trading',
    config: {
      template: 'text-with-accent',
      product: 'onlineSavings',
    },
    content: {
      bgColor: 'invest',
      extraClass: 'long-title',
      label: 'Trade stocks and ETFs commission free with no account minimums',
      accent: 'trade stocks and ETFs',
      bottomCopy: 'commission free with no account minimums.',
      cta: {
        skin: 'outline',
        text: 'Open Account',
        // TODO: impliment environment switch for this
        url: 'ally://account_opening/INVS',
        // https://invest.ally.com/ola/ PROD URL
      },
    },
  },
  topTagline: {
    titleLevel: 'h2',
    ptClassName: 'text-center',
    title: 'Intelligent investing for the independent type.',
  },
  addlInfoSection: [
    // top section
    {
      className: 'title bold',
      copy: 'Trade stocks and ETFs',
    },
    {
      className: 'outer-text',
      copy: 'Commission',
    },
    {
      className: 'rate ratePadding',
      copy: '++$++0',
    },
    {
      className: 'outer-text',
      copy: 'no account minimums.',
    },
    // bottom section
    {
      className: 'title bold divider',
      copy: 'Plus, trade options',
    },
    {
      className: 'outer-text',
      copy: 'Commission',
    },
    {
      className: 'rate ratePadding',
      copy: '++$++0',
    },
    {
      className: 'outer-text',
      copy: '+50&cent; per contract fee',
    },
  ],
  tagline: {
    titleLevel: 'h2',
    ptClassName: 'text-center padding-bottom',
    title: 'We give you the tools. You take it from there.',
  },
  cardSection: {
    title: 'our favorite features',
    titleLevel: 'h3',
    class: 'favorite-features',
    cards: [
      {
        icon: 'invest/tradeanywhere.svg',
        title: 'Connect with the market 24/7.',
        copy: 'Research investments, analyze your portfolio, or place trades from wherever you happen to be.',
      },
      {
        icon: 'invest/performance.svg',
        title: 'Commission-free ETFs from Vanguard, GlobalX, iShares, and more.',
        copy: 'We partnered with market leaders to offer you simple, high-quality ETFs that complement your portfolio, so you can diversify your strategy with little impact to your bottom line.',
      },
      {
        icon: 'invest/dollarsign.svg',
        title: 'No account minimum to get started.',
        copy: 'It only takes a few minutes to open an account. You don\'t need to add money right away, but the faster you fund the sooner you can start trading.',
      },
    ],
  },
  plainText: [
    {
      ptClassName: 'padding-bottom padding-top',
      title: 'Move over your other investment accounts completely online.',
      className: 'not-nested',
      copy: 'Transfer individual stocks or all your investments online from most major brokerages. We also reimburse up to $75 in transfer fees when you make a one-time transfer of $2,500 or more.',
    },
    {
      ptClassName: 'padding-bottom',
      className: 'not-nested',
      copy: '@@About transfer fee reimbursement::https://www.ally.com/invest/self-directed-trading/#why-ally::@@',
    },
    {
      ptClassName: 'padding-bottom padding-top',
      className: 'not-nested',
      copy: 'For stocks priced less than $2.00, there’s an additional penny per share charge and the maximum commission per order generally doesn’t exceed 5% of the trade value. There’s a minimum commission of $4.95 for stocks priced less than $2.00.',
    },
  ],

};

export default createStore('selfDirectedTradingContent', selfDirectedTradingContent);
