import React from 'react';
import Copy from 'components/utility-components/copy/copy';
import resolveImagePath from 'utilities/resolve-image-path';
import { device } from 'utilities';
import fetchLaunchDarklyFlag from '../../utilities/fetch-feature-flags';

import './info-card.scss';

const InfoCard = ({
  icon,
  title,
  copy,
  cardLink,
  dejarg,
}) => (
  <div className="info-card">
    <div className="title-section">
      <img aria-hidden="true" className="icon" src={resolveImagePath(icon)} alt="" />
      <h3 className="title">{title}</h3>
    </div>
    <div className="copy-section">
      <p className="copy">
        <Copy>{copy}</Copy>
      </p>
      {device === 'android' && cardLink
        ? <p className="card-link"><Copy>{cardLink}</Copy></p>
        : null
      }

      {/* TODO: Merge dejarg & cardLink when we deprecate "android" style */}
      {dejarg ? (
        <p className="card-link"><Copy>{dejarg}</Copy></p>
      ) : null}
    </div>
  </div>
);

const InfoCards = ({ cards }) => cards.map((card, index) => {
  const { flag } = card;
  if (flag && !fetchLaunchDarklyFlag(flag)) return null;
  return card && <InfoCard key={`info-card-${index}`} {...card} />;
});

export default InfoCard;
export { InfoCards };
