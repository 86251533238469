import React from 'react';

/**
 * Returns an a11y only component for screen readers
 * Screen reader will read the label and sets the actual content to aria-hidden
 * Helpful to read spans as one line rather than in multiple swipes
 *
 * @param {string} label What the screen reader should read `2.20% APY on all balance tiers`
 * @param {any} children What should be displayed on the screen
 */

const ADAText = ({ label, children }) => (
  <span className="sr-text-parent">
    <span className="sr-text">{label}</span>
    <span aria-hidden="true">{children}</span>
  </span>
);

export default ADAText;
