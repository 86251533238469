import { createStore } from 'satcheljs';
import { GET_RATES_FIRST_TIME, GET_RATINGS_FIRST_TIME } from 'actions/products';

const productStore = {
  fetched: {
    rate: '',
    rating: '',
  },
  fetch: {
    rate: GET_RATES_FIRST_TIME,
    rating: GET_RATINGS_FIRST_TIME,
    retryText: "Rates didn't load. Select to try to load them again.",
  },
  iraOnlineSavings: {
    ratingInfo: {
      overallRating: null,
      reviewCount: null,
    },
    terms: {
      '0M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
    },
    effectiveDate: null,
  },
  checking: {
    ratingInfo: {
      overallRating: null,
      reviewCount: null,
    },
    terms: {
      '0M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
      },
    },
    effectiveDate: null,
  },
  onlineSavings: {
    ratingInfo: {
      overallRating: null,
      reviewCount: null,
    },
    terms: {
      '0M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
    },
    effectiveDate: null,
  },
  moneyMarket: {
    ratingInfo: {
      overallRating: null,
      reviewCount: null,
    },
    terms: {
      '0M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
    },
  },
  iraHighYield: {
    ratingInfo: {
      overallRating: null,
      reviewCount: null,
    },
    terms: {
      '3M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '6M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '9M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '12M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '18M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '36M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '60M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
    },
    effectiveDate: null,
  },

  // this is mapped as "highYieldCD" in the ratings API. Elsewhere in the code we have
  // code references to "higYieldCd" (and obviously "cd").
  cd: {
    ratingInfo: {
      overallRating: null,
      reviewCount: null,
    },
    terms: {
      '3M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '6M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '9M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '12M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '18M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '36M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '60M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
    },
    effectiveDate: null,
  },
  noPenaltyCD: {
    ratingInfo: {
      overallRating: null,
      reviewCount: null,
    },
    terms: {
      '11M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
    },
    effectiveDate: null,
  },
  iraRaiseYourRate: {
    ratingInfo: {
      overallRating: null,
      reviewCount: null,
    },
    terms: {
      '24M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '48M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
    },
    effectiveDate: null,
  },
  raiseYourRateCD: {
    ratingInfo: {
      overallRating: null,
      reviewCount: null,
    },
    terms: {
      '24M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
      '48M': {
        tier1: {
          apy: 0,
          apr: 0,
        },
        tier2: {
          apy: 0,
          apr: 0,
        },
        tier3: {
          apy: 0,
          apr: 0,
        },
      },
    },
    effectiveDate: null,
  },
};

export default createStore('productStore', productStore);
