import { tabControl, makeTrackText } from 'components/utility-components/track';

const { get: getSchema } = tabControl;

/**
 * Gathers all of the attributes and outputs them in an object of attributes
 *
 * @param {number} index position in the tab in the segment control
 * @param {number} activePanel Returns index of the active panel
 * @param {string} tier Contains the text of the tab
 * @returns {object}
 */

const getAttrs = ({ index, activePanel, tier }) => {
  const isActive = activePanel === index;
  const btnState = isActive ? ' active' : '';
  const tabClass = `text-center btn-clean${btnState}`;
  const tierLvl = `tier${index + 1}`;
  const text = makeTrackText(tier);

  return {
    role: 'tab',
    key: index,
    className: tabClass,
    'aria-selected': isActive,
    'data-tier-level': tierLvl,
    ...getSchema({ text }),
  };
};

export default getAttrs;
