import afterDraw from './after-draw';

const naturalFocus = {
  a: true,
  button: true,
};

class TargetHeading {
  constructor() {
    this.state = {
      page: {
        el: null,
        ready: false,
      },
    };
  }

  shouldFocus(type) {
    return this.getTrigger() === type;
  }

  setTrigger(type) {
    this.state.trigger = type;
  }

  getTrigger() {
    return this.state.trigger;
  }

  getEl(type) {
    if (!this.state[type]) {
      this.state[type] = {
        el: null,
      };
    }

    return this.state[type].el;
  }

  setReady(type, val) {
    if (!this.state[type]) {
      this.state[type] = {
        ready: val,
      };
      return val;
    }

    this.state[type].ready = val;
    return val;
  }

  checkReady(type) {
    return this.state[type] && this.state[type].ready;
  }

  setEl(el, type) {
    // set the most recently rendered
    // element to be focused next
    if (el !== this.getEl(type)) {
      this.state[type].el = el;
    }
  }

  loadFocus(el, type) {
    if (el) {
      this.setEl(el, type);
      this.updateEl(type);
    }
  }

  updateEl(type) {
    if (type) {
      const el = this.getEl(type);

      if (el) {
        const needsFocus = !naturalFocus[el.tagName.toLowerCase()];

        if (!el.getAttribute('tabindex') && needsFocus) {
          el.setAttribute('tabindex', '-1');
        }
      }
      // if whatever event happened that will render
      // the element, focus on it
      //
      // e.g. the side nav being clicked, a tab or
      // segmented control being selected
      if (this.checkReady(type)) {
        this.focus(type);
        this.setReady(type, false);
      }
    }
  }

  focus(type, doScroll) {
    afterDraw(() => {
      const element = this.getEl(type);
      if (element) {
        element.focus();

        if (type === 'page') {
          const allyLogo = document.querySelector('.ally-logo-main');

          window.scrollTo(0, 0);

          if (allyLogo) {
            allyLogo.setAttribute('tabindex', '-1');
            allyLogo.focus();
          }
        }

        if (doScroll) {
          element.scrollIntoView();
          window.scrollBy(0, -10);
        }
      }
    });
  }

  setFocus(type) {
    this.setReady(type, true);
  }
}

const focusLoader = new TargetHeading();

export default focusLoader;
