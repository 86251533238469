import React, { Component } from 'react';
import { Route, withRouter, Switch, Redirect, Link } from 'react-router-dom';
import { nextFocus, scrollTop, globalParams } from 'utilities';

import Explore from 'pages/explore';
import Checking from 'pages/explore/products/checking';
import SelectionList from 'pages/utility-pages/selection-list';

import SavingsRouter from 'routes/sub-routes/savings-router';
import IraRouter from 'routes/sub-routes/ira-router';
import CdRouter from 'routes/sub-routes/cd-router';
import InvestRouter from 'routes/sub-routes/invest-router';
import HomeRouter from 'routes/sub-routes/home-router';
import Footer from 'components/footer';
import exploreContent from 'stores/pages/explore/';

import footerContent from 'stores/shared/footer';
import { updateDDO, dispatchDDO } from 'components/utility-components/track';

import './main.scss';
import initializeLaunchDarkly from '@ally/aardvarkly';
import { afterDraw } from '../utilities';

// Initialize LD and fetch feature flags
const { client, getInitPromise } = initializeLaunchDarkly({ platform: 'ember' });
getInitPromise()
  .then(() => {
    window.ldClient = client;
  })
  .catch((error) => {
    console.warn('Failed to initialize LaunchDarkly Client SDK', error);
  });

const ExploreWithContent = props => <Explore {...props} {...exploreContent()} />;
const { subroute } = globalParams;

// TODO: make higher order component for DDO updates
// Checking
// const pageName = 'Interest Checking';
const CHK = props => {
  updateDDO({ pageName: 'Interest Checking', lineOfBusiness: 'Bank' });
  return <Checking {...props} />;
};

// Explore
// pageName = 'Products and Rates';
const EXP = props => {
  updateDDO({ pageName: 'Products and Rates', lineOfBusiness: 'Corporate' });
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }
  window.scrollTo(0, 0);
  return <ExploreWithContent {...props} />;
};

const LegalFooter = ({ pathname }) => {
  if (!pathname.includes('/selection-list/')) {
    return <Footer content={footerContent()} />;
  }
  return null;
};

let { pageName } = window.digitalData.page;

class App extends Component {
  static dispatch() {
    afterDraw(() => {
      const { pageName: current } = window.digitalData.page;
      if (pageName !== current) {
        pageName = current;
        dispatchDDO();
      }
    });
  }

  constructor() {
    super();
    this.state = {
      redirect: subroute,
    };
  }

  componentDidMount() {
    const {
      history: { listen },
    } = this.props;
    // returns a function that will stop
    // the listened-to function from firing
    this.unlisten = listen(scrollTop);
    App.dispatch();
    this.setState({ redirect: false });
  }

  componentDidUpdate() {
    nextFocus();
    App.dispatch();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  static renderDevlinks() {
    const { hostname } = window.location;
    const isProduction = hostname === 'explore-products-rates.ally.com';
    // TODO replace with build env flag when available
    if (isProduction) {
      return null;
    }

    return (
      <div>
        <div>
          <Link to="/invest">Invest Page</Link>
        </div>
        <div>
          <Link to="/invest/wealth-management">Wealth Management Page</Link>
        </div>
        <div>
          <a href="/?device=ios&testRates=failed">iOS Rate Failure</a>
        </div>
        <div>
          <a href="/?device=android&testRates=failed">Android Rate Failure</a>
        </div>
      </div>
    );
  }

  render() {
    const { location } = this.props;
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    return (
      <div className="aos-application">
        <Switch>
          {/* Ally Banking */}
          <Route path="/savings" component={SavingsRouter} />
          <Route path="/checking/:tier?/:term?" render={CHK} />
          <Route path="/iras" component={IraRouter} />
          <Route path="/cds" component={CdRouter} />
          {/* Ally Invest */}
          <Route path="/invest" component={InvestRouter} />
          {/* Ally Home */}
          <Route path="/home" component={HomeRouter} />
          {/* Mobile dropdown replacement */}
          <Route path="/selection-list/:product/:currentTerm" render={SelectionList} />
          {/* Targeted Explore homepage */}
          <Route path="/explore/:template_id?/:campaign_id?" render={EXP} />
          {/* Explore homepage */}
          <Route path="/" render={EXP} />
        </Switch>
        {App.renderDevlinks()}
        <LegalFooter {...location} />
      </div>
    );
  }
}

export default withRouter(App);
