// receive an image path and return the relevant url
// paths could be absolute or relative
const resolveImagePath = path => {
  const absolutePathRegex = /^https?:\/\/.+$/;
  const prefixedRelativePathRegEx = /^assets\/images\/.+$/;

  // if we were provided a path that looks like a URL, let's assume it is
  // and return the path so we can load the image
  if (absolutePathRegex.test(path)) {
    return path;
  }

  // if we were provided a relative path with the assets/images
  // directories, we can just require that image directly
  if (prefixedRelativePathRegEx.test(path)) {
    return require(`${path}`);
  }

  // if we were provided a relative path without the assets/images prefix
  // we'll need to generate the correct relative path and require the image
  return require(`assets/images/${path}`);
};

export default resolveImagePath;
