import * as React from 'react';
import Hero from 'components/hero';
import Main from 'components/main';
import ImgWithContent from 'components/img-with-content';
import BlockQuote from 'components/block-quote';
import ValueProp from 'components/valueprop';
import Divider from 'components/divider';
import PlainText from 'components/plain-text';
import ShowcaseTile from 'components/showcase-tile';

import homePurchaseContent from 'stores/pages/home-purchase';
import './home-purchase.scss';

class HomePurchase extends React.Component {
  constructor() {
    super();
    this.Content = homePurchaseContent();
  }

  render() {
    const {
      hero,
      fastForwardLoanApp,
      yourHomeYourTerms,
      optionsFixedRate,
      optionsAdjustableRate,
      optionsJumboLoans,
      helpGetTheHomeYouWant,
      crunchTheNumbers,
      crunchNumberTiles,
    } = this.Content;
    return (
      <div className="Home-Purchase">
        <Hero {...hero} />
        <Main>
          <ImgWithContent {...fastForwardLoanApp} />
          <Divider />
          <PlainText {...yourHomeYourTerms} />
          <ValueProp {...optionsFixedRate} />
          <ValueProp {...optionsAdjustableRate} />
          <ValueProp {...optionsJumboLoans} />
          <BlockQuote {...helpGetTheHomeYouWant} />
          <PlainText {...crunchTheNumbers} />
          <ShowcaseTile {...crunchNumberTiles} />
        </Main>
      </div>
    );
  }
}

export default HomePurchase;
