import getKey from './keygen';

const pairKey = arr => {
  if (arr[0] && arr[0].key) {
    return arr;
  }

  arr.forEach((element, idx) => {
    const { key } = element;

    if (!key) {
      arr[idx] = {
        key: getKey(),
        data: element,
      };
    }
  });
  return arr;
};

export default pairKey;
