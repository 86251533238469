import { mutator } from 'satcheljs';
import productStore from 'stores/products';
import { STORE_RATINGS } from 'actions/products';

export default
mutator(STORE_RATINGS, (ratingsResponse) => {
  ratingsResponse.forEach((product) => {
    const { ratingInfo } = product;
    let { productName } = product;
    const store = productStore();

    // hack... apparently "highYieldCD", "highYieldCd" and "cd" are used
    // interchangeably throughout the API/code, but this product was causing
    // the entire store to be flagged failed b/c `store.highYieldCD` doesn't exist
    if (productName === 'highYieldCD') {
      productName = 'cd';
    }

    store[productName].ratingInfo.overallRating = ratingInfo.overallRating;
    store[productName].ratingInfo.reviewCount = ratingInfo.reviewCount;
  });

  productStore().fetched.rating = 'loaded';
});
