/* eslint-disable react/prop-types, react/no-danger */
import React from 'react';
import { convertMarkdown } from '@ally/auth-sales-shared/utilities';
import './marketing-hero.scss';
import Button from 'components/button';

const MarketingHero = ({
  headline,
  subhead,
  cta: { link, text },
}) => (
  <article className="marketing-hero">
    <section className="marketing-hero__content">
      <div className="marketing-hero__body">
        <h2 className="marketing-hero__title" dangerouslySetInnerHTML={{ __html: convertMarkdown(headline) }} />
        <p className="marketing-hero__description" dangerouslySetInnerHTML={{ __html: convertMarkdown(subhead) }} />
      </div>
    </section>
    <footer className="marketing-hero__footer">
      <Button to={link} button={text} skin={'hero'}/>
    </footer>
  </article>
);

export default MarketingHero;
