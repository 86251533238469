import { createStore } from 'satcheljs';
import { device } from 'utilities';

const isIos = device === 'ios';

const tiersText = {
  ios: {
    text: [
      'Less than $5,000',
      '$5,000 Minimum',
      '$25,000 Minimum',
    ],
    a11y: [
      'Minimum opening deposit of less than $5,000',
      'Opening deposit of $5,000 Minimum',
      'Opening deposit of $25,000 Minimum',
    ],
  },
  android: {
    text: [
      'Less than $5,000',
      '$5,000 Minimum',
      '$25,000 Minimum',
    ],
    a11y: [
      'Minimum opening deposit of less than $5,000',
      'Opening deposit of $5,000 Minimum',
      'Opening deposit of $25,000 Minimum',
    ],
  },
};

const tiers = tiersText[device] || tiersText.ios;

const cdsContent = {
  accountTypes: [
    {
      tiers: tiers.text,
      hiddenText: tiers.a11y,
      config: { product: 'cd' },
      header: 'High Yield CD',
      region: 'High Yield CD',
      cta: {
        text: 'Explore',
        label: 'Explore High Yield CD',
        url: '/cds/hycd',
      },
      template: 'multi-tile-scroll',
      intro: 'With an opening deposit of',
      tiles: [
        {
          copy: {
            term: '60M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '5-year term',
          },
          link: '/cds/hycd/%s',
          type: 'cds',
        },
        {
          copy: {
            term: '36M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '3-year term',
          },
          link: '/cds/hycd/%s',
          type: 'cds',
        },
        {
          copy: {
            term: '18M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '18-month term',
          },
          link: '/cds/hycd/%s',
          type: 'cds',
        },
        {
          copy: {
            term: '12M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '12-month term',
          },
          link: '/cds/hycd/%s',
          type: 'cds',
        },
        {
          copy: {
            term: '9M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '9-month term',
          },
          link: '/cds/hycd/%s',
          type: 'cds',
        },
        {
          copy: {
            term: '6M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '6-month term',
          },
          link: '/cds/hycd/%s',
          type: 'cds',
        },
        {
          copy: {
            term: '3M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '3-month term',
          },
          link: '/cds/hycd/%s',
          type: 'cds',
        },
      ],
      disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s. Early withdrawal penalty will apply.',
    },
    {
      config: { product: 'raiseYourRateCD' },
      header: 'Raise Your Rate CD',
      region: 'Raise Your Rate CD',
      cta: {
        text: 'Explore',
        label: 'Explore Raise Your Rate CD',
        url: '/cds/ryrcd',
      },
      template: 'side-by-side',
      intro: '',
      tiles: [
        {
          copy: {
            term: '24M',
            tier1: 'tier1',
            subtext: '2-year term',
          },
          link: '/cds/ryrcd/tier1',
          type: 'cds',
        },
        {
          copy: {
            term: '48M',
            tier1: 'tier1',
            subtext: '4-year term',
          },
          link: '/cds/ryrcd/tier2',
          type: 'cds',
        },
      ],
      disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s. Early withdrawal penalty will apply.',
    },
    {
      config: { product: 'noPenaltyCD' },
      header: 'No Penalty CD',
      region: 'No Penalty CD',
      cta: {
        text: 'Explore',
        label: 'Explore No Penalty CD',
        url: '/cds/no-penalty-cd',
      },
      template: 'full-width',
      intro: isIos ? '11-Month Term' : null,
      subheading: isIos ? null : '11-Month Term',
      tiles: [
        {
          copy: {
            term: '11M',
            tier1: 'tier1',
            subtext: 'Less than $5,000 opening deposit',
          },
          link: '/cds/no-penalty-cd/tier1',
          type: 'cds',
        },
        {
          copy: {
            term: '11M',
            tier1: 'tier2',
            subtext: '$5,000 minimum opening deposit',
          },
          link: '/cds/no-penalty-cd/tier2',
          type: 'cds',
        },
        {
          copy: {
            term: '11M',
            tier1: 'tier3',
            subtext: '$25,000 minimum opening deposit',
          },
          link: '/cds/no-penalty-cd/tier3',
          type: 'cds',
        },
      ],
      disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s.',
    },
  ],
};

export default createStore('cdsContent', cdsContent);
