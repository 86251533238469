import { createStore } from 'satcheljs';
import fetchLaunchDarklyFlag from '../../utilities/fetch-feature-flags';

const dejargMap = {
  compoundedInterest: {
    title: 'Daily compounded interest',
    body:
      'Instead of just paying you interest on your initial deposit, we also pay you interest on the interest you earn–called compounding interest.',
  },
  fdic: {
    title: 'Federal Deposit Insurance Corporation (FDIC)',
    body:
      'A federal agency that insures deposits in member banks against loss if the bank holding the deposit were to fail.',
    a11yText: 'F.D.I.C.',
  },
  whySix: {
    title: 'Why only 6?',
    body:
      'There are Federal limits on transactions from U.S. savings and money market accounts.You can always call us and request a check made out to you. You can also make unlimited deposits and ATM withdrawals. But, Federal law limits other electronic, telephone and check transactions to a total of 6 per statement cycle. These limited transactions can be to other accounts or to a third party. If you go over the limit we charge $10 per transaction.',
  },
  aboutTheTransactionLimit: {
    title: 'About the Transaction Limit',
    body:
      'We limit certain types of withdrawals and transfers from Savings and Money Market Accounts to 10 per statement cycle. Depending on your account type, these limited transactions include things like Online and Mobile Banking transfers (between Ally Bank accounts or to a third party), Overdraft Transfer Service, checks, and point-of-sale transactions (such as debit card purchases). We don’t charge a fee for going over the limit. However, we’ll close your account if you exceed it on more than an occasional basis. We’ll notify you when you’ve gone over the limit.',
  },
  whySixCovid: {
    title: 'Why only 6?',
    body:
      'Federal law permits limiting certain types of withdrawals and transfers from savings and money market accounts to a combined total of 6 per statement cycle. These limited transactions include things like Online and Mobile Banking transfers from your account to any of your accounts with us, or to a third party and, if checks and debit cards are allowed on the account, check and point-of-sale transactions. Previously, there was a $10 excessive transaction fee for each transaction that exceeded this limit. However, due to federal regulation changes, we’re currently not applying limits, and are temporarily refunding fees associated with such withdrawals and transfers. Other transactions are also unlimited. For example, you can make as many deposits as you like, and you can call us any time to request a check made out to you. With a Money Market Account, you can make unlimited ATM withdrawals.',
  },
  sixTransfersAndWithdrawals: {
    title: 'Six transfers and withdrawals',
    body:
    'Federal law limits certain types of telephone, check and electronic transfers and withdrawals from money market accounts to 6 per statement cycle. These limited transactions include things like Point of Sale transactions, Online and Mobile Banking transfers, our Overdraft Transfer service, and transfers from your Money Market Account to another of your Ally bank accounts. If you go over this limit, we charge $10 for each of these transactions after the initial 6. If you exceed this limit on more than an occasional basis, we are required to close your Money Market Account. Some transactions are unlimited. You can make as many deposits as you’d like. You can make unlimited ATM withdrawals or call us anytime to request a check made out to you.',
  },
  sixTransfersAndWithdrawalsCovid: {
    title: 'Six transfers and withdrawals',
    body:
      'Federal law permits limiting certain types of withdrawals and transfers from savings and money market accounts to a combined total of 6 per statement cycle. These limited transactions include things like Online and Mobile Banking transfers from your account to any of your accounts with us, or to a third party and, if checks and debit cards are allowed on the account, check and point-of-sale transactions. Previously, there was a $10 excessive transaction fee for each transaction that exceeded this limit. However, due to federal regulation changes, we’re currently not applying limits, and are temporarily refunding fees associated with such withdrawals and transfers. Other transactions are also unlimited. For example, you can make as many deposits as you like, and you can call us any time to request a check made out to you. With a Money Market Account, you can make unlimited ATM withdrawals.',
  },
  standardTransfers: {
    title: 'Standard Transfers',
    body:
      'Our standard transfer delivery speed usually takes 3 business days and is always available.',
  },
  expeditedTransfers: {
    title: 'Expedited Transfers',
    body: 'Transfers eligible for expedited delivery are usually delivered in 1 business day.',
  },
  overdraftItem: {
    title: 'Overdraft item',
    body:
      'If you don’t have enough money in your account to cover a transaction and you don’t have Overdraft Transfer Service or CoverDraft℠ service, we may pay the transaction and overdraw your account, or decline the transaction without payment. In either case, we won’t charge an overdraft fee.',
  },
  checks: {
    title: 'Official/Cashier’s Checks',
    body: 'A check issued by a bank and drawn on the bank’s own funds.',
  },
  returnedDeposit: {
    title: 'Returned deposit item',
    body:
      'A check or other deposit that is not paid by the bank on which it was drawn. See your Ally Bank Deposit Agreement for rules concerning returned deposit items.',
  },

  // TODO: Cleanup as part of STOR-46749
  excessiveTransactions: {
    title: 'Excessive Transactions Fee',
    body: 'Federal law limits certain types of telephone and electronic transfers and withdrawals from online savings accounts to 6 per statement cycle. These limited transactions include things like Online and Mobile Banking transfers, our Overdraft Transfer service, and transfers from your Online Savings Account to another of your Ally bank accounts. If you go over this limit, we charge $10 for each of these transactions after the initial 6. If you exceed this limit on more than an occasional basis, we are required to close your Online Savings Account. Some transactions are unlimited. You can make as many deposits as you’d like. You can call us anytime to request a check made out to you.'
  },

  excessiveTransactionsCovidRegD: {
    title: 'Excessive Transactions Fee',
    body: 'We limit certain types of withdrawals and transfers from Savings and Money Market Accounts to 10 per statement cycle. Depending on your account type, these limited transactions include things like Online and Mobile Banking transfers (between Ally Bank accounts or to a third party), Overdraft Transfer Service, checks, and point-of-sale transactions (such as debit card purchases). We don’t charge a fee for going over the limit. However, we’ll close your account if you exceed it on more than an occasional basis. We’ll notify you when you’ve gone over the limit.'
  },

  excessiveTransactionsCovid: {
    title: 'Excessive Transactions Fee',
    body:
      'Federal law permits limiting certain types of withdrawals and transfers from savings and money market accounts to a combined total of 6 per statement cycle. These limited transactions include things like Online and Mobile Banking transfers from your account to any of your accounts with us, or to a third party and, if checks and debit cards are allowed on the account, check and point-of-sale transactions. Previously, there was a $10 excessive transaction fee for each transaction that exceeded this limit. However, due to federal regulation changes, we’re currently not applying limits, and are temporarily refunding fees associated with such withdrawals and transfers. Other transactions are also unlimited. For example, you can make as many deposits as you like, and you can call us any time to request a check made out to you. With a Money Market Account, you can make unlimited ATM withdrawals.',
  },
  domesticWire: {
    title: 'Domestic Outgoing Wire',
    body:
      'An electronic transfer from one U.S. bank account to another made through the Federal Reserve Wire Network.',
  },
  accountResearch: {
    title: 'Account Research Fee',
    body:
      'If you request extensive research on transaction histories, we may charge you a $25/hr Account Research Fee. We’ll let you know before we start if that fee will apply.',
  },
  bestRateGuarantee: {
    title: 'Ten Day Best Rate Guarantee',
    body:
      'When you fund your CD within 10 days of your open date, you’ll get the best rate we offer for your term and balance tier if our rate goes up within that time. The Ally Ten Day Best Rate Guarantee also applies at renewal.',
  },
  penaltyExceptions: {
    title: 'Are there exceptions?',
    body:
      'We’ll waive the early withdrawal penalty if the depositor passes away or is judged legally incompetent.',
  },
  penaltyCalculation: {
    title: 'How is the penalty calculated?',
    body:
      'The penalty amount is calculated at the interest rate in effect for the CD, and is equal to the interest accrued during the time period specified in the penalty schedule. If the accrued interest is less than the penalty amount, the difference will be deducted from the CD’s balance.',
  },
  crossBorder: {
    title: 'Cross Border/Currency Conversion Transaction',
    body:
      'If you are in a foreign country and use an ATM or make a purchase with your debit card, you may be charged a fee of up to 1% of the transaction amount for the currency conversion and/or cross border transaction.',
  },
  stopPayment: {
    title: 'Stop payment',
    body:
      'An order to your bank not to honor a debit from your account. You can place a stop payment on checks and certain types of electronic funds transfers. See our Deposit Agreement for more details.',
  },
  superstores: {
    title: 'Superstores',
    body:
      'This refers to a retailer like Target or Walmart where you can buy an array of goods in one transaction, not just groceries. Because the retailer doesn’t separate these items out during your purchase, card providers generally don’t count these as grocery stores or supermarkets.',
  },
  warehouseClubs: {
    title: 'Warehouse Clubs',
    body:
      'This refers to retailers like Sam’s, Costco and BJ’s where you need a membership to make purchases like gas, groceries, etc. Because the retailer doesn’t separate these items out during your purchase, card providers generally don’t count these as grocery stores or supermarkets.',
  },
  transferfee: {
    title: 'About transfer fee reimbursement',
    body:
      'We must receive your online transfer request or completed Automated Customer Account Transfer (ACAT) https://www.ally.com/resources/pdf/invest/account-transfer.pdf form within 15 days of opening the new account. Account owners who complete a first-time ACAT transfer of $2,500 or more are eligible to receive a transfer fee credit of up to $75 to cover the outgoing transfer fee from the current brokerage firm. This credit will be deposited into the new account within 30 days of receiving proof of charge. Keep in mind, this credit can’t be applied to termination or maintenance fees.',
  },
  homeReadyProgram: {
    title: 'Fannie Mae’s HomeReady mortgage program details',
    body:
      '%%Standard loan terms.%% 25-year or 30-year fixed rate terms available. %%Flexible down payment options.%% You may be able to put as little as 3% down with the potential to save on Private Mortgage Insurance. %%Homeownership education course.%% To help set you up for financial success, complete an online education course as a requirement for your loan. %%If you’re interested in this program, one of our home loan experts can guide you through how it works and help find out if you qualify.%%',
  },
  howRoundUpWorks: {
    title: 'More about how round ups work ',
    body:
      'Each transfer will be between $5 - $20, and we’ll do our best to make sure your round up transfers won’t get you close to a $0 balance. We round up transactions like debit card purchases, electronic payments, and checks written from your Ally Interest Checking account. Track more than one account for round ups. Make changes or stop round ups whenever you want. Keep in mind you can’t cancel this type of transfer once it starts, but you can instantly move money back to your checking account whenever you want.',
  },
  spendingBuckets: {
    title: 'Spending Buckets',
    body:
      'Think of spending buckets as digital envelopes that help you set spending limits. They’ll give you a clearer picture of your spending habits and an opportunity to create better ones.',
  },
  moreOnTheMin: {
    title: 'More on the minimum.',
    body:
      'In order to receive our advisory services, we’ll ask you to invest at least $100,000 with us that we’ll directly manage (we call this your assets under care). However, in order to meet the $100,000 minimum for scheduling a call, we allow you to count investable assets from anywhere, not just those set up through us. Investable assets include cash, stocks, mutual funds, retirement accounts (including IRA accounts and old employer 401(k)s), and more. They don’t include things like your home, cars, life insurance policies, or a current employer’s 401(k).',
  },
  blendedAnnualAdvisory: {
    title: 'More on our advisory fee.',
    body:
      "We charge a competitive annual fee of 0.75-0.85%, which we'll automatically deduct from your account typically at the start of each month. We calculate this fee by assessing your daily Wealth Management portfolio balance or the total daily balance of all your portfolios (if you have more than one Wealth Management account). Your portfolio (or portfolios) consists of funds you've invested directly with us, also called your assets under care, not funds or assets held at other financial institutions. The rate is tiered, meaning your portfolio gets charged separate rates depending on your balance: - The rate tier for your first $250,000 is 0.85% - The rate tier for your next $750,000 up to $1 million is 0.80% - The rate tier for any additional balance over that first $1 million is 0.75%. Also, the fee is blended. This means once the rates are applied for each tier, we add together the separate calculated fees to arrive at one blended amount. Keep in mind, if your Wealth Management portfolio holds a Separately Managed Account (SMA), you'll pay a separate fee in addition to the advisory fee. This SMA fee covers the selection of, due diligence for, and access to the SMA. Your advisor will walk you through this fee information and can provide further details.",
  },
  assetsUnderCare: {
    title: 'Assets under care',
    body:
      'These are the funds you had invested with us or moved over with us in order to create your Wealth Management portfolio. We directly manage these funds. They do not include any assets or accounts you’ve linked to track from financial institutions outside of Ally.',
  },
  strategy: {
    title: 'Portfolio strategies',
    body:
      'Foundational – For this strategy, your investments can focus on basics like income generation and tax optimization. Impact – Investments for this strategy specifically have an environmental, social, or governance (ESG) impact. Thematic – A thematic approach allows you to invest more heavily in specific themes, sectors, or industries that matter to you.',
  },
  moreOnInvestableAssets: {
    title: 'More on investable assets.',
    body:
      'Investable assets include cash, stocks, mutual funds, retirement accounts (including IRA accounts and old employer 401(k)s), and more. They don’t include things like your home, cars, life insurance policies, or a current employer’s 401(k). In order to meet the $100,000 minimum for scheduling a call, we allow you to count investable assets from anywhere, not just those set up through us. However, to receive our advisory services, we’ll ask you to invest at least $100,000 with us that we’ll directly manage (we call this your assets under care). Your advisor can provide more details.',
  },
  coverdraft: {
    title: 'CoverDraft℠',
    body:
      'CoverDraft℠ is a service, not a line of credit or a loan. Keep in mind, we pay overdrafts at our discretion, and we don’t guarantee we’ll always cover an overdraft. Transactions that aren’t covered are declined.',
  },
  savingsBuckets: {
    title: 'Savings buckets',
    body:
      'Like digital envelopes, savings buckets stash your cash for whatever you want (or want to do). Use them to track your progress, all while earning interest on your total balance.',
  },
  fiduciaryDefinition: {
    title: 'Fiduciary',
    body:
      'A fiduciary is required to act in your best interest and provide meaningful support. It’s not only the law but the right thing to do.',
  },
  diversificationDefinition: {
    title: 'Diversification',
    body:
      'The strategy of spreading investments across a number of different securities to reduce the risk of putting all your eggs in one basket.',
  },
  advisoryFee: {
    title: 'More on our advisory fee.',
    body: `Our rates are tiered, meaning the more you invest with us, the lower your rate gets. 
      We apply different rates to different portions of the total value of your portfolios (what we also call your assets under care). We then add (or blend) those together to arrive at one fee. By blending, we make sure you’re getting the most bang for your buck.  
      The rate tier for your first $250,000 is 0.85%. The rate tier for the next $750,000 up to $1 million is 0.80%. The rate tier for any additional balance over that first $1 million is 0.75%.
      To break it down – for a portfolio that stays around $100,000, we’d apply one flat rate of 0.85% to reach a yearly fee of about $850.  
      For a portfolio that stays around $500,000, we’d apply a 0.85% rate to the first $250,000, but a lower 0.80% rate to the next $250,000, to get to a blended rate of 0.825%, or a fee of about $4,125 a year. 
      Since a typical daily portfolio balance will move as the market moves, we total the value of your accounts daily to make sure we’re applying the right fees each day. Also, we’ll automatically deduct what you owe at the start of each month.  
      Your advisor will walk you through this fee info and gladly answer any questions.`,
  },
  minimumInvestment: {
    title: 'More on the minimum.',
    body: `To qualify for our advisory services, we’ll ask you to invest at least $100,000 with us that we’ll directly manage (we call this your assets under care). 
      However, to meet the $100,000 minimum for scheduling a call, we allow you to count investable assets from anywhere – not just those set up through us. 
      Investable assets include cash, stocks, mutual funds and retirement accounts, including IRA accounts and old employer 401(k)s. They don’t include things like your home, cars, life insurance policies or a current employer’s 401(k).`,
  },
  gettingStarted: {
    title: 'How to access our Personal Advice.',
    body: `Personal Advice is currently available to customers only. 
      If you already have an account, log in with your Bank, Invest, Credit Card or Mortgage credentials then schedule a call to get started.`,
  },
  personalizedPlan: {
    title: 'Personalized financial plan, explained.',
    body:
      'Think of the proposal as your custom-made financial road map, summarizing where you currently are, where you’d like to go and how to get there. Through projections and visualizations, we show you the tailored financial plan your advisor’s laid out for you.',
  },
  portfolioStrategies: {
    title: 'Portfolio strategies, explained.',
    body: `First is our impact strategy. Investments for this strategy specifically have an environmental, social or governance (ESG) impact.
      Second is our foundational strategy. For this strategy, your investments can focus on basics like income generation and tax optimization.
      Finally, our thematic approach allows you to invest more heavily in specific themes, sectors or industries that matter to you.`,
  },
};

export default createStore('dejargMap', dejargMap);
