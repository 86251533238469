// TODO: turns out this data transform is not needed, the same list items
// exist elsewhere in the correct format. So the stores should be refactored
// for proper content sharing post release
//
// i.e. 'Overdraft item paid or overdraft item returned'

import fetchLaunchDarklyFlag from "../../../utilities/fetch-feature-flags";

// is in osa.jsx in the proper format
const mungeList = items => [].concat(...items.map((item) => {
  const { title, rows } = item;
  return rows.map((row) => {
    const { heading, content } = row;
    return {
      ...row,
      heading: title,
      content: heading,
      subCopy: content,
    };
  });
}));

const preTitle = '…and we won’t hide the fees we do have.';

const monthlyMaintenanceFees = {
  heading: 'Monthly maintenance fees',
  content: '$0',
};

const overdraftItemFees = {
  heading: 'Overdraft item fees',
  content: '$0',
  dejargKey: 'overdraftItem',
};

const electronicTransfers = {
  heading: 'Electronic transfers to your account at another institution',
  content: '$0',
};

const statementCopiesOnline = {
  heading: 'Statement copies online',
  content: '$0',
};

const allyBankStandardChecks = {
  heading: 'Ally Bank standard checks',
  content: '$0',
};

const incomingWires = {
  heading: 'Incoming wires',
  subCopy: 'Domestic and International',
  content: '$0',
};

const postagePaidEnvelopes = {
  heading: 'Postage-paid deposit envelopes',
  content: '$0',
};

const officialCashiersChecks = {
  heading: "Official / cashier's checks",
  content: '$0',
  dejargKey: 'checks',
};

const unlimitedDeposits = {
  heading: 'Unlimited Deposits',
  content: '$0',
};

const unlimitedDepositsAndWithdrawals = {
  heading: 'Unlimited Deposits and ATM withdrawals',
  content: '$0',
};

const crossBorder = {
  title: 'cross border / currency conversion transaction',
  rows: [
    {
      heading: 'Up to 1% of the transaction',
      content: '',
      dejargKey: 'crossBorder',
    },
  ],
};

const stopPayment = {
  title: 'Stop payment item',
  rows: [
    {
      heading: '$15.00',
      content: '',
      dejargKey: 'stopPayment',
    },
  ],
};

const rushDelivery = {
  title: 'Rush delivery of debit cards or other items',
  rows: [
    {
      heading: '$15.00',
      content: '',
    },
  ],
};

const overnightBillPay = {
  title: 'Overnight Bill Pay',
  rows: [
    {
      heading: '$14.95',
      content: 'Delivery by mail',
    },
  ],
};

const sameDayBillPay = {
  title: 'Same-day Bill Pay',
  rows: [
    {
      heading: '$9.95',
      content: 'Electronic delivery when available',
    },
  ],
};

const outgoingWires = {
  title: 'Outgoing wires',
  rows: [
    {
      heading: '$20.00',
      content: 'Domestic only',
      dejargKey: 'domesticWire',
    },
  ],
};

// TODO: Cleanup as part of STOR-46749
const excessiveTransaction = {
  title: 'Excessive transactions fee',
  rows: [
    {
      heading: '$10.00',
      content: '',
      subCopy2: 'per transaction',
    },
  ],
};

const excessiveTransactionNoHiddenFees = {
  heading: 'Excessive transactions fee',
  content: '$0.00',
  subCopy2: 'per transaction',
  dejargKey: 'excessiveTransactionsCovidRegD',
};

const expeditedDelivery = {
  title: 'Expedited delivery',
  rows: [
    {
      heading: '$15.00',
    },
  ],
};

const acctResearch = {
  title: 'Account research fee',
  rows: [
    {
      heading: '$25.00',
      content: '',
      subCopy2: 'per hour',
      dejargKey: 'accountResearch',
    },
  ],
};

const noHiddenFees = {
  title: 'no hidden fees',
  postTitle: 'There’s a lot we don’t charge for',
  rows: [
    monthlyMaintenanceFees,
    overdraftItemFees,
    electronicTransfers,
    statementCopiesOnline,
    incomingWires,
    postagePaidEnvelopes,
    officialCashiersChecks,
    unlimitedDeposits,
  ],
};

const mmaNoHiddenFees = {
  title: 'no hidden fees',
  postTitle: 'There’s a lot we don’t charge for',
  rows: [
    monthlyMaintenanceFees,
    overdraftItemFees,
    electronicTransfers,
    statementCopiesOnline,
    allyBankStandardChecks,
    incomingWires,
    postagePaidEnvelopes,
    officialCashiersChecks,
    unlimitedDepositsAndWithdrawals,
  ],
};

const mmaFeesTable = [
  mmaNoHiddenFees,
  {
    preTitle,
    rows: mungeList([
      excessiveTransaction,
      expeditedDelivery,
      stopPayment,
      outgoingWires,
      acctResearch,
    ]),
  },
  Object.assign({ dropdown: true }, crossBorder),
];

// TODO: Cleanup as part of STOR-46749
const mmaRegDNoHiddenFees = {
  title: 'no hidden fees',
  postTitle: 'There’s a lot we don’t charge for',
  rows: [
    monthlyMaintenanceFees,
    overdraftItemFees,
    excessiveTransactionNoHiddenFees,
    electronicTransfers,
    statementCopiesOnline,
    allyBankStandardChecks,
    incomingWires,
    postagePaidEnvelopes,
    officialCashiersChecks,
    unlimitedDepositsAndWithdrawals,
  ],
};

const mmaRegDFeesTable = [
  mmaRegDNoHiddenFees,
  {
    preTitle,
    rows: mungeList([
      expeditedDelivery,
      stopPayment,
      outgoingWires,
      acctResearch,
    ]),
  },
  Object.assign({ dropdown: true }, crossBorder),
];

const feesTable = [
  noHiddenFees,
  {
    preTitle,
    rows: mungeList([
      stopPayment,
      rushDelivery,
      overnightBillPay,
      sameDayBillPay,
      outgoingWires,
      acctResearch,
    ]),
  },
  Object.assign({ dropdown: true }, crossBorder),
];

export {
  preTitle,
  monthlyMaintenanceFees,
  electronicTransfers,
  statementCopiesOnline,
  allyBankStandardChecks,
  incomingWires,
  postagePaidEnvelopes,
  officialCashiersChecks,
  unlimitedDeposits,
  unlimitedDepositsAndWithdrawals,
  crossBorder,
  overdraftItemFees,
  stopPayment,
  rushDelivery,
  overnightBillPay,
  sameDayBillPay,
  outgoingWires,
  excessiveTransaction,
  expeditedDelivery,
  acctResearch,
  noHiddenFees,
  feesTable,
  mmaNoHiddenFees,
  mmaFeesTable,
  mmaRegDFeesTable,
};
