import React from 'react';
import { setScreenTitle } from 'utilities';
import './page-title-heading.scss';

const pageHeaderID = 'pageHeader';
const headerClass = 'sr-text';
const docTitle = document.title;

const PageTitleHeading = ({ title }) => {
  const newTitle = title || docTitle;
  document.title = newTitle;
  setScreenTitle(newTitle);
  return <h1 id={pageHeaderID} className={headerClass}>{title}</h1>;
};

export default PageTitleHeading;
