import React from 'react';
import resolveImagePath from 'utilities/resolve-image-path';
import Copy from '../utility-components/copy';
import fetchLaunchDarklyFlag from 'utilities/fetch-feature-flags';
import './valueprop.scss';

/**
 * Creates an image with a list from Props passed in through data store
 *
 * @param {String} title centered and bolded title for component `Check out this cool product`
 * @param {String} subtitle
 * @param {path} imgSrc path to image to go along side of the list `other/STK-EPR-Graph-OSA.png`
 * @param {String} imgClassName className to apply to image `img-left`
 * @param {String} copy paragraph on the right of the image (rather than bullets) `Paragraph text`
 * @param {Boolean} column alters layout when true
 *
 * @returns JSX element with image and copy styling
 */

const ValueProp = ({ title, subtitle, copy, linkText, imgSrc, column, flag }) => (
  <section className={`valueProp ${column ? 'valueProp--column' : ''}`}>
    {imgSrc && (
      <img className="valueProp-icon" alt=" " aria-hidden="true" src={flag && flag.imgSrc && fetchLaunchDarklyFlag(flag.name) ? resolveImagePath(flag.imgSrc) : resolveImagePath(imgSrc) } />
    )}
    <div className="valueProp-content">
      {title && (
        <h3 className="valueProp-title">
          <Copy>{flag && flag.title && fetchLaunchDarklyFlag(flag.name) ? flag.title : title}</Copy>
        </h3>
      )}
      {subtitle && (
        <p className="valueProp-subtitle">
          <Copy>{flag && flag.subtitle && fetchLaunchDarklyFlag(flag.name) ? flag.subtitle : subtitle}</Copy>
        </p>
      )}
      {copy && (
        <p className="valueProp-copy">
          <Copy>{flag && flag.copy && fetchLaunchDarklyFlag(flag.name) ? flag.copy : copy}</Copy>
        </p>
      )}
      {linkText && (
        <p className="valueProp-linkText">
          <Copy>{flag && flag.linkText && fetchLaunchDarklyFlag(flag.name) ? flag.linkText : linkText}</Copy>
        </p>
      )}
    </div>
  </section>
);

export default ValueProp;
