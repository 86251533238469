import { createStore } from 'satcheljs';

const investContent = {
  pageTitle: 'Invest',
  accountTypes: [
    {
      config: {
        includeRating: false,
      },
      header: 'Robo Portfolios',
      region: 'Robo Portfolios',
      cta: {
        text: 'Explore',
        label: 'Explore Robo Portfolios',
        url: '/invest/robo-portfolios',
      },
      template: 'invest',
      intro: 'Automated investing. No experience required.',
      tiles: [
        {
          template: 'three-row-text',
          link: '/invest/robo-portfolios',
          type: 'invest',
          copy: [
            {
              top: 'Start investing with',
              rate: '$100.',
              bottom: 'No advisory fee. Professionally managed.',
            },
          ],
        },
      ],
    },
    {
      config: {
        includeRating: false,
      },
      header: 'Self-Directed Trading',
      region: 'Self-Directed Trading',
      cta: {
        text: 'Explore',
        label: 'Explore Self Directed Trading',
        url: '/invest/self-directed-trading',
      },
      template: 'invest',
      intro: 'Intelligent investing for the independent type.',
      tiles: [
        {
          template: 'three-row-text',
          link: '/invest/self-directed-trading',
          type: 'invest',
          copy: [
            {
              title: 'Trade stocks and ETFs',
              top: 'Commission',
              rate: '$0',
              bottom: 'no account minimums.',
              accentRate: true,
            },
            {
              title: 'Plus, trade options',
              top: 'Commission',
              rate: '$0',
              bottom: '+50&cent; per contract fee',
              accentRate: true,
            },
          ],
        },
      ],
      disclosure: 'Other fees may apply.',
    },
  ],
};

export default createStore('investContent', investContent);
