import React from 'react';
import HeadingLevel from 'components/utility-components/heading-level';
import Button from 'components/button';
import ContentLoader from 'components/utility-components/content-loader';
import { swapContent } from 'utilities';
import productStore from 'stores/products/';
import './text-with-rate.scss';

const TextWithRate = ({
  rateSection, cta, topCopy, bottomCopy, label,
}) => {
  const { skin, url, text } = cta;
  const className = bottomCopy ? 'bottom-text-container' : 'text-container';
  const osaRate = `${productStore().onlineSavings.terms['0M'].tier1.apy.toFixed(2)}%`;

  return (
    <div className="text-with-rate-template">
      <div className={className}>
        {topCopy && <HeadingLevel ariaLabel={label} title={topCopy} titleLevel="h2" />}
        {rateSection && (
          <div className="rate-section">
            <ContentLoader className="css-loader-card" flag="rate">
              <p className="rate-header">{rateSection.rateHeader}</p>
              <p className="rate">{swapContent(rateSection.rate, osaRate)}</p>
              <p className="rate-footer">{rateSection.tierInfo.tier1}</p>
              {rateSection.extraInfoBelow && <p className="rate-footer">{rateSection.extraInfoBelow}</p>}
            </ContentLoader>
          </div>
        )}
      </div>
      <Button
        skin={skin}
        to={url}
        button={text}
      />
    </div>
  );
};

export default TextWithRate;
