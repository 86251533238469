import { createStore } from 'satcheljs';
import { feesTable } from 'stores/pages/shared';
import { device } from 'utilities';

const isDroid = device === 'android';

// make titles caps
const tierInfo = {
  tier1: 'Less than $15,000 minimum daily balance',
  tier2: '$15,000 minimum daily balance',
};

const checkingContent = {
  hero: {
    pageTitle: 'Spending Account',
    config: {
      bottomColor: 'checking',
      template: 'product-page-header',
      product: 'checking',
    },
    content: {
      rateText: `%s${isDroid ? '++%++' : '%'} APY`,
      reviewText: '%s (%s Reviews)',
      a11yText: 'Rated %s out of 5 stars, based on %s reviews.',
      tiers: [
        'Less than $15,000',
        '$15,000 Minimum',
      ],
      tierInfo,
      failTextLabel: 'Rates did not load.',
      hiddenText: tierInfo,
      focusType: 'checkingRate',
      cta: {
        text: 'Open Account',
        url: 'ally://account_opening/DDA',
      },
    },
    disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s.',
  },
  introBlurb: {
    title: 'Make everyday banking count.',
    titleLevel: 'h2',
    ptClassName: `padding-bottom${device === 'android' && '-small'}`,
    className: 'not-nested',
    copy: 'Our rates are consistently among the most competitive in the country. You’ll always earn interest, but Spending Account balances of $15,000 or more get an even higher rate. Keep in mind, rates are variable and may change after you open the account, and fees may reduce earnings.',
  },
  bullets: [
    'No minimum deposit required to open your account',
    'Your deposits are insured by the ##FDIC::fdic::## up to the maximum allowed by law @@Maximize your coverage::https://www.ally.com/bank/fdic/::@@',
    'Certified by Bank On for meeting the National Account Standards as a safe, low-cost, low-fee account',
  ],
  infoCards: {
    title: 'Our favorite features',
    cards: [
      {
        icon: 'checking/spendingbuckets.svg',
        title: 'Spending Buckets.',
        copy: 'Buckets help you stash money away for ongoing expenses like rent and groceries. It\'s a great way to curb overspending. And if you\'re looking to save money, give our popular ##savings buckets::savingsBuckets::## a try.',
        flag: 'FF_spending-buckets',
      },
      {
        icon: 'checking/peaceofmind.svg',
        title: 'Peace of mind.',
        copy: 'Our award-winning customer care is available 24/7.',
      },
      {
        icon: 'checking/getcash.svg',
        title: 'Get Cash.',
        copy: `Use any Allpoint++®++ ATM in the U.S. for free, plus we reimburse up to $10 per statement cycle for fees charged at other ATMs nationwide.${device === 'ios' ? ' @@Find ATMs::https://www.ally.com/bank/find-atms/::@@' : ''}`,
        cardLink: '@@Find ATMs::https://www.ally.com/bank/find-atms/::@@',
      },
      {
        icon: 'checking/shield.svg',
        title: 'Get a double line of defense against accidental overdrafts.',
        copy: 'Use Overdraft Transfer Service or ##CoverDraft℠::coverdraft::## service as your fee-free safety net. Or, use them both together for even more coverage.',
      },
      {
        icon: 'checking/makedeposits.svg',
        title: 'Deposit checks remotely.',
        copy: 'With @@Ally eCheck Deposit℠::https://www.ally.com/bank/online-banking/how-to-bank-with-ally/::@@ you can deposit checks right from your smartphone. Just snap a photo and you\'re good to go. Plus, watch your money grow fast with interest compounded daily.',
      },
      {
        icon: 'checking/paybills.svg',
        title: 'Pay bills.',
        copy: 'Set up one-time or recurring bill payments, right from your checking account.',
      },
      {
        icon: 'checking/lightbulb.svg',
        title: 'Spend wisely.',
        copy: 'With Card Controls in our app, you can monitor and manage your Debit MasterCard with EMV chip technology you can trust.',
      },
      {
        icon: 'checking/roundup.svg',
        title: 'Save when you spend with Round Ups.',
        copy: 'Access a special savings booster when you have a Spending and Savings Account with us. We\'ll track transactions we can round up to the nearest dollar, then transfer to your savings.',
        dejarg: '##More about how it works::howRoundUpWorks::##',
      },
    ],
  },
  tableSections: feesTable,
};

export default createStore('checkingContent', checkingContent);
