import React from 'react';
import DejargRows from './dejarg-rows';

const DejargContent = ({
  rows,
  active,
  parent,
  name,
}) => {
  const animationDir = active ? 'open' : 'close';
  const className = `${name}-panel ${name}-panel-${animationDir}`;
  const containerAttrs = {
    className,
    'aria-labelledby': parent,
    'aria-hidden': !active || null,
  };

  return (
    <div {...containerAttrs}>
      <DejargRows rows={rows} name={name} />
    </div>
  );
};

export default DejargContent;
