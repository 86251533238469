import { globalParams } from 'utilities';

const site = 'ally mobile app';
const category1 = 'Web View';
const isAuthenticated = !!(globalParams.guid || globalParams.cif);

// this needs to be defined before any other files
// interact with tracking
window.digitalData = {
  user: {
    cif: globalParams.cif || '',
    guid: globalParams.guid || '',
    isAuthenticated: isAuthenticated ? 'Yes' : 'No',
  },
  page: {
    site,
    category1,
    category2: '',
    category4: '',
    pageName: '',
    title: '',
    lineOfBusiness: 'Corporate',
    breakpoint: '',
    orientation: '',
  },
};

const dispatchDDO = () => {
  const event = new CustomEvent('trackPageLoad');
  document.body.dispatchEvent(event);
};

// TODO: move to util with DDO stuff above and on index.html
const get = ({ pageTitle = '', pageName = '', lineOfBusiness = 'Bank' }) => ({
  page: {
    category2: pageTitle,
    category4: pageName,
    pageName: `${site}:${category1}:${pageTitle ? `${pageTitle}:` : ''}${pageName}`,
    lineOfBusiness,
  },
});

const set = DDO => {
  Object.assign(window.digitalData.page, DDO.page);
  return window.digitalData;
};

const updateDDO = args => {
  const obj = get(args);
  set(obj);
};

const getNumber = str => {
  const numArr = str.match(/\d+/);
  if (numArr) {
    return numArr.map(Number)[0];
  }
  return '';
};

const getFormat = (txt, num) => {
  if (txt.includes('year')) {
    return `${num} yr`;
  }
  if (txt.includes('month')) {
    return `${num} mo`;
  }
  if (txt.includes('less than')) {
    return `Less than ${num}K`;
  }
  if (txt.includes('minimum') || txt.includes(',000+')) {
    return `${num}K Min`;
  }
  if (txt.includes('$0')) {
    return 'Less than 5K';
  }
  if (txt.includes('$5,000')) {
    return '5K Min';
  }

  return txt;
};

const makeTrackText = text => {
  const txt = text.toLowerCase();
  const num = getNumber(txt);
  if (Number.isInteger(num)) {
    return getFormat(txt, num);
  }
  return text;
};

export { dispatchDDO, updateDDO, makeTrackText };
