import React from 'react';
import DejargDropdown from 'components/dejarg-dropdown';
import DetailsList, { GenerateListRows, GenerateColumnRows } from 'components/details-list';
import { device } from 'utilities';

const isAndroid = device === 'android';

const TableSections = ({ sections }) => sections.map((props, index) => {
  const {
    title,
    rows,
    dropdown,
  } = props;
  const key = `table-section-${index}`;

  if (dropdown) {
    return <DejargDropdown key={key} {...{ title, rows }} />;
  }

  if (isAndroid) {
    return (
      <DetailsList key={key} {...props}>
        <GenerateListRows {...{ rows }} />
      </DetailsList>
    );
  }

  return (
    <DetailsList key={key} {...props}>
      <GenerateColumnRows {...{ rows }} />
    </DetailsList>
  );
});

export default TableSections;
