exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".aos-application-container .aos-application .ORXbhe_rqgdzVc5LXsQ7k{display:flex;align-items:center}.aos-application-container .aos-application ._2-EGqLsHAfeJsq8DSXjcLb{padding-left:5px}.aos-application-container .aos-application .qe12UuOmpq9Mo5dYnItWA{width:1px;height:1px;margin-bottom:-1px;overflow:hidden;opacity:0;margin:0;padding:0;pointer-events:none}", "", {"version":3,"sources":["/runnertmp/builds/c3Z3HgyH/0/allyfinancial/ally-digital/applications/ui/explore-products-and-rates/src/packages/auth-sales-shared/components/ProductRatings/ProductRatings.scss","/runnertmp/builds/c3Z3HgyH/0/allyfinancial/ally-digital/applications/ui/explore-products-and-rates/src/packages/auth-sales-shared/assets/_mixins.scss"],"names":[],"mappings":"AAMM,mEACE,aACA,kBAAA,CAGF,qEACE,gBAAA,CAGF,mECdJ,UACA,WACA,mBACA,gBACA,UACA,SACA,UACA,mBAAA,CAAA","file":"ProductRatings.scss","sourcesContent":["@import '../../assets/main.scss';\n\n:global(.aos-application-container) {\n  :global(.aos-application) {\n    :local {\n\n      .productRating {\n        display: flex;\n        align-items: center;\n      }\n\n      .textInfo {\n        padding-left: 5px;\n      }\n\n      .srText {\n        @include srOnly;\n      }\n    }\n  }\n}\n","@mixin srOnly {\n  width: 1px;\n  height: 1px;\n  margin-bottom: -1px;\n  overflow: hidden;\n  opacity: 0;\n  margin: 0;\n  padding: 0;\n  pointer-events: none;\n}\n\n@mixin srOnlyInline {\n  display: inline-block;\n  width: 1px;\n  margin-right: -1px;\n  height: 1px;\n  overflow: hidden;\n  position: absolute;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"productRating": "ORXbhe_rqgdzVc5LXsQ7k",
	"textInfo": "_2-EGqLsHAfeJsq8DSXjcLb",
	"srText": "qe12UuOmpq9Mo5dYnItWA"
};