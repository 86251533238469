import React, { PureComponent } from 'react';
import { afterDraw } from 'utilities';
import './ada-text.scss';


// TODO: this component should be removed in favor of a simpler approach to a11y text
// it was created in reponse to a defect opened by QA that may not be valid per metronome guidelines

/**
 * A component to set the accesibility text to the proper position
 *
 * @class AdaText
 * @extends {PureComponent}
 */
class AdaText extends PureComponent {
  constructor() {
    super();
    this.state = {
      fontSize: '1px',
    };
  }


  /**
   * After the initial render, update the font size to be the height of the container
   * this causes the iOS VoiceOver indicator to be in the right place
   *
   * @memberof AdaText
   */
  componentDidMount() {
    afterDraw(() => {
      this.updateFontSize();
    });
  }


  /**
   * Sets the font size of the hidden text to the height of its container
   *
   * @memberof AdaText
   */
  updateFontSize() {
    if (this.el) {
      const { offsetHeight } = this.el.parentElement;
      const fontSize = offsetHeight || 1;
      this.setState({ fontSize });
    }
  }


  /**
   * Create a Component with accessibility text that fits itself to its parent element.
   *
   * @returns
   * @memberof AdaText
   */
  render() {
    const { children, styles } = this.props;
    const { fontSize } = this.state;
    const style = Object.assign({
      fontSize,
    }, styles);

    return (
      <div
        className="AdaText"
        style={style}
        ref={(e) => {
          this.el = e;
        }}
      >
        {children}
      </div>
    );
  }
}

export default AdaText;
