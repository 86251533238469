import React from 'react';

import LinkTo from 'components/utility-components/link-to';
import resolveImagePath from 'utilities/resolve-image-path';

import './image-with-cta.scss';

const ImageWithCTA = ({ cta, image, tagline }) => {
  const { url, text } = cta;
  return (
    <div className="image-with-cta-template">
      <div className="upper-section">
        <img className="hero-image" aria-hidden="true" src={resolveImagePath(image)} alt=" " />
        <p className="tagline">{tagline}</p>
      </div>
      <div className="link-container">
        <LinkTo to={url}>{text}</LinkTo>
      </div>
    </div>
  );
};

export default ImageWithCTA;
