import getTerm from './getTerm';

/**
 * Returns the selected term and tier of product
 *
 * @param {string} term selected term returned - 36M
 * @param {object} terms returns an object of available terms with apy and apr rates split by tiers
 *   term: 24
 *    tier1: {apy: 2.5, apr: 2.47}
 *    tier2: {apy: 2.5, apr: 2.47}
 *    tier3: {apy: 2.5, apr: 2.47}
 * @param {string} tier returns selected tier - tier1
 * @returns {string} selectedTerm - 24M
 * @returns {string} selectedTier - tier1
 */

const getSelected = (term, terms, tier) => {
  const selectedTerm = getTerm(term, terms);
  const selectedTier = terms[selectedTerm][tier] ? tier : 'tier1';

  return {
    selectedTerm,
    selectedTier,
  };
};

export default getSelected;
