import { dejargMap } from 'stores';
import { device } from 'utilities';

const androidMethods = androidObj => {
  switch (androidObj.type) {
    case 'resetFocus':
      Android.clearFocus();
      break;
    case 'screenTitle':
      Android.setTitle(androidObj.data.title);
      break;
    case 'dejarg':
      Android.androidAlert(JSON.stringify(androidObj));
      break;
    default:
      throw new Error('Android type not valid');
  }
};

const appBridge = objCrossingBridge => {
  const stringifiedObj = JSON.stringify(objCrossingBridge);
  try {
    switch (device) {
      case 'android':
        androidMethods(objCrossingBridge);
        break;
      case 'windows':
        window.external.notify(stringifiedObj);
        break;
      case 'ios':
        window.webkit.messageHandlers.callBackHandler.postMessage(stringifiedObj);
        break;
      default:
        throw new Error('ERR: device not valid');
    }
  } catch (err) {
    console.log(objCrossingBridge);
    // const { data: { title, body } } = objCrossingBridge;
    // if (title && body) {
    //   console.log(`${title}:\n${body}`);
    // }
  }
};

const focusTopOfApp = () => {
  const focusObj = {
    type: 'resetFocus',
    data: {
      title: '',
      body: '',
    },
  };
  appBridge(focusObj);
};

const nativeAlertDejarg = dejargKey => {
  const keyValue = dejargMap()[dejargKey];
  const keyObj = {
    type: 'dejarg',
    data: {
      title: keyValue.title,
      body: keyValue.body,
    },
  };
  appBridge(keyObj);
};

const setScreenTitle = screenTitle => {
  const titleObj = {
    type: 'screenTitle',
    data: {
      title: screenTitle,
      body: '',
    },
  };
  appBridge(titleObj);
};

export { focusTopOfApp, nativeAlertDejarg, setScreenTitle };
