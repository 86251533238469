import React from 'react';
import Card from 'components/card';

// TODO: Discussion around use of this component.

const Tiles = ({ index, tiles }) => (
  <div className={`menu-item-link-container container-${index}`}>
    <TileMap {...{ tiles }} />
  </div>
);

const TileMap = ({ tiles }) => tiles.map((tile, index) => <Card key={index} {...tile} />);

export default Tiles;
