import React from 'react';

/**
 * Returns aria-hidden paragraph what will be displayed if the rates fail
 *
 * @param {string} failText Rate fail text `Great Rates on all balance tiers`
 */

const Failed = ({ failText }) => (
  <p className="rate-info failed-text" aria-hidden="true">
    {failText}
  </p>
);

export default Failed;
