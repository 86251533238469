import React from 'react';
import HeadingLevel from 'components/utility-components/heading-level/heading-level';


/**
 * Returns HeadingLevel react component based on props passed in
 *
 * @param {string} title 'No Hidden Fees' - used as title text for component.
 * @param {any} backLink backlink to pass to HeadingContent if needed. Can be undefined
 * @param {any} titleLevel 'h4' - used to changed heading level if needed. If undefined defaults to 'h2'.
 *
 * @returns {Component} <HeadingLevel {...headingLevel} />
 */

const ItemTitle = ({ title, titleLevel }) => {
  const headingLevel = {
    titleLevel: titleLevel || 'h2',
    titleClass: 'menu-item-title',
    title,
  };

  return (
    <HeadingLevel {...headingLevel} />
  );
};

export default ItemTitle;
