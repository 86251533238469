import { createStore } from 'satcheljs';

// TODO: make single url configuration so that
// they aren't repeated all over the codebase
// i.e.
// const urlObj = {
//    CC_URL: '/credit-card',
//    CHK_URL: '/savings'.
// }
const exploreContent = {
  hero: {
    pageTitle: 'Explore',
    config: {
      product: 'onlineSavings',
      term: '0M',
      tier: 'tier1',
      bottomColor: 'savings',
    },
    content: {
      reviewText: '%s (%s Reviews)',
      a11yText: 'Rated %s out of 5 stars, based on %s reviews.',
      productName: 'Savings Account',
      tierInfo: 'On all balance tiers',
      failText: 'Great Rates',
      failTextLabel: 'Great rates on all balance tiers.',
      focusType: 'exploreRate',
      cta: {
        text: 'Start Saving',
        url: '/savings/osa',
      },
      image: 'explore/bank-savings-hero.svg',
    },
  },
  menuItems: [
    {
      title: 'Ally Invest',
      tiles: [
        {
          template: 'two-row-icon',
          image: 'explore/invest-sdt.svg',
          copy: 'Self-Directed Trading',
          type: 'invest',
          link: '/invest/self-directed-trading',
        },
        {
          template: 'two-row-icon',
          image: 'explore/invest-mp.svg',
          copy: 'Robo Portfolios',
          type: 'invest',
          link: '/invest/robo-portfolios',
        },
        {
          template: 'two-row-icon',
          image: 'explore/invest-mp.svg',
          copy: 'Personal Advice',
          type: 'invest',
          link: '/invest/personal-advice',
        },
      ],
    },
    {
      title: 'Ally Bank',
      tiles: [
        {
          template: 'two-row-icon',
          image: 'explore/bank-checking.svg',
          copy: 'Checking',
          type: 'checking',
          link: '/checking',
        },
        {
          template: 'two-row-icon',
          image: 'explore/bank-savings.svg',
          copy: 'Savings',
          type: 'savings',
          link: '/savings/osa',
        },
        {
          template: 'two-row-icon',
          image: 'explore/bank-cds.svg',
          copy: 'CDs',
          type: 'cds',
          link: '/cds',
        },
        {
          template: 'two-row-icon',
          image: 'explore/money-market.svg',
          copy: 'Money Market',
          type: 'money-market',
          link: '/savings/money-market',
        },
      ],
    },
    {
      title: 'Ally Home',
      tiles: [
        {
          template: 'two-row-icon',
          image: 'explore/home_loans.svg',
          copy: 'Buy a Home',
          type: 'home',
          link: '/home/purchase',
        },
        {
          template: 'two-row-icon',
          image: 'explore/home_refi.svg',
          copy: 'Refinance',
          type: 'home',
          link: '/home/refinance',
        },
      ],
    },
  ],
};

export default createStore('exploreContent', exploreContent);
