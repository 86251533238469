import afterDraw from './after-draw';

/**
 * redifine custom css properties to cascade out to document
 * NOTE: this is not supported in IE so fallbacks must be in place
 *
 * @param {Array} cssProperties - the names of the properties to define variables by
 * cssProperties = `['font-size', 'line-height']`
 */
const redifineCssVariables = (cssProperties, targetEl = document.body) => {
  afterDraw(() => {
    const { styleSheets } = document;
    const lastSheet = styleSheets[styleSheets.length - 1];
    const lastRule = lastSheet.rules.length;

    const toAdd = cssProperties
      .map(prop => {
        const rootVal = window
          .getComputedStyle(targetEl)
          .getPropertyValue(prop)
          .replace('px', '');
        return `--${prop}: ${rootVal};`;
      })
      .join('');

    /**
     * example rule:
     *
     * input: cssProperties = `['font-size', 'line-height']`
     * rule: `:root { --font-size: 16;--line-height: 16; }`
     */
    const rule = `:root { ${toAdd} }`;

    lastSheet.insertRule(rule, lastRule);
  });
};

export default redifineCssVariables;
