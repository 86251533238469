import React from 'react';
import Dejargonator from 'components/utility-components/dejargonator';
import { dejargMap } from 'stores';

const StyleText = ({ column, dejargKey }) => {
  const componentName = 'StyleText';
  const className = `${componentName}-under-dashed`;
  const { a11yText } = dejargMap()[dejargKey];

  return (
    <span className={className} aria-label={a11yText || column}>{column}</span>
  );
};

const InlineDejarg = ({
  dejargKey,
  column,
  copy,
  children,
}) => {
  const colAttrs = {
    className: copy && 'row-with-sub-copy',
  };

  return (
    <span {...colAttrs}>
      <Dejargonator dejargKey={dejargKey}>
        <StyleText column={children || column} dejargKey={dejargKey} />
      </Dejargonator>
      {copy && <p className="table-sub-copy" aria-hidden="true">{copy}</p>}
    </span>
  );
};

export default InlineDejarg;
