import { createStore } from 'satcheljs';

const mboxStore = {
  // 'ally-epnr-our-services-hero': {
  //   type: 'RATE_TABLE',
  //   title: 'Online Savings Account',
  //   apySubtext: 'On all balance tiers',
  //   productName: 'onlineSavings',
  //   term: 0,
  //   cta: {
  //     text: 'Open New Account',
  //     link: '/open-account/create-accounts?OAP=OSAV',
  //   },
  // },
  // 'ally-epnr-our-services-hero': {
  //   type: 'MARKETING',
  //   tagline: 'No advisory fees for all Ally Invest Cash-Enhanced Robo Portfolios. Start investing with $100.',
  //   title: 'Robo Portfolios',
  //   cta: {
  //     text: 'Create Your Plan',
  //     link: '/open-account/create-accounts?OAP=OSAV',
  //   },
  //   image: {
  //     path: 'home/mortgage-devices.png',
  //     altText: 'some text alt text',
  //   },
  // },
};

export default createStore('mboxStore', mboxStore);
