import React, { Component } from 'react';
import RatingsAndReviews from 'components/ratings-and-reviews';
import ContentLoader from 'components/utility-components/content-loader';
import FailedRatePlaceholder from 'components/utility-components/failed-rate-placeholder';
import { productStore } from 'stores';
import { observer } from 'mobx-react';
import LinkTo from 'components/utility-components/link-to';
import './product-page-header.scss';
import Copy from 'components/utility-components/copy';
import UPDATE_TERM_TIER from 'actions/pages';
import TermSelector from 'components/term-selector';
import { swapContent, device } from 'utilities';
import TierTabs from './helpers/tier-tabs';
import getSelected from './helpers/getSelected';
import innerCopy from './helpers/innerCopy';

class ProductPageHeader extends Component {
  constructor({ term, tier, product }) {
    super();
    const Product = productStore()[product];
    const { terms } = Product;
    const { selectedTier, selectedTerm } = getSelected(term, terms, tier);
    this.state = {
      tier: selectedTier,
      term: selectedTerm,
    };
    this.updateTier = this.updateTier.bind(this);
  }

  updateTier(tier) {
    const { product, terms } = this.props;
    const updateObject = {
      updating: 'tier',
      updateTo: tier,
      product,
    };

    UPDATE_TERM_TIER(updateObject);
    this.setState({ tier });

    if (terms) {
      this.setState({ term: terms[tier] });
    }
  }

  render() {
    const {
      cta,
      product,
      includeRating = true,
      term,
      tierInfo,
      extraInfo,
      extraInfoBelow,
      termSelector = false,
      rateText,
      reviewText,
      a11yText,
      failTextLabel,
      focusType,
    } = this.props;

    const Product = productStore()[product];
    const { overallRating, reviewCount } = Product.ratingInfo;

    const { tier } = this.state;
    const decidedTerm = this.state.term || term;
    const termMonths = parseInt(decidedTerm, 10);
    const daoURL = swapContent(cta.url, termMonths);
    const twoDecimalAPY = Product.terms[decidedTerm][tier].apy.toFixed(2);

    const TierCopy = () => (
      <React.Fragment>
        {extraInfo && <p className="tier-extra-line"><Copy>{extraInfo}</Copy></p>}
        <p className="tier-info"><Copy>{innerCopy(tierInfo, device, tier)}</Copy></p>
        {extraInfoBelow && <p className="tier-extra-line-bottom"><Copy>{extraInfoBelow}</Copy></p>}
      </React.Fragment>
    );

    return (
      // the styling for when ratings don't load changes the positioning of the rates,
      // but this only matters if ratings were to be included anyway
      <div className={`product-page-header-template ${(productStore().fetched.rating === 'failed') && includeRating ? 'rating-failed' : ''}`}>
        <TierTabs {...this.props} handleClick={this.updateTier} selectedTier={tier} />

        {termSelector
          && <TermSelector currentTerm={decidedTerm} product={product} />
        }

        <ContentLoader label={failTextLabel} className={`row product-rate text-center ${extraInfo || extraInfoBelow ? 'extra-line' : ''}`} flag="rate" loadAction="placeholder">
          {productStore().fetched.rate !== 'failed'
            ? (
              <p className="tier-rate">
                <Copy>
                  {swapContent(rateText, twoDecimalAPY)}
                </Copy>
              </p>
            )
            : <FailedRatePlaceholder label={failTextLabel} focusType={focusType} />
          }

          {/* NOTE: device is defined globally on index.html */}
          {device === 'ios' && <TierCopy />}

        </ContentLoader>

        {includeRating
          && (
          <ContentLoader className="row product-rating text-center" flag="rating">
            <RatingsAndReviews {...{
              product, overallRating, reviewCount, reviewText, a11yText,
            }}
            />
          </ContentLoader>
          )
        }

        {/* NOTE: device is defined globally on index.html */}
        {device === 'android' && <TierCopy />}


        <div className="link-container">
          <LinkTo to={daoURL}>{cta.text}</LinkTo>
        </div>
      </div>
    );
  }
}

export default observer(ProductPageHeader);
