import { createStore } from 'satcheljs';
import { device } from 'utilities';

const tiersText = {
  ios: {
    text: [
      'Less than $5,000',
      '$5,000 Minimum',
      '$25,000 Minimum',
    ],
    a11y: [
      'Minimum opening deposit of less than $5,000',
      'Opening deposit of $5,000 Minimum',
      'Opening deposit of $25,000 Minimum',
    ],
  },
  android: {
    text: [
      'Less than $5,000',
      '$5,000 Minimum',
      '$25,000 Minimum',
    ],
    a11y: [
      'Minimum opening deposit of less than $5,000',
      'Opening deposit of $5,000 Minimum',
      'Opening deposit of $25,000 Minimum',
    ],
  },
};

const tiers = tiersText[device] || tiersText.ios;

const irasContent = {
  accountTypes: [
    {
      tiers: tiers.text,
      hiddenText: tiers.a11y,
      config: {
        product: 'iraHighYield',
        includeRating: false,
      },
      header: 'IRA High Yield CD',
      region: 'IRA High Yield CD',
      cta: {
        text: 'Explore',
        label: 'Explore IRA High Yield CD',
        url: '/iras/irahycd',
      },
      template: 'multi-tile-scroll',
      intro: 'With an opening deposit of',
      tiles: [
        {
          copy: {
            term: '60M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '5-year term',
          },
          link: '/iras/irahycd/%s',
          type: 'iras',
        },
        {
          copy: {
            term: '36M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '3-year term',
          },
          link: '/iras/irahycd/%s',
          type: 'iras',
        },
        {
          copy: {
            term: '18M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '18-month term',
          },
          link: '/iras/irahycd/%s',
          type: 'iras',
        },
        {
          copy: {
            term: '12M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '12-month term',
          },
          link: '/iras/irahycd/%s',
          type: 'iras',
        },
        {
          copy: {
            term: '9M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '9-month term',
          },
          link: '/iras/irahycd/%s',
          type: 'iras',
        },
        {
          copy: {
            term: '6M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '6-month term',
          },
          link: '/iras/irahycd/%s',
          type: 'iras',
        },
        {
          copy: {
            term: '3M',
            tier1: 'tier1',
            tier2: 'tier2',
            tier3: 'tier3',
            subtext: '3-month term',
          },
          link: '/iras/irahycd/%s',
          type: 'iras',
        },
      ],
      disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s. Early withdrawal penalty will apply.',
    },
    {
      config: {
        product: 'iraRaiseYourRate',
        includeRating: false,
      },
      header: 'IRA Raise Your Rate CD',
      region: 'IRA Raise Your Rate CD',
      cta: {
        text: 'Explore',
        label: 'Explore IRA Raise Your Rate CD',
        url: '/iras/ira-raise-your-rate',
      },
      template: 'side-by-side',
      intro: '',
      tiles: [
        {
          copy: {
            term: '24M',
            tier1: 'tier1',
            subtext: '2-year term',
          },
          link: '/iras/ira-raise-your-rate/tier1',
          type: 'iras',
        },
        {
          copy: {
            term: '48M',
            tier1: 'tier1',
            subtext: '4-year term',
          },
          link: '/iras/ira-raise-your-rate/tier2',
          type: 'iras',
        },
      ],
      disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s. Early withdrawal penalty will apply.',
    },
    {
      config: {
        product: 'iraOnlineSavings',
        includeRating: false,
      },
      header: 'IRA Online Savings',
      region: 'IRA Online Savings',
      cta: {
        text: 'Explore',
        label: 'Explore IRA Online Savings',
        url: '/iras/ira-osa',
      },
      template: 'full-width',
      intro: '',
      tiles: [
        {
          copy: {
            term: '0M',
            tier1: 'tier1',
            subtext: 'On all balance tiers',
          },
          link: '/iras/ira-osa',
          type: 'iras',
        },
      ],
      disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s.',
    },
  ],
};

export default createStore('irasContent', irasContent);
