import React from 'react';
import LinkTo from 'components/utility-components/link-to';
import './button.scss';

const Button = ({
  to,
  button,
  skin,
  label,
  id,
}) => {
  const trackElem = skin === 'blue' || skin === 'outline' ? 'button' : 'link';
  let finalLink = to;

  if (to && typeof to === 'object') {
    const env = window.location.hostname;
    if (env === 'explore-products-rates.ally.com') {
      finalLink = to.prod;
    } else {
      finalLink = to.dev;
    }
  }

  return (
    <div className="button-container">
      <LinkTo
        attrs={{ 'data-track-elem': trackElem }}
        label={label}
        className={skin}
        to={finalLink}
        id={id}
      >
        {button}
      </LinkTo>
    </div>
  );
};

export default Button;
