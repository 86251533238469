import React, { Fragment } from 'react';
import Copy from '../../utility-components/copy';

const RegularList = ({
  liClassName, info,
}) => {
  if (!info) {
    return null;
  }

  return (
    <Fragment>
      {info.map(listItem => (
        // Although redundant - ul and li roles are needed to fix Safari A11y
        // bug where it doesn't read as a list when ul is set to display: inline
        <li role="listitem" key={`RegularList-${listItem}`} className={liClassName}>
          <Copy>{listItem}</Copy>
        </li>
      ))}
    </Fragment>
  );
};

export default RegularList;
