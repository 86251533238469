import React from 'react';
import getAttrs from './getAttrs';
import HiddenText from './HiddenText';

/**
 * Returns a button component for the a segmented control tab
 *
 * @param {array} tiers array that contains the all tiers tab text
 * @param {function} changeTier function for the click handler
 *  Updates the state with active panel, atracking data
 * @param {number} activePanel returns the index of the active tab
 * @param {object} hiddenText contains the tier and a11y text for that tier.
 * @returns {component} button
 */

const TierList = ({
  tiers,
  changeTier,
  activePanel,
  hiddenText,
}) => tiers.map((tier, index) => {
  const buttonAttrs = getAttrs({ index, activePanel, tier });
  const clickHandler = changeTier.bind(this, buttonAttrs);

  return (
    <button key={hiddenText} type="button" {...buttonAttrs} onClick={clickHandler}>
      <HiddenText text={hiddenText} index={index}>
        {tier}
      </HiddenText>
    </button>
  );
});

export default TierList;
