/**
 * Returns tier text based off user device, and selected tier
 *
 * @param {object} tierInfo object with all rate text options for all devices
 *  android:
 *    tier1: "Less than $5,000 opening deposit"
 *    tier2: "$5,000 minimum opening deposit"
 *    tier3: "$25,000 minimum opening deposit"
 * @param {string} device returns users device - 'ios' or 'android'
 * @param {string} tier returns selected tier - tier3
 * @returns {string} tier-text based on device, and selected tier - '$15,000 minimum daily balance'
 */

const innerCopy = (tierInfo, device, tier) => {
  if (tierInfo[device]) {
    return tierInfo[device][tier];
  }
  return tierInfo[tier];
};

export default innerCopy;
