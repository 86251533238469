import React from 'react';
import './css-loader.scss';

const CssLoader = ({
  className,
  ariaAnnounce = 'Rates Loading',
  loadCenter = '%',
}) => (
  <div className={`css-loader ${className || ''}`}>
    <div
      className="loader-aria"
      data-sr-only
      tabIndex="-1"
      ref={e => e && e.focus()}
    >
      {ariaAnnounce}
    </div>
    <div className="loader-outer">
      <p
        aria-hidden="true"
        className="loader-inner"
      >
        {loadCenter}
      </p>
      {/* these divs are empty so that there can be a
          pseudo-grow/shrink animation by using an animation delay */}
      <div aria-hidden="true" />
      <div aria-hidden="true" />
      <div aria-hidden="true" />
      <div aria-hidden="true" />
      <div aria-hidden="true" />
    </div>
  </div>
);

export default CssLoader;
