// params = {
//   requests: [ // required
//     {
//        path: 'path/to/script.js',
//        onload: function(event) {
//            // do stuff
//        },
//     },
//   ],
//   complete: function(event) { // optional
//     // do stuff
//   },
// };

class Loader {
  constructor(params) {
    this.params = params;
    this.state = {
      loadCount: 0,
      toLoad: params.requests.length,
    };
    this.onload = this.onload.bind(this);
  }

  request({ path, onload }) {
    const script = document.createElement('script');
    script.setAttribute('src', path);
    script.setAttribute('async', true);
    script.setAttribute('type', 'text/javascript');
    script.addEventListener('load', (event) => {
      onload(event);
      this.onload(event);
    }, false);
    document.body.append(script);
    return script;
  }

  requestAll() {
    const { requests } = this.params;
    requests.forEach((src) => {
      this.request(src);
    });
  }

  isComplete() {
    const { state: { loadCount, toLoad } } = this;

    if (loadCount >= toLoad) {
      return true;
    }
    return false;
  }

  onload(event) {
    const { state, params: { complete } } = this;
    state.loadCount += 1;

    if (complete && this.isComplete()) {
      complete(event);
    }
  }
}

export default Loader;
