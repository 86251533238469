import React from 'react';
import AdaText from '../../../components/ada-text';
import SubCopy from '../../../components/sub-copy';
import Dejargonator from '../../../components/utility-components/dejargonator';
import { resolveImagePath } from '../../../utilities';

const img = resolveImagePath('other/info-icon.svg');

const DejargColumn = React.memo(({ dejargKey }) => {
  if (!dejargKey) {
    return <span />;
  };

  return (
    <span>
      <Dejargonator dejargKey={dejargKey}>
        <img
          alt="Select here to learn more"
          className="icon"
          src={img}
        />
      </Dejargonator>
    </span>
  );
});

const TableRow = React.memo(({
  heading,
  content,
  dejargKey,
  subCopy = '',
  subCopy2 = '',
}) => {
  const name = 'GenerateColumnRows';
  const key = `row-${heading}`;
  const adaContent = `${heading} ${subCopy} ${content} ${subCopy2}`;
  const styles = {
    letterSpacing: '70px',
    whiteSpace: 'nowrap',
  };

  return (
    <li className={`${name}-item`} key={key}>
      <AdaText styles={styles}>
        {adaContent}
      </AdaText>
      <div className="display-row">
        <SubCopy column={heading} copy={subCopy} />
        <SubCopy column={content} copy={subCopy2} />
        <DejargColumn {...{ dejargKey }} />
      </div>
    </li>
  );
});

const GenerateColumnRows = React.memo(({ rows }) => rows
  .map(section => <TableRow key={`row-${section.heading}`} {...section} />));

export default GenerateColumnRows;
