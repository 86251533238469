import React from 'react';
import merge from 'lodash/merge';
import PageTitleHeading from 'components/page-title-heading';
import Disclaimer from 'components/disclaimer';
import { DATE } from 'utilities';
import {
  ProductRate, ImageWithCTA, ProductPageHeader, TextWithAccent, TextWithRate,
} from './templates';
import './hero.scss';

function applyTemplate({ config, content, params }) {
  switch (config.template) {
    case 'image-with-cta':
      return <ImageWithCTA {...config} {...content} />;
    case 'product-page-header':
      // the order of the ProductPageHeader params is meaningful
      // if it changes tab/deeplink functionality may break
      // merge these objects to stop undefined values from taking precedence
      return <ProductPageHeader {...merge(params, config, content)} />;
    case 'text-with-accent':
      return <TextWithAccent {...config} {...content} />;
    case 'text-with-rate':
      return <TextWithRate {...merge(params, config, content)} />;
    default: // default template
      return <ProductRate {...config} {...content} />;
  }
}

// TODO: determine why effective date observer isn't working
const Hero = (props) => {
  const {
    config: { template, bottomColor },
    content: { failTextLabel, focusType },
    pageTitle,
    effectiveDate,
    disclaimer,
  } = props;

  return (
    <header className={`hero ${template || ''}`}>
      <PageTitleHeading title={pageTitle} />
      <div className={`inner-hero ${bottomColor || ''}`}>
        {
          applyTemplate(props)
        }
      </div>
      {template === 'product-page-header' || template === 'text-with-rate'
        ? (
          <Disclaimer
            className="effective-date"
            flag="rate"
            effectiveDate={effectiveDate || DATE}
            disclaimer={disclaimer}
            failTextLabel={failTextLabel}
            focusType={focusType}
          />
        )
        : null
    }
    </header>
  );
};

export default Hero;
