import React from 'react';
import ContentLoader from '../../../../utility-components/content-loader';

/**
 * Upper area of the hero template which displays an icon, product name, and star rating
 *
 * @param {filename} img points to the location of an icon `/assets/bank-savings-hero.svg`
 * @param {string} productName title of the page `Online Savings Account`
 * @param {*} children info to be displayed below productName - currently star raitings info
 */

const ProductRateHeading = ({ img, productName, children }) => (
  <div className="row">
    <div className="columns col-sm-3 text-center product-image-container">
      <img aria-hidden="true" className="product-image" src={img} alt="" />
    </div>
    <div className="columns col-sm-9 product-head">
      <h2 className="product-name">{productName}</h2>
      <ContentLoader className="product-rating hidden-on-fail" flag="rating">
        {children}
      </ContentLoader>
    </div>
  </div>
);

export default ProductRateHeading;
