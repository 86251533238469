import React from 'react';
import Copy from 'components/utility-components/copy';
import './plain-text.scss';
import HeadingLevel from '../utility-components/heading-level';

// convenience for keeping logic out of template
const NullComponent = () => null;

const PlainCopy = ({ className, copy }) => (
  <p {...{ className }}>
    <Copy>{copy}</Copy>
  </p>
);

const TextMap = ({ content, prefix = '' }) => content.map((text, index) => {
  const key = `${prefix}${index}`;
  return <PlainText {...text} key={key} />;
});

const PlainText = ({
  ptClassName, title, titleLevel = 'h3', copy, className, classNameTitle,
}) => {
  const containerClass = `plain-text ${ptClassName || ''}`;
  const titleClass = `title ${classNameTitle || ''}`;
  const copyClass = `copy ${className || ''}`;
  const Title = title ? HeadingLevel : NullComponent;
  const Content = copy ? PlainCopy : NullComponent;

  return (
    <div className={containerClass}>
      <Title {...{ titleClass, titleLevel, title }} />
      <Content {...{ className: copyClass, copy }} />
    </div>
  );
};

export default PlainText;
export { TextMap };
