const MOCK_RATES = {
  prodRates: {
    message: 'Success',
    status: '0',
    products: [
      {
        terms: [
          {
            term: 0,
            termEndDate: null,
            termStartDate: null,
            termId: 'OSAV-0M',
            termCode: '0M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.2,
                apr: 2.18,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.2,
                apr: 2.18,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.2,
                apr: 2.18,
              },
            ],
          },
        ],
        productCode: 'OSAV',
        effectiveDate: '06/20/2019',
        bankName: 'Ally Bank',
      },
      {
        terms: [
          {
            term: 0,
            termEndDate: null,
            termStartDate: null,
            termId: 'MMDA-0M',
            termCode: '0M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 0.9,
                apr: 0.9,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 0.9,
                apr: 0.9,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 1.0,
                apr: 1.0,
              },
            ],
          },
        ],
        productCode: 'MMDA',
        effectiveDate: '06/20/2019',
        bankName: 'Ally Bank',
      },
      {
        terms: [
          {
            term: 3,
            termEndDate: null,
            termStartDate: null,
            termId: 'CD-3M',
            termCode: '3M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 0.75,
                apr: 0.75,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 0.75,
                apr: 0.75,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 0.75,
                apr: 0.75,
              },
            ],
          },
          {
            term: 6,
            termEndDate: null,
            termStartDate: null,
            termId: 'CD-6M',
            termCode: '6M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 1.0,
                apr: 1.0,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 1.0,
                apr: 1.0,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 1.0,
                apr: 1.0,
              },
            ],
          },
          {
            term: 9,
            termEndDate: null,
            termStartDate: null,
            termId: 'CD-9M',
            termCode: '9M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 1.25,
                apr: 1.24,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 1.25,
                apr: 1.24,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 1.25,
                apr: 1.24,
              },
            ],
          },
          {
            term: 12,
            termEndDate: null,
            termStartDate: null,
            termId: 'CD-12M',
            termCode: '12M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.55,
                apr: 2.52,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.55,
                apr: 2.52,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.55,
                apr: 2.52,
              },
            ],
          },
          {
            term: 18,
            termEndDate: null,
            termStartDate: null,
            termId: 'CD-18M',
            termCode: '18M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.35,
                apr: 2.32,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.55,
                apr: 2.52,
              },
            ],
          },
          {
            term: 36,
            termEndDate: null,
            termStartDate: null,
            termId: 'CD-36M',
            termCode: '36M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.45,
                apr: 2.42,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.55,
                apr: 2.52,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.6,
                apr: 2.57,
              },
            ],
          },
          {
            term: 60,
            termEndDate: null,
            termStartDate: null,
            termId: 'CD-60M',
            termCode: '60M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.85,
                apr: 2.81,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.85,
                apr: 2.81,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.85,
                apr: 2.81,
              },
            ],
          },
          {
            term: 24,
            termEndDate: null,
            termStartDate: null,
            termId: 'CD-24M',
            termCode: '24M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.5,
                apr: 2.47,
              },
            ],
          },
          {
            term: 48,
            termEndDate: null,
            termStartDate: null,
            termId: 'CD-48M',
            termCode: '48M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.5,
                apr: 2.47,
              },
            ],
          },
        ],
        productCode: 'CD',
        effectiveDate: '06/20/2019',
        bankName: 'Ally Bank',
      },
      {
        terms: [
          {
            term: 11,
            termEndDate: null,
            termStartDate: null,
            termId: 'NCD-11M',
            termCode: '11M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 1.8,
                apr: 1.78,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.15,
                apr: 2.13,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.3,
                apr: 2.27,
              },
            ],
          },
        ],
        productCode: 'NCD',
        effectiveDate: '06/20/2019',
        bankName: 'Ally Bank',
      },
      {
        terms: [
          {
            term: 0,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRAOSA-0M',
            termCode: '0M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.2,
                apr: 2.18,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.2,
                apr: 2.18,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.2,
                apr: 2.18,
              },
            ],
          },
        ],
        productCode: 'IRAOSA',
        effectiveDate: '06/20/2019',
        bankName: 'Ally Bank',
      },
      {
        terms: [
          {
            term: 3,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRAHY-3M',
            termCode: '3M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 0.75,
                apr: 0.75,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 0.75,
                apr: 0.75,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 0.75,
                apr: 0.75,
              },
            ],
          },
          {
            term: 6,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRAHY-6M',
            termCode: '6M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 1.0,
                apr: 1.0,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 1.0,
                apr: 1.0,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 1.0,
                apr: 1.0,
              },
            ],
          },
          {
            term: 9,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRAHY-9M',
            termCode: '9M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 1.25,
                apr: 1.24,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 1.25,
                apr: 1.24,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 1.25,
                apr: 1.24,
              },
            ],
          },
          {
            term: 12,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRAHY-12M',
            termCode: '12M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.55,
                apr: 2.52,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.55,
                apr: 2.52,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.55,
                apr: 2.52,
              },
            ],
          },
          {
            term: 18,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRAHY-18M',
            termCode: '18M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.35,
                apr: 2.32,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.55,
                apr: 2.52,
              },
            ],
          },
          {
            term: 36,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRAHY-36M',
            termCode: '36M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.45,
                apr: 2.42,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.55,
                apr: 2.52,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.6,
                apr: 2.57,
              },
            ],
          },
          {
            term: 60,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRAHY-60M',
            termCode: '60M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.85,
                apr: 2.81,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.85,
                apr: 2.81,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.85,
                apr: 2.81,
              },
            ],
          },
        ],
        productCode: 'IRAHY',
        effectiveDate: '06/20/2019',
        bankName: 'Ally Bank',
      },
      {
        terms: [
          {
            term: 24,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRARYR-24M',
            termCode: '24M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.5,
                apr: 2.47,
              },
            ],
          },
          {
            term: 48,
            termEndDate: null,
            termStartDate: null,
            termId: 'IRARYR-48M',
            termCode: '48M',
            rates: [
              {
                max: 4999.99,
                min: 0.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 24999.99,
                min: 5000.0,
                apy: 2.5,
                apr: 2.47,
              },
              {
                max: 9.9999999999e8,
                min: 25000.0,
                apy: 2.5,
                apr: 2.47,
              },
            ],
          },
        ],
        productCode: 'IRARYR',
        effectiveDate: '06/20/2019',
        bankName: 'Ally Bank',
      },
      {
        terms: [
          {
            term: 0,
            termEndDate: null,
            termStartDate: null,
            termId: 'DDA-0M',
            termCode: '0M',
            rates: [
              {
                max: 14999.99,
                min: 0.0,
                apy: 0.1,
                apr: 0.1,
              },
              {
                max: 9.9999999999e8,
                min: 15000.0,
                apy: 0.6,
                apr: 0.6,
              },
            ],
          },
        ],
        productCode: 'DDA',
        effectiveDate: '06/20/2019',
        bankName: 'Ally Bank',
      },
    ],
  },
};

export default MOCK_RATES;
