import * as React from 'react';

// TODO: implement pattern for unique id generation
let count = 0;
const getNum = () => {
  count += 1;
  return count;
};
const calculatePercentage = rating => ((rating / 5) * 100).toFixed(1);

export default function StarRating({ product = 'default', rating, reviewCount }) {
  const idNum = getNum();
  return (
    <svg className="star-rating" xmlns="http://www.w3.org/2000/svg" height="25" width="100" viewBox="0 0 179.7 37" role="img" aria-hidden="true">
      <title id={`Item-reviews-rating-stars-title-${product}-${idNum}`}>
        {`Rated ${rating} out of 5 stars, based on ${reviewCount} reviews.`}
      </title>

      <defs>
        <g id={`rating-star-path-reviews-rating-stars-${product}-${idNum}`}>
          <polygon className="star-shape rating-star-1 active" points="17.9,28 7.1,35.1 10.5,22.7 0.3,14.6 13.3,14 17.9,1.9 22.4,14 35.3,14.6 25.1,22.7 28.7,35.1" />
          <polygon className="star-shape rating-star-2 active" points="53.9,28 43.1,35.1 46.5,22.7 36.3,14.6 49.3,14 53.9,1.9 58.4,14 71.3,14.6 61.1,22.7 64.7,35.1" />
          <polygon className="star-shape rating-star-3 active" points="89.9,28 79.1,35.1 82.5,22.7 72.3,14.6 85.3,14 89.9,1.9 94.4,14 107.3,14.6 97.1,22.7 100.7,35.1" />
          <polygon className="star-shape rating-star-4 active" points="125.9,28 115.1,35.1 118.5,22.7 108.3,14.6 121.3,14 125.9,1.9 130.4,14 143.3,14.6 133.1,22.7 136.7,35.1" />
          <polygon className="star-shape rating-star-5 " points="161.9,28 151.1,35.1 154.5,22.7 144.3,14.6 157.3,14 161.9,1.9 166.4,14 179.3,14.6 169.1,22.7 172.7,35.1" />
        </g>
        <mask id={`star-rating-mask-reviews-rating-stars-${product}-${idNum}`}>
          <rect x="0" y="0" width={`${calculatePercentage(rating)}%`} height="100%" id={`m-reviews-rating-stars-${idNum}`} className="star-rating-progress-bar" fill="white" />
        </mask>
      </defs>
      <g>
        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#rating-star-path-reviews-rating-stars-${product}-${idNum}`} fill="#DDDDDD" />
        <use mask={`url(#star-rating-mask-reviews-rating-stars-${product}-${idNum})`} xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#rating-star-path-reviews-rating-stars-${product}-${idNum}`} fill="#FFC107" />
      </g>
    </svg>
  );
}
