/* eslint-disable react/no-multi-comp */
import React, { memo } from 'react';
import Hero from 'components/hero';
import Main from 'components/main';
import MenuItem from 'components/menu-item';
import JsonOffer from 'components/json-offer';
import { observer } from 'mobx-react';
import './explore.scss';

const MenuItems = memo(({ menuItems }) => menuItems
  .map((category, index) => <MenuItem key={index} index={index} {...category} />));

const Explore = memo(({ hero, menuItems }) => (
  <div className="Explore">
    <JsonOffer offerName="ally-epnr-our-services-hero" pageTitle={hero.pageTitle}>
      {/* fallback content */}
      <Hero {...hero} />
    </JsonOffer>
    <Main>
      <MenuItems {...{ menuItems }} />
    </Main>
  </div>
));

export default observer(Explore);
