import { orchestrator } from 'satcheljs';
import { WINDOW_JSON_OVERRIDE, OVERRIDE_WITH_JSON } from 'actions/utility';

// we're using an orchestrator here because the Object
// on the window is technically an external source
// and our mutators shouldn't rely on or consider an
// external source
export default orchestrator(WINDOW_JSON_OVERRIDE, () => {
  // check for the presence of the override Object
  const authSalesOverrideObject = window.authSalesOverrideObject || {};

  // trigger the mutator and pass the relevant data through
  OVERRIDE_WITH_JSON(authSalesOverrideObject);
});
