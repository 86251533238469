import { Loader } from '@ally/auth-sales-shared/utilities';
import { updateDDO, dispatchDDO } from 'components/utility-components/track';
import startsWith from './starts-with';

// hrefs for launch js
const root = '//assets.adobedtm.com/';
const fileName = {
  development: 'cd392cd56789/6e78a0a7390a/launch-f9375026550a-development.min.js',
  staging: 'cd392cd56789/6e78a0a7390a/launch-349df1d035c8-staging.min.js',
  production: 'cd392cd56789/6e78a0a7390a/launch-7205b4f8d243.min.js',
};

const {
  location: { host },
} = window;
const isEnv = (env, origin = '') => startsWith(origin, env);
const devEnvs = ['localhost:', 'dev.', 'mod.'];

// since the same build is used for mod and prod,
// we rely on the url host to determine the tagging
// environment. It will default to production if the
// url is not listed
const getEnvParam = devEnvs.filter(pre => isEnv(pre, host)).shift() ? 'development' : 'production';

const path = `${root}${fileName[getEnvParam]}`;

const adobeLaunchLoader = () =>
  new Promise(resolve => {
    const onload = () => {
      if (!window.digitalData.pageName) {
        updateDDO({ pageName: 'Products and Rates', lineOfBusiness: 'Corporate' });
      }
      dispatchDDO();
      resolve();
    };

    // Global variable used by launch script to only load certain third party
    // libraries when in mobile EP&R
    window.isEPRWebView = true;

    const loader = new Loader({
      requests: [{ path, onload }],
    });

    loader.requestAll();
  });

export default adobeLaunchLoader;
