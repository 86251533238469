import React from 'react';
import SegmentedControl from 'components/segmented-control';

const getSegmentedAttrs = ({
  product,
  selectedTier: tier,
  tiers,
  tierInfo,
  hiddenText,
  handleClick,
}) => ({
  product,
  selectedTier: tier,
  tiers,
  tierInfo,
  hiddenText,
  handleClick,
});

const TierTabs = (props) => {
  const { tiers } = props;
  if (!tiers) {
    return null;
  }
  return <SegmentedControl {...getSegmentedAttrs(props)} />;
};

export default TierTabs;
