import React from 'react';
import PlainText from 'components/plain-text';
import Copy from 'components/utility-components/copy';
import MenuItem from 'components/menu-item';
import './details-list.scss';

const DetailsList = ({
  heading,
  subheading,
  preTitle,
  title,
  titleLevel,
  postTitle,
  className = '',
  sectionClass = '',
  titleClass = '',
  children,
}) => {
  const name = 'DetailsList';
  const containerClass = `${name}-section ${sectionClass}`;
  const headingClass = `title ${titleClass}`;
  const PreTitle = preTitle ? PlainText : () => null;
  const PostTitle = postTitle ? PlainText : () => null;
  return (
    <div className={containerClass}>
      {heading && <h3 className={headingClass}><Copy>{heading}</Copy></h3>}
      {subheading && <p className="subtitle"><Copy>{subheading}</Copy></p>}
      {preTitle && <PreTitle copy={preTitle} />}
      {title && <MenuItem title={title} titleLevel={titleLevel} />}
      {postTitle && <PostTitle copy={postTitle} />}

      <ul className={className}>
        {children}
      </ul>
    </div>
  );
};

export default DetailsList;
