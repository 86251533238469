import React from 'react';
import RateContent from './RateContent';
import getRate from '../utils/getRate';
import rateFormat from '../utils/rateFormat';
import ADAText from './ADAText';

/**
 * Returns a component with the returned rate formatted with '% APY' appended
 * that is formatted for screen readers to read in one swipe
 *
 * @param {object} terms Object containing all terms and tiers available
 * @param {string} term term to be returned `0M`
 * @param {string} tier tier to be returned `tier1`
 */

const Rate = ({ terms, term, tier }) => {
  const rate = getRate({ terms, term, tier });
  const label = rateFormat(rate);

  return (
    <p className="rate-info">
      <ADAText label={label}>
        <RateContent rate={rate} />
      </ADAText>
    </p>
  );
};

export default Rate;
