import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { lazyWithPreload } from 'utilities';
import { updateDDO } from 'components/utility-components/track';

const SavingsProduct = lazyWithPreload(() => import('pages/explore/products/savings/osa'))();
const MoneyMarketProduct = lazyWithPreload(() => import('pages/explore/products/savings/money-market'))();
const pageTitle = 'Savings';
const lineOfBusiness = 'Bank';

const OSA = (props) => {
  updateDDO({ pageTitle, pageName: 'Online Savings', lineOfBusiness });
  return <SavingsProduct {...props} />;
};

const MMS = (props) => {
  updateDDO({ pageTitle, pageName: 'Money Market Savings', lineOfBusiness });
  return <MoneyMarketProduct {...props} />;
};

const SavingsRouter = (params) => {
  const { match: { url } } = params;
  return (
    <Switch>
      <Route exact path={`${url}/osa/:tier?/:term?`} render={OSA} />
      <Route exact path={`${url}/money-market/:tier?/:term?`} render={MMS} />
    </Switch>
  );
};

export default SavingsRouter;
