import { mutator } from 'satcheljs';
import { OVERRIDE_WITH_JSON } from 'actions/utility';
import merge from 'lodash/merge';

import hycdContent from 'stores/pages/cds/products/hycd';
import noPenaltyContent from 'stores/pages/cds/products/no-penalty';
import ryrcdContent from 'stores/pages/cds/products/ryrcd';
import checkingContent from 'stores/pages/checking-product';
import exploreContent from 'stores/pages/explore';
import homePurchaseContent from 'stores/pages/home-purchase';
import homeRefinanceContent from 'stores/pages/home-refinance';
import investContent from 'stores/pages/invest';
import iraRaiseYourRate from 'stores/pages/iras/products/ira-raise-your-rate';
import irahycdContent from 'stores/pages/iras/products/irahycd';
import iraosa from 'stores/pages/iras/products/iraosa';
import roboPortfoliosContent from 'stores/pages/robo-portfolios';
import moneyMarketContent from 'stores/pages/savings/products/money-market';
import savingsProduct from 'stores/pages/savings/products/osa';
import selfDirectedTradingContent from 'stores/pages/self-directed-trading';

// our JSON object will specify one of the keys below
// which we'll use to map to a content store
const storeMap = {
  checking: checkingContent,
  explore: exploreContent,
  highYieldCd: hycdContent,
  highYieldIra: irahycdContent,
  invest: investContent,
  ira: iraosa,
  roboPortfolios: roboPortfoliosContent,
  moneyMarket: moneyMarketContent,
  mortgagePurchase: homePurchaseContent,
  mortgageRefinance: homeRefinanceContent,
  noPenaltyCd: noPenaltyContent,
  raiseYourRateCd: ryrcdContent,
  raiseYourRateIra: iraRaiseYourRate,
  savings: savingsProduct,
  selfDirectedTrading: selfDirectedTradingContent,
};

// we're going to destructure the SatchelJS Action type and ID from our passed data first
export default mutator(
  OVERRIDE_WITH_JSON,
  ({ type: satchelJSType, __SATCHELJS_ACTION_ID, ...overrideObject }) => {
    // iterate through our provided keys and see if we have content to override
    Object.keys(overrideObject).forEach(type => {
      // make sure this is a valid key for one of our stores
      if (storeMap[type]) {
        const store = storeMap[type]();

        // iterate through our provided keys and perform a deep merge
        // we check each provided parent to ensure that the update
        // properly triggers observers
        Object.keys(overrideObject[type]).forEach(key => {
          const currentValue = store[key];

          // we're using lodash.merge() because it does a deep merge
          store[key] = merge(currentValue, overrideObject[type][key]);
        });
      }
    });
  },
);
