import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { afterDraw } from '../../../utilities';

class Track extends Component {
  constructor() {
    super();
    this.state = {
      text: '',
    };
    this.listenConfig = {
      subtree: true,
      childList: true,
      characterData: true,
    };

    this.passRef = this.passRef.bind(this);
    this.updateTracking = this.updateTracking.bind(this);
    this.observer = new MutationObserver(this.updateTracking);
  }

  componentDidMount() {
    afterDraw(() => {
      if (this.el) {
        this.listenChange();
        this.updateTracking();
      }
    });
  }

  componentWillUnmount() {
    this.unlistenChange();
  }

  getText(text) {
    const { schema: { valFrom } } = this.props;
    const output = text || valFrom(this.el);
    return output.replace(/\s/g, ' ');
  }

  didLoad(txt = '') {
    // TODO: refactor to use store/observer after
    // the android release
    const { text } = this.state;
    const output = (txt || text).replace(/\s/g, ' ');

    return output && !output.includes('% 0.00%');
  }

  passRef(e) {
    // the Link component from react-router-dom
    // does not support forwardRef
    // NOTE: if this is false it should be a static track
    this.el = findDOMNode(e);
  }

  updateTracking() {
    const text = this.getText();

    if (this.didLoad(text)) {
      this.setState({ text });
    }
  }

  unlistenChange() {
    this.observer.disconnect();
    this.disconnect = () => null;
  }

  listenChange() {
    const { parentElement: el } = this.el;
    this.observer.observe(el, this.listenConfig);
  }

  render() {
    const { schema: { get }, RenderComponent } = this.props;
    const { text } = this.state;

    return <RenderComponent passRef={this.passRef} {...get({ text })} />;
  }
}

export default Track;
