import React from 'react';
import Copy from '../copy';

const HeadingLevel = ({
  titleLevel,
  title,
  titleClass = 'title',
  ariaHidden = 'false',
  ariaLabel = null,
}) => ({
  h1: <h1 aria-hidden={ariaHidden} aria-label={ariaLabel} className={titleClass}><Copy>{title}</Copy></h1>,
  h2: <h2 aria-hidden={ariaHidden} aria-label={ariaLabel} className={titleClass}><Copy>{title}</Copy></h2>,
  h3: <h3 aria-hidden={ariaHidden} aria-label={ariaLabel} className={titleClass}><Copy>{title}</Copy></h3>,
  h4: <h4 aria-hidden={ariaHidden} aria-label={ariaLabel} className={titleClass}><Copy>{title}</Copy></h4>,
}[titleLevel]);

export default HeadingLevel;
