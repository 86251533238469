import { createStore } from 'satcheljs';
import { device } from 'utilities';

const isDroid = device === 'android';

const iraosa = {
  hero: {
    pageTitle: 'IRA Online Savings',
    config: {
      bottomColor: 'iras',
      template: 'product-page-header',
      product: 'iraOnlineSavings',
      includeRating: false,
    },
    content: {
      rateText: `%s${isDroid ? '++%++' : '%'} APY`,
      extraInfoBelow: 'Ally Bank Spending Account balance tiers are less than $5,000 and $25,000 or more',
      tierInfo: {
        tier1: 'On all balance tiers',
      },
      cta: {
        text: 'Open Account',
        url: 'ally://account_opening/IRAOSA-%s',
      },
    },
    disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s.',
  },
  intro: {
    title: 'Smart savings for your retirement.',
    titleLevel: 'h2',
    ptClassName: 'padding-bottom-small',
    className: 'not-nested',
    copy: 'Our rates are consistently among the most competitive in the country.',
  },
  bullets: [
    'No minimum deposit required to open your account',
    'Grow your money faster with ##interest compounded daily::compoundedInterest::##',
    'Six transactions limit per statement cycle. ##Why only 6?::whySix::##',
    'Your deposits are insured by the ##FDIC::fdic::## up to the maximum allowed by law. @@Maximize your coverage::https://www.ally.com/bank/fdic/::@@',
    'Available for Roth, SEP and Traditional IRAs. @@Find the IRA for you::https://www.ally.com/bank/ira/ira-account/::@@',
    'Flexible funding options, including rollover to Roth and Traditional IRAs',
  ],
  tiers: {
    title: 'Ally Bank Savings Account balance tiers',
    titleLevel: 'h2',
    ptClassName: 'padding-bottom-small',
    className: 'not-nested',
  },
  tiersInfo: [
    'Less than $5,000',
    'Between $5,000 and $24,999.99',
    '$25,000 or more'
  ],
  infoCardArea: {
    title: 'our favorite features',
    cards: [
      {
        icon: 'ira/peaceofmind.svg',
        title: 'Peace of mind',
        copy: 'Our award-winning customer care is available 24/7.',
      },
      {
        icon: 'ira/movemoney.svg',
        title: 'Move money',
        copy: 'Transfer funds between your Ally Bank accounts or accounts at other institutions.',
      },
    ],
  },
  tableSections: [
    {
      title: 'no hidden fees',
      sectionClass: 'no-hidden-fees',
      postTitle: 'There’s a lot we don’t charge for',
      rows: [
        {
          heading: 'Monthly maintenance fees',
          content: '$0',
        },
        {
          heading: 'Overdraft item fees',
          content: '$0',
          dejargKey: 'overdraftItem',
        },
        {
          heading: 'Statement copies online',
          content: '$0',
        },
        {
          heading: 'Incoming wires',
          subCopy: 'Domestic and International',
          content: '$0',
        },
        {
          heading: 'Postage-paid deposit envelopes',
          content: '$0',
        },
        {
          heading: "Official / cashier's checks",
          content: '$0',
          dejargKey: 'checks',
        },
        {
          heading: 'Unlimited Deposits',
          content: '$0',
        },
      ],
    },
    {
      postTitle: '…and won’t hide the fees we do have.',
      className: 'for-a-fee-table',
      rows: [
        {
          heading: 'Excessive transactions fee',
          content: '$10.00',
          subCopy2: 'per transaction',
          dejargKey: 'excessiveTransactions',
        },
        {
          heading: 'Expedited delivery',
          content: '$15.00',
        },
        {
          heading: 'Outgoing wires',
          subCopy: 'Domestic only',
          content: '$20.00',
          dejargKey: 'domesticWire',
        },
        {
          heading: 'Account research fee',
          content: '$25.00',
          subCopy2: 'per hour',
          dejargKey: 'accountResearch',
        },
      ],
    },
  ],
};

export default createStore('iraosa', iraosa);
