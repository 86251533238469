/**
 *  Function that returns each apy and apr for every rate and termlength and passes to
 *  build term function.
 *
 * @param {Array} ratesArray '[
 * { 0: { max: 4999.99, min: 0, apy: 2.2, apr: 2.18 }},
 * { 1: { max: 24999.99, min: 5000, apy: 2.2, apr: 2.18 }},
 * { 2: { max: 999999999.99, min: 25000, apy: 2.2, apr: 2.18}}
 * ]'
 *  Array of rate objects
 *
 * @param {String} termLength '12' Determines which term length
 *
 * @returns {Object}
 * {
 * term: 12
 * tier1: { apy: 99.99, apr:99.99 }
 * tier2: { apy: 99.99, apr:99.99 }
 * tier3: { apy: 99.99, apr:99.99 }
 * }
 * Example object for termLength 12
 *
 */

const buildRates = (ratesArray, termLength) => {
  const obj = {
    term: termLength,
  };
  ratesArray.forEach((rate, index) => {
    obj[`tier${index + 1}`] = {
      apy: rate.apy,
      apr: rate.apr,
    };
  });

  return obj;
};

export default buildRates;
