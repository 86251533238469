import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePurchase from 'pages/explore/products/home/home-purchase';
import HomeRefinance from 'pages/explore/products/home/home-refinance';
import { updateDDO } from 'components/utility-components/track';

const lineOfBusiness = 'Home';

const PURCHASE = (props) => {
  updateDDO({ pageName: 'Home Purchase', lineOfBusiness });
  return <HomePurchase {...props} />;
};

const REFI = (props) => {
  updateDDO({ pageName: 'Home Refinance', lineOfBusiness });
  return <HomeRefinance {...props} />;
};

const HomeRouter = ({ match: { url } }) => (
  <Switch>
    <Route path={`${url}/purchase`} render={PURCHASE} />
    <Route path={`${url}/refinance`} render={REFI} />
  </Switch>
);

export default HomeRouter;
