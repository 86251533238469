import React from 'react';
import Copy from '../../../utility-components/copy';
import PlainText from '../../../plain-text';
import './three-row-text.scss';

const RenderCards = ({ copy }) => copy.map(({
  title, top, rate, bottom, accentRate,
}, index) => (
  <li key={`card-${index}`} className="content-wrapper">
    {title && <PlainText className="title" title={title} />}
    <header className={`${title ? '' : 'heading'}`}><Copy>{top}</Copy></header>
    {accentRate ? <p className="accentRate"><Copy>{rate}</Copy></p> : <p className="rate">{rate}</p> }
    <footer className="bottom"><Copy>{bottom}</Copy></footer>
  </li>
));

const ThreeRowText = ({ copy }) => (
  <ul>
    <RenderCards {...{ copy }} />
  </ul>
);

export default ThreeRowText;
