import * as React from 'react';
import Hero from 'components/hero';
import Main from 'components/main';
import ImgWithContent from 'components/img-with-content';
import PlainText from 'components/plain-text';
import Divider from 'components/divider';
import homeRefinanceContent from 'stores/pages/home-refinance';
import ValuePropWithCta from 'components/valueprop-with-cta';
import ValuePropWithList from 'components/valueprop-with-list';
import './home-refinance.scss';

const HomeRefinance = () => {
  const {
    seeTheDifference,
    refiForTheRightReason,
    lowerPayments,
    fasterPayoff,
    takeCashOut,
    refiCalculator,
    hero,
  } = homeRefinanceContent();

  return (
    <div className="Home-Refinance">

      <Hero {...hero} />
      <Main>
        <ImgWithContent {...seeTheDifference} />
        <Divider />
        <PlainText {...refiForTheRightReason} />
        <ValuePropWithList {...lowerPayments} />
        <Divider />
        <ValuePropWithList {...fasterPayoff} />
        <Divider />
        <ValuePropWithList {...takeCashOut} />
        <Divider />
        <ValuePropWithCta {...refiCalculator} />
      </Main>
    </div>
  );
};

export default HomeRefinance;
