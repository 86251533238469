import { orchestrator } from 'satcheljs';
import { GET_RATES_FIRST_TIME, STORE_RATES, LOAD_FAILURE } from 'actions/products';
import { testRates, globalParams } from 'utilities';
import { processRates } from '@ally/auth-sales-shared/utilities';
import { APIGEE_KEY, APIGEE_KEY_STAGING, RATES_URL, RATES_URL_STAGING, MOCK_RATES } from '../utility/environment-variables';

const axios = require('axios');

const {
  device,
  cif,
} = globalParams;

const { hostname } = window.location;
const isStaging = hostname.includes('mod') || hostname.includes('dev');
const ratesEndpoint = isStaging ? RATES_URL_STAGING : RATES_URL;
const apiKey = isStaging ? APIGEE_KEY_STAGING : APIGEE_KEY;

export default
orchestrator(GET_RATES_FIRST_TIME, () => {
  axios.get(`${ratesEndpoint}&device=${device}${cif ? `&cif=${cif}` : ''}`, {
    // according to UX, timeout is 20s for rates
    timeout: 20000,
    headers: {
      'api-key': apiKey,
    },
  })
    .then((response, err) => {
      if (err) {
        throw err;
      }

      return response.data;
    }).then((jsonResponse) => {
      if (testRates !== 'loading') {
        if (testRates === 'failed') {
          LOAD_FAILURE({ api: 'rate' });
        } else {
          STORE_RATES(processRates(jsonResponse));
        }
      }
    }).catch((err) => {
      if (testRates !== 'failed' && hostname === 'localhost') {
        STORE_RATES(processRates(MOCK_RATES));
      } else {
        console.log('Get rates failed', err);
        LOAD_FAILURE({ api: 'rate' });
      }
    });
});
