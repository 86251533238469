/* eslint-disable react/prop-types */
import React from 'react';
import StarRating from '../StarRating';

const styles = require('./ProductRatings.scss');

const ProductRatings = ({
  className,
  showTextInfo,
  reviewCount,
  overallRating,
  productName,
}) => (
  (overallRating && reviewCount)
    ? (
      <figure className={`${styles.productRating} ${className}`}>
        <StarRating rating={overallRating} reviewCount={reviewCount} product={productName} />
        <span className={styles.srText}>{`Rated ${overallRating} out of 5 stars.`}</span>
        {showTextInfo && (
          <figcaption className={styles.textInfo}>{`${overallRating} (${reviewCount} Reviews)`}</figcaption>
        )}
      </figure>
    )
    : null
);

export default ProductRatings;
