import React from 'react';
import resolveImagePath from 'utilities/resolve-image-path';
import Button from 'components/button';
import Copy from '../utility-components/copy';
import SimpleList from '../simple-list';
import './valueprop-with-cta.scss';

/**
 * Creates an image with a list from Props passed in through data store
 *
 * @param {String} title centered and bolded title for component `Check out this cool product`
 * @param {path} imgSrc path to image to go along side of the list `other/STK-EPR-Graph-OSA.png`
 * @param {String} imgClassName className to apply to image `img-left`
 * @param {String} copy paragraph on the right of the image (rather than bullets) `Paragraph text`
 *
 * @returns JSX element with image and copy styling
 */
const outerClass = 'listContainer';

const ValuePropWithCta = ({
  title, copy, linkText, imgSrc, list, subTitle, button,
}) => (
  <div className="value-prop-with-cta-container">
    <div className="content">
      {imgSrc && (
        <div className="img-container-stk">
          <img alt=" " aria-hidden="true" src={resolveImagePath(imgSrc)} />
        </div>
      )}
      {title && <h3 className="title"><Copy>{title}</Copy></h3>}
      {subTitle && <h4 className="sub-title"><Copy>{subTitle}</Copy></h4>}
      {copy && <p className="copy"><Copy>{copy}</Copy></p>}
      {linkText && <p className="linkText"><Copy>{linkText}</Copy></p>}
      <div className="list-cta-wrapper">
        {list && (
          <SimpleList
            ulClassName={outerClass}
            liClassName="listItem"
            info={list}
          />
        )}
        {button && <Button {...button} />}
      </div>
    </div>
  </div>
);

export default ValuePropWithCta;
