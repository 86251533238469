/* eslint-disable react/prop-types */
import React from 'react';
import HeadingLevel from 'components/utility-components/heading-level';
import './text-with-accent.scss';
import Button from 'components/button';

const TextWithAccent = ({
  bgColor, cta, topCopy, topCopyClass, bottomCopy, accent, description, label, extraClass,
}) => {
  const {
    skin, text, url, id,
  } = cta;

  const className = bottomCopy ? 'bottom-text-container' : 'text-container';

  const ariaHidden = label ? 'true' : 'false';
  const accentAriaHidden = topCopy ? 'true' : 'false';
  const accentLabel = topCopy ? null : label;

  return (
    <div className={`text-with-accent-template ${bgColor}`}>
      <div className={className}>
        {topCopy && <HeadingLevel titleClass={topCopyClass} ariaLabel={label} title={topCopy} titleLevel="h2" />}
        {accent && <HeadingLevel ariaHidden={accentAriaHidden} ariaLabel={accentLabel} title={accent} titleLevel="h2" titleClass={`title-accent ${extraClass}`} />}
        {bottomCopy && <HeadingLevel ariaHidden={ariaHidden} title={bottomCopy} titleLevel="h2" />}
        {description && <p className="description">{description}</p>}
      </div>
      <Button
        skin={skin}
        to={url}
        button={text}
        id={id || ''}
      />
    </div>
  );
};

export default TextWithAccent;
