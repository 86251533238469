import React, { Component } from 'react';
import RatingsAndReviews from 'components/ratings-and-reviews';
import { productStore } from 'stores';
import { observer } from 'mobx-react';
import Failed from './helpers/Failed';
import ProductRateHeading from './helpers/ProductRateHeading';
import ProductRateBody from './helpers/ProductRateBody';
import Rate from './helpers/Rate';
import deconstructor from './utils/deconstructor';
import './product-rate.scss';

class ProductRate extends Component {
  constructor({ product }) {
    super();
    this.ProductStore = productStore();
    this.Product = this.ProductStore[product];
  }

  render() {
    const didLoad = this.ProductStore.fetched.rate === 'loaded';
    const Rates = !didLoad ? Failed : Rate;
    const { focusType } = this.props;
    const {
      ratesInfo,
      ratesContent,
      bodyInfo,
      headingContent,
    } = deconstructor(this.props, this.Product, didLoad);

    return (
      <div className="product-rate-template">
        <ProductRateHeading {...headingContent}>
          <RatingsAndReviews {...ratesInfo} />
        </ProductRateHeading>

        <ProductRateBody {...bodyInfo} focusType={focusType}>
          <Rates {...ratesContent} />
        </ProductRateBody>
      </div>
    );
  }
}

export default observer(ProductRate);
