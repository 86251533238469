/**
 * Returns a formatted rate based on the term and tier provided
 *
 * @param {object} terms Object containing all terms and tiers available
 * @param {string} term term to be returned `0M`
 * @param {string} tier tier to be returned `tier1`
 */

const getRate = ({ terms, term, tier }) => terms[term][tier].apy.toFixed(2);

export default getRate;
