import React from 'react';
import LinkTo from 'components/utility-components/link-to';
import { device } from 'utilities';
import './term-selector.scss';

const label = 'Term Length';

// TODO: this function is similar to one in pages/utility-pages/selection-list.jsx
// the functionality can be combined using the dynamic-content component
function monthsToYears(term) {
  const termNumber = parseInt(term, 10);
  if (termNumber !== 12 && termNumber % 12 === 0) {
    return `${termNumber / 12}-year`;
  }
  return `${termNumber}-month`;
}

// TODO remove any text ("Term Length" and the aria-labels) and move to store -- maybe?
const TermSelector = ({ product, currentTerm }) => {
  const termDate = monthsToYears(currentTerm);
  // attach tagging attributes
  const attrs = {
    id: 'termSelector',
    className: 'term-selector',
    to: `/selection-list/${product}/${currentTerm}/`,
  };

  return (
    <LinkTo {...attrs}>
      <p className="selector-label" aria-label="Term length selection">
        {label}
      </p>
      <p className="selector-value" aria-label={`${termDate} term currently selected`}>
        {`${termDate} `}
        <i className={`arrow ${device === 'ios' ? 'right' : 'down'}`} />
      </p>
    </LinkTo>
  );
};

export default TermSelector;
