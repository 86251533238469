import React from 'react';
import { linkRoleBtn, isString, getChildText } from 'components/utility-components/track';
import { StaticTrack, DynamicTrack } from './index';

const NavLink = ({ attributes, children }) => {
  const { schema, nochange, text } = attributes;
  const isStatic = isString(children) || nochange;
  const Tracker = isStatic ? StaticTrack : DynamicTrack;
  const name = attributes['aria-label'] || text || getChildText(children);
  const config = schema || linkRoleBtn;

  const attrs = {
    ...attributes,
    role: 'button',
  };
  delete attrs.schema;
  delete attrs.nochange;
  delete attrs.text;

  return (
    <Tracker {...{ attrs, text: name, schema: config }}>
      {children}
    </Tracker>
  );
};

export default NavLink;
