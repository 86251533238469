import React from 'react';
import './divider.scss';

const Divider = ({
  className,
}) => {
  const dividerClass = className ? `divider-component ${className}` : 'divider-component';
  return <hr aria-hidden="true" className={dividerClass} />;
};

export default Divider;
