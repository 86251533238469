import { createStore } from 'satcheljs';

const homePurchaseContent = {
  hero: {
    pageTitle: 'Home Mortgages',
    config: {
      template: 'text-with-accent',
    },
    content: {
      bgColor: 'plum',
      topCopy: 'Lender fees are out. Saving money is in.',
      topCopyClass: 'title-full-width',
      description:
        'We believe money’s better spent on a home. That’s why, with us, lender fees are a thing of the past.',
      cta: {
        skin: 'outline',
        text: 'Get Pre-approved',
        // This URL is part of a handoff to the mobile app (Angry Nerds)
        // They redirect the user based on logic on their end

        // The URLs will need to be switched once Angry Nerds
        // finalize their changes for Mortgage Prefill
        url: 'ally://mortgageLead',
      },
    },
  },
  fastForwardLoanApp: {
    modifier: 'imgContentPair--center',
    title: 'We’ve pressed fast forward on loan applications.',
    subtitle: 'Our modern mortgage experience simplifies buying, so you can focus on living.',
    imgSrc: 'home/mortgage-devices.svg',
    imgClassName: 'img-left',
    ulClassName: 'custom-icon',
    bulletIcon: 'other/icon-green-check.svg',
    listHeading: 'Applying is simple and can be completed 100% online anytime, from anywhere.',
    list: [
      'No lender fees - no application, origination, processing, or underwriting fees (unlike most big banks and lenders)',
      'Get pre-approved in as little as 3 minutes',
      'State-of-the-art technology that helps you avoid excessive paperwork',
      'Reliable support from a team of home loan experts',
    ],
  },
  yourHomeYourTerms: {
    titleLevel: 'h2',
    title: 'Your home, your terms.',
    classNameTitle: 'title-large',
    ptClassName: 'padding-top-large center',
  },
  optionsFixedRate: {
    column: true,
    imgSrc: 'home/icon-fixed-rate.svg',
    title: 'Fixed Rate',
    copy:
      'Consider if you’re looking for consistent monthly payment and a rate that won’t change over the life of your loan.',
  },
  optionsAdjustableRate: {
    column: true,
    imgSrc: 'home/icon-adjustable-rate.svg',
    title: 'Adjustable Rate',
    copy:
      'Consider if you plan on moving or refinancing in 5, 7, or 10 years and want to pay less in interest than you would with a fixed-rate loan.',
  },
  optionsJumboLoans: {
    column: true,
    imgSrc: 'home/icon-jumbo-loan.svg',
    title: 'Jumbo Loans',
    copy:
      'If a higher-priced home means you need a larger loan, we also offer competitive rates on jumbo loans.',
  },
  helpGetTheHomeYouWant: {
    bgColor: 'clear',
    titleLevel: 'h2',
    title: 'We’ll help you get the home you want.',
    text: [
      'Whether you’re buying your first home or vacation property, we offer programs with down payment options as low as 3%. @@Learn about the HomeReady program::https://www.ally.com/education/financial-life/first-time-home-buyer/::@@',
    ],
  },
  crunchTheNumbers: {
    titleLevel: 'h2',
    title: 'Crunch the numbers.',
    classNameTitle: 'title-large',
    ptClassName: 'padding-top-large center border-top',
  },
  crunchNumberTiles: {
    cards: [
      {
        title:
          '@@Monthly payment calculator::https://www.ally.com/home-loans/mortgage-payment-calculator/::@@',
        description:
          'It helps to have an idea of what your payments could be so you can set a realistic budget.',
        imgSrc: 'home/calculator-monthly-payment.png',
      },
      {
        title:
          '@@Affordability Calculator::https://www.ally.com/home-loans/home-affordability-calculator/::@@',
        description:
          'We look at your income and financial obligations to estimate how much house you can afford.',
        imgSrc: 'home/calculator-affordability.png',
      },
    ],
  },
};

export default createStore('homePurchaseContent', homePurchaseContent);
