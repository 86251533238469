import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { lazyWithPreload } from 'utilities';
import irasContent from 'stores/pages/iras';
import Category from 'pages/explore/category';
import { updateDDO } from 'components/utility-components/track';

const IraRaiseYouRate = lazyWithPreload(() => import('pages/explore/products/iras/ira-raise-your-rate'))();
const IraOsa = lazyWithPreload(() => import('pages/explore/products/iras/iraosa'))();
const IraHycd = lazyWithPreload(() => import('pages/explore/products/iras/irahycd'))();
const pageTitle = 'IRAs';
const lineOfBusiness = 'Bank';

const IRA = (props) => {
  updateDDO({ pageName: pageTitle, lineOfBusiness });
  return <Category {...props} title={pageTitle} categoryContent={irasContent} />;
};

const IRARYRCD = (props) => {
  updateDDO({ pageTitle, pageName: 'IRA Raise Your Rate CD', lineOfBusiness });
  return <IraRaiseYouRate {...props} />;
};

const IRAOSA = (props) => {
  updateDDO({ pageTitle, pageName: 'IRA Online Savings', lineOfBusiness });
  return <IraOsa {...props} />;
};

const IRAHYCD = (props) => {
  updateDDO({ pageTitle, pageName: 'IRA High Yield CD', lineOfBusiness });
  return <IraHycd {...props} />;
};

const IraRouter = (params) => {
  const { match: { url } } = params;
  return (
    <Switch>
      {/* The RYRCD and IRARYRCD pages use tiers to pass their terms */}
      <Route exact path={`${url}/ira-osa/:tier?/:term?`} render={IRAOSA} />
      <Route exact path={`${url}/irahycd/:tier?/:term?`} render={IRAHYCD} />
      <Route exact path={`${url}/ira-raise-your-rate/:tier?/:term?`} render={IRARYRCD} />
      <Route exact path={`${url}/:tier?/:term?`} render={IRA} />
    </Switch>
  );
};

export default IraRouter;
