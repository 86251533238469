import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { device, adobeLaunchLoader } from 'utilities';
import { dispatch } from 'satcheljs';
import { GET_OFFERS } from 'actions/mbox';
import { WINDOW_JSON_OVERRIDE } from 'actions/utility';
import App from './routes/App';
import './mutators';
import './orchestrators';

// add script tag for tracking
adobeLaunchLoader().then(GET_OFFERS);


// add class to the wrapping div
document.body.classList.add(device);

dispatch(WINDOW_JSON_OVERRIDE);

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('authSalesMobile'),
);
