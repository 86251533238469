import React from 'react';
import { nativeAlertDejarg } from 'utilities';
import { dejarg, isString } from 'components/utility-components/track';
import './dejargonator.scss';

const { get: getSchema } = dejarg;

const getText = (children) => {
  if (isString(children)) {
    return children;
  }
  const { type, props: { alt } } = children;
  if (type === 'img') {
    return alt;
  }
  return children.innerText;
};

const getAttrs = (key, text) => ({
  className: 'dejargonator',
  role: 'button',
  tabIndex: 0,
  onClick: () => nativeAlertDejarg(key),
  ...getSchema({ text }),
});

const getCoronaVirusFF = () => {
  try {
    const ffCoronaVirusRegD = window.ldClient.variation('FF_corona-virus-reg-d');
    return ffCoronaVirusRegD || false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getRegDFF = () => {
  try {
    const ffRegD = window.ldClient.variation('FF_reg-d-2023');
    return ffRegD || false;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const Dejargonator = ({ dejargKey, children }) => {
  const covidKeys = ['whySix', 'sixTransfersAndWithdrawals', 'excessiveTransactions'];
  const regDKeys = ['excessiveTransactions'];

  if (getCoronaVirusFF() && covidKeys.includes(dejargKey)) {
    // remap to a new dejarg key which will have the feature flagged title/body
    dejargKey += 'Covid';
  }

  if (getRegDFF() && regDKeys.includes(dejargKey)) {
    dejargKey += 'RegD';
  }

  // TODO: This span should be converted to an anchor tag.
  return (
    <span {...getAttrs(dejargKey, getText(children))}>
      {children}
    </span>
  );
};

export default Dejargonator;
