import { mutator } from 'satcheljs';
import productStore from 'stores/products';
import { STORE_RATES } from 'actions/products';
import buildTerms from './helpers/build-terms';


export default
mutator(STORE_RATES, (ratesResponse) => {
  ratesResponse.forEach((product) => {
    const { productName, rates } = product;
    productStore()[productName].effectiveDate = rates.effectiveDate;
    productStore()[productName].terms = buildTerms(rates);
    productStore().fetched.rate = 'loaded';
  });
});
