/**
 * Takes the tier url param `tier1` and returns the
 * position of the tab in the segmented control. `0`
 *
 * @param {string} tier currently selected tier `tier1`
 * @returns {number} `0`
 */

const tierVal = (tier = 'tier1') => {
  const lst = tier.length - 1;
  return parseInt((tier[lst]), 10) - 1;
};

export default tierVal;
