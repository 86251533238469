// default to ios device
const globalParams = {
  device: 'ios', // 'ios' or 'android'
  testRates: 'normal', // 'normal', 'loading', or 'failed'
  testReviews: 'normal', // 'normal', 'loading', or 'failed'
};

// attaches all url params to the globalParams object
const getParams = () => {
  const {
    location: { search },
  } = window;
  search
    .substr(1)
    .split('&')
    .forEach(qp => {
      const params = qp.split('=');
      globalParams[params[0]] = params[1];
    });
  return globalParams;
};

const { device, testRates, testReviews } = getParams();

export { getParams, globalParams, device, testRates, testReviews };
