import React from 'react';

import UPDATE_TERM_TIER from 'actions/pages';
import { nextFocus, resolveImagePath } from 'utilities';

import { selectTermLength, makeTrackText } from 'components/utility-components/track';

// checkmark svg is standard across all uses of this page,
// and props are passed only through url
const checkMark = resolveImagePath('other/checkmark.svg');


const { get: getSchema } = selectTermLength;

// TODO: combine this function with the one
// in components/term-selector (use DynamicContent?)
const monthsToYears = (term) => {
  const termNumber = parseInt(term, 10);
  if (termNumber !== 12 && termNumber % 12 === 0) {
    return `${termNumber / 12} years`;
  }
  return `${termNumber} months`;
};

const getListItemAttrs = (key, text) => ({
  className: 'term-selector-list-item',
  'data-info': key,
  style: { position: 'relative' },
  ...getSchema({ text }),
});

const ShowAsActive = ({ liText }) => (
  <React.Fragment>
    <span className="selected-term-accessability">
      {`${liText} selected`}
    </span>
    <img className="selected-term-indicator" aria-hidden="true" src={checkMark} alt="" />
  </React.Fragment>
);

const update = ({ target: { dataset: { info: term } } }, { product, history }) => {
  // load the associated page with the selected term
  UPDATE_TERM_TIER({
    updating: 'term',
    updateTo: term,
    product,
  });
  // sets the target for accessability focus when going back to the previous page
  nextFocus('termSelector');
  // set history to return to the previous when back button clicked
  history.goBack();
};

const ListItem = ({
  id,
  history,
  match,
}) => {
  const { params } = match;
  const { product, currentTerm } = params;
  const isSelected = id === currentTerm;

  const liText = monthsToYears(id);
  const text = makeTrackText(liText);
  const attrs = getListItemAttrs(id, text);
  const clickHandler = event => update(event, { product, history });

  // TODO: address semantic/a11y issue with using li here
  return (
    <li {...attrs} onClick={clickHandler}>
      <span className="term-length" aria-hidden={isSelected}>{liText}</span>
      {isSelected && <ShowAsActive {...{ liText }} />}
    </li>
  );
};

export default ListItem;
