import * as React from 'react';

export default function StarSingle({ product = 'default', rating, reviewCount }) {
  return (
    <svg className="star-single" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 37 37" role="img">
      <title id={`Item-reviews-rating-stars-title-${product}`}>
        {`Rated ${rating} out of 5 stars, based on ${reviewCount} reviews.`}
      </title>
      <defs>
        <g id={`rating-star-path-reviews-rating-stars-${product}`}>
          <polygon className="star-shape rating-star-1 active" points="17.9,28 7.1,35.1 10.5,22.7 0.3,14.6 13.3,14 17.9,1.9 22.4,14 35.3,14.6 25.1,22.7 28.7,35.1" />
        </g>
      </defs>
      <g>
        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#rating-star-path-reviews-rating-stars-${product}`} fill="#FFC107" />
      </g>
    </svg>
  );
}
