import { focusTopOfApp } from 'utilities';

// defined outside of component so an
// update loop doesn't occur from componentDidUpdate
const focusManager = {
  idToFocus: null,
  focusAfterLoad() {
    // goes back to the top of the app on new render
    // (each page) UNLESS a page has used setNextFocus
    const {
      location: { hash },
    } = window;
    const el = document.getElementById(this.idToFocus);

    if (el) {
      el.focus();
    } else if (!hash) {
      focusTopOfApp();
    }
    // reset focus pointer
    this.setNext(null);
  },
  setNext(target) {
    this.idToFocus = target;
  },
  nextFocus(target) {
    if (target) {
      this.setNext(target);
    } else {
      this.focusAfterLoad();
    }
  },
};

const { nextFocus } = focusManager;

// used to set the target on next render (page);
// intended for use with history.goBack(),
// specifically the SelectionList page

export default nextFocus.bind(focusManager);
