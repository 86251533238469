import React from 'react';
import './failed-rate-placeholder.scss';
import { focusLoader } from '@ally/auth-sales-shared/utilities';

const FailedRatePlaceholder = ({ className, focusType, label }) => (
  <div
    aria-label={label}
    tabIndex="0"
    ref={(element) => {
      focusLoader.loadFocus(element, focusType);
    }}
    className={`failed-rate-placeholder ${className || ''}`}
  >
    <p aria-hidden="true" className="placeholder-percent">%</p>
  </div>
);

export default FailedRatePlaceholder;
