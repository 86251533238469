/* eslint-disable react/prop-types */
import React from 'react';

const styles = require('./Rate.scss');

const Rate = ({
  apy,
  measure,
  className,
}) => (
  <p className={`${styles.rate} ${className}`}>
    <span className={styles.apy}>{Number(apy).toFixed(2)}</span>
    <span className={styles.measure}>{measure}</span>
  </p>
);

export default Rate;
