import React, { Component } from 'react';
import { keygen } from '../../utilities';
import TitleCopy from './helpers/title-copy';
import DejargContent from './helpers/dejarg-content';
import './dejarg-dropdown.scss';

const name = 'DejargDropdown';
const nextKey = keygen(name, 0);

class DejargDropdown extends Component {
  constructor() {
    super();
    this.keyId = nextKey();
    this.name = name;
    this.state = {
      active: false,
    };
    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive() {
    const { active } = this.state;
    this.setState({ active: !active });
  }

  render() {
    const { title, rows } = this.props;
    const { active } = this.state;
    const { name, keyId } = this;

    const panelState = active ? 'open' : 'closed';
    const chevronClass = `${name}-chevron ${name}-chevron-${panelState}`;

    const btnAttrs = {
      id: keyId,
      onClick: this.toggleActive,
      className: `${name}-button`,
      'aria-expanded': active,
    };

    const dejargAttrs = {
      rows,
      active,
      name,
      parent: keyId,
    };

    return (
      <div className={`${name} accordion-${panelState}`}>
        <button type="button" {...btnAttrs}>
          <span className={chevronClass} />
          <div className="content">
            <p className={`${name}-title`}>
              {title}
            </p>
            <TitleCopy {...{ rows, name }} />
          </div>
        </button>
        <DejargContent {...dejargAttrs} />
      </div>
    );
  }
}

export default DejargDropdown;
