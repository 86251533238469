import { createStore } from 'satcheljs';
import { device } from 'utilities';

const isDroid = device === 'android';

const tiers = [
  '2-Year Term',
  '4-Year Term',
];

const iraRaiseYourRate = {
  hero: {
    pageTitle: 'IRA Raise Your Rate CD',
    config: {
      bottomColor: 'iras',
      template: 'product-page-header',
      product: 'iraRaiseYourRate',
      terms: {
        tier1: '24M',
        tier2: '48M',
      },
      includeRating: false,
    },
    content: {
      tiers,
      hiddenText: tiers,
      rateText: `%s${isDroid ? '++%++' : '%'} APY`,
      extraInfoBelow: 'No minimum deposit',
      tierInfo: {
        tier1: '%%Raise your rate 1 time%%',
        tier2: '%%Raise your rate 2 times%%',
      },
      cta: {
        text: 'Open Account',
        url: 'ally://account_opening/IRARYRCD-%s',
      },
    },
    disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s. Early withdrawal penalty will apply.',
  },
  intro: {
    title: 'Give your retirement savings a raise.',
    titleLevel: 'h2',
    ptClassName: 'padding-bottom-small',
    className: 'not-nested',
    copy: 'Start with a great rate, plus have the opportunity to increase your rate once over the 2-year term or twice over the 4-year term if our rate on your term and balance tier goes up.',
  },
  bullets: [
    'No minimum deposit required to open your account',
    'Grow your money faster with ##interest compounded daily::compoundedInterest::##',
    'Your deposits are insured by the ##FDIC::fdic::## up to the maximum allowed by law. @@Maximize your coverage::https://www.ally.com/bank/fdic/::@@',
    'Available for Roth, SEP and Traditional IRAs. @@Find the IRA for you::https://www.ally.com/bank/ira/ira-account/::@@',
    'Flexible funding options, including rollover to Roth and Traditional IRAs',
  ],
  infoCardArea: {
    title: 'our favorite features',
    cards: [
      {
        icon: 'ira/greatrates.svg',
        title: 'Great rates',
        copy: 'Get the best rate we offer on the day you open your IRA CD or the day you fund, when you fund within 90 days of opening your IRA CD',
      },
      {
        icon: 'ira/peaceofmind.svg',
        title: 'Peace of mind',
        copy: 'Our award-winning customer care is available 24/7.',
      },
      {
        icon: 'ira/manageonline.svg',
        title: 'Manage your IRA CD online',
        copy: 'When your IRA CD is ready for renewal, it’s easy to let us know what you want to do with your money online.',
      },
      {
        icon: 'ira/nomaintenance.svg',
        title: 'No monthly maintenance fees',
        copy: "We don't charge monthly maintenance fees for your Ally Bank IRA CD.",
      },
      {
        icon: 'ira/10day.svg',
        title: 'Ally 10 Day Best Rate Guarantee',
        copy: "When your IRA CD matures, you can add or withdraw your money at no charge during a 10-day grace period. If you decide to renew your IRA CD, don't do a thing, and we'll automatically renew it.",
      },
    ],
  },
  noHiddenFees: {
    title: 'Early Withdrawals',
    class: 'no-hidden-fees',
    text: [
      {
        ptClassName: 'padding-bottom',
        className: 'not-nested',
        copy: 'If you redeem your IRA CD before the maturity date, the penalty we charge will depend on your IRA CD term (see penalty schedule). ##How is the penalty calculated?::penaltyCalculation::##',
      },
      {
        ptClassName: 'padding-bottom',
        className: 'not-nested',
        copy: 'An additional IRS tax penalty may also apply. Please consult your tax professional.',
      },
      {
        ptClassName: 'padding-bottom',
        className: 'not-nested',
        copy: 'See the @@Ally Bank Deposit Agreement::https://www.ally.com/docs/bank/deposit-agreement/::@@ for full details on how we calculate the withdrawal penalty.',
      },
    ],
  },
  tableSections: [
    {
      sectionClass: 'no-dejarg',
      title: 'Purchased or Renewed Before 12/07/13',
      rows: [
        {
          heading: 'All IRA CDs',
          content: '60 days of interest',
        },
      ],
    },
    {
      sectionClass: 'no-dejarg',
      title: 'Purchased or Renewed After 12/07/13',
      rows: [
        {
          heading: '2 years',
          content: '60 days of interest',
        },
        {
          heading: '4 years',
          content: '120 days of interest',
        },
      ],
    },
  ],
  purchasedOrRenewed: {
    class: 'purchased-or-renewed',
    title: 'Purchased or Renewed',
    tabbedText: {
      heading: {
        title: 'Purchased or Renewed',
        titleLevel: 'h3',
      },
      product: 'purchased-or-renewed',
      template: 'list-item-template',
      tiers: [
        'Before 12/07/13',
        'After 12/07/13',
      ],
      tierInfo: {
        tier1: [
          {
            title: 'All IRA CDs',
            copy: '60 days of interest',
          },
        ],
        tier2: [
          {
            title: '2 years',
            copy: '60 days of interest',
          },
          {
            title: '4 years',
            copy: '120 days of interest',
          },
        ],
      },
      tableDisclaimer: 'The penalty we charge will depend on your IRA CD term.',
    },
  },
};

export default createStore('iraRaiseYourRate', iraRaiseYourRate);
