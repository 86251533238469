import React from 'react';
import { Link } from 'react-router-dom';

function RenderLink(attrs, children) {
  return function out(props) {
    const { passRef } = props;
    const linkAttrs = { ...props, ...attrs };
    delete linkAttrs.passRef;

    return <Link ref={passRef} {...linkAttrs}>{children}</Link>;
  };
}

export default RenderLink;
