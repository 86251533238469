import React from 'react';
import LinkTo from 'components/utility-components/link-to';
import InlineDejarg from 'components/inline-dejarg';
import formatMarkup from '@ally/aos-utils/formatting/markup';

const Copy = ({ children }) => {
  function filterArray(regex, copy) {
    let tempArray;

    if (regex.test(copy)) {
      const match = regex.exec(copy)[0]; // return first string matching regex
      // split by match, force back into array
      tempArray = copy.split(match);

      // adds all instances of the same match into the appropriate place in the array
      let endTarget = tempArray.length - 1;
      while (endTarget >= 1) {
        tempArray.splice(endTarget, 0, match);
        endTarget -= 1;
      }

      return tempArray;
    }
    // no regex, force array
    return [copy];
  }

  // TODO: regex breaks when any character is deirectly after the expression
  // eg. '%%fine%% and %%this is fine too%%, but this comma makes it %%break%%'
  // it also turns the remaining copy into a dejarg
  function makeCopy(copy) {
    // LEGEND:
    //  %%bold%%
    //  ~~strikethrough~~
    //  //italics//
    //  --subscript--
    //  ++superscript++
    //  @@link text::url::@@
    //  !!img src::alt text::!!
    //  ##dejarg text::dejarg key::##
    //  ==aria-hidden==

    // begins and ends with two [ % | ~ | / | - | + | @ | # | $ ] characters,
    // with any amount of characters between
    // TODO: Discuss other options for images besides !'s - could be used in regular text
    const copyReg = /([%~\/\-\+#\@!\=])(?=\1)[\s\S]+?\1{2}/;

    // placeholder
    const strArray = filterArray(copyReg, copy);

    const finalArray = strArray.map((string, index) => {
      if (copyReg.test(string) && /^\S/.test(string)) {
        // no need to run if regestring does not match
        if (/^%/.test(string)) {
          // test for bold
          string = string.replace(/%{2}/g, '');
          return <strong key={`${string}-${index}-strong`}>{makeCopy(string)}</strong>;
        }
        if (/^~/.test(string)) {
          // test for strikethrough
          string = string.replace(/~{2}/g, '');
          return <del key={`${string}-${index}-del`}>{makeCopy(string)}</del>;
        }
        if (/^\//.test(string)) {
          // test for italics
          string = string.replace(/\/{2}/g, '');
          return <em key={`${string}-${index}-em`}>{makeCopy(string)}</em>;
        }
        if (/^-/.test(string)) {
          // test for subscript
          string = string.replace(/-{2}/g, '');
          return <sub key={`${string}-${index}-sub`}>{makeCopy(string)}</sub>;
        }
        if (/^\+/.test(string)) {
          // test for superscript
          string = string.replace(/\+{2}/g, '');
          return <sup key={`${string}-${index}-sup`}>{makeCopy(string)}</sup>;
        }
        if (/^\@/.test(string)) {
          // test for url
          const dest = string.split('::')[1];
          const linkAttrs = {
            'data-track-name': { string },
            'data-track-elem': 'link',
            'data-track-trigger': 'internal',
          };
          string = string.replace(/\@{2}/g, '');
          string = string.replace(/(\:{2})[\s\S]+?\1/g, '');
          return (
            <LinkTo to={dest} key={`${string}-${index}-internal-link`} {...linkAttrs}>
              {makeCopy(string)}
            </LinkTo>
          );
        }
        if (/^!/.test(string)) {
          LinkTo;
          // test for img
          const alt = string.split('::')[1];
          string = string.replace(/!{2}/g, '');
          string = string.replace(/(\:{2})[\s\S]+?\1/g, '');
          return <img key={`img-${index}`} aria-hidden="true" src={string} alt={alt} />;
        }
        if (/^=/.test(string)) {
          // test for aria-hidden
          string = string.replace(/={2}/g, '');
          return <span aria-hidden="true" key={`${string}-${index}-hidden`}>{makeCopy(string)}</span>;
        }
        const dejargKey = string.split('::')[1];
        string = string.replace(/#{2}/g, '');
        string = string.replace(/(\:{2})[\s\S]+?\1/g, '');
        return (
          <InlineDejarg key={`${string}-${index}-dejarg`} dejargKey={dejargKey}>
            {string}
          </InlineDejarg>
        );
      }
      if (copyReg.test(string)) {
        return makeCopy(string); // recursive if more matches in sub strings
      } // plain non-formatted copy

      return (
        <React.Fragment key={`${string}-${index}-no-format`}>{formatMarkup(string)}</React.Fragment>
      );
    });
    return finalArray;
  }
  return <React.Fragment>{makeCopy(children)}</React.Fragment>;
};

export default Copy;
