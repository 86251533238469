import React from 'react';
import DynamicContent from 'components/utility-components/dynamic-content';
import StarRating from 'components/star-rating';
import StarSingle from 'components/star-single';
import { device } from 'utilities';
import './ratings-and-reviews.scss';

const RatingsAndReviews = ({
  overallRating,
  reviewCount,
  reviewText,
  a11yText,
  product,
}) => (
  <div className="RatingsAndReviews">
    <StarRating {...{ product, reviewCount, rating: overallRating }} />
    {/* NOTE: device is defined globally in the index.html */}
    { device === 'android' && <StarSingle {...{ product, reviewCount, rating: overallRating }} /> }
    <p className="sr-text-parent review-content">
      <span className="sr-text review-content">
        <DynamicContent dynamicContent={[overallRating, reviewCount]}>
          {a11yText}
        </DynamicContent>
      </span>
      <span aria-hidden="true">
        <DynamicContent dynamicContent={[overallRating, reviewCount]}>
          {reviewText}
        </DynamicContent>
      </span>
    </p>
  </div>
);

export default RatingsAndReviews;
