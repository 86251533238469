import React, { Suspense, lazy } from 'react';

const lazyWithPreload = (factory) => {
  const Loader = lazy(factory);
  Loader.preload = factory;
  return () => {
    Loader.preload();

    return params => (
      <Suspense fallback={<div />}>
        <Loader {...params} />
      </Suspense>
    );
  };
};

export default lazyWithPreload;
