import * as React from 'react';

// TODO: Should we consider making this an allowed tag list instead.
const FORBIDDEN_TAGS = ['script', 'link', 'iframe', 'form', 'input', 'textarea', 'button'];

/**
 * Deal with rendering markup inside a fragment of footer content, like <strong> tags
 * TODO: we probably need to trampoline this or otherwise protect the stack against
 * an overflow from deeply nested markup structures
 * @param content {String} string of content that might contain stylistic markup
 * @returns JSX.Element || JSX.Element[]
 */
export default function formatMarkup(content: string | ChildNode): JSX.Element[] | JSX.Element {
  const testElement = document.createElement('div');

  if (typeof content === 'string') {
    testElement.innerHTML = content;
  } else {
    testElement.innerHTML = (content as HTMLElement).outerHTML;
  }

  if (testElement.childNodes.length) {
    return Array.from(testElement.childNodes).map((node, index) => {
      const Element = node.nodeName.toLowerCase();

      if (!FORBIDDEN_TAGS.includes(Element)) {
        if (Element === '#text') {
          return <span key={`${Element}-content-${index}`}>{node.textContent}</span>;
        }

        const htmlNode = node as HTMLElement;

        const key = `${Element}-content-${index}`;

        // if this element had specifically assigned attributes, we want to preserve them
        // in our generated markup
        // iterate through attributes and reduce them to a simple Object { [attribute]: [value] }
        const attributes = Array.from(htmlNode.attributes).reduce(
          (obj, attribute) => {
            return Object.assign({}, obj, {
              [attribute.name]: attribute.value,
            });
          },
          { key },
        );

        return <Element {...attributes}>{formatMarkup(htmlNode.innerHTML)}</Element>;
      }

      // TypeScript is exepcting an Element to be returned by each item
      // in this Array.prototype.map() call, so we'll just return a null
      // Component using an empty React.Fragtment
      return <React.Fragment />;
    });
  }

  return <span>{content}</span>;
}
