/* eslint-disable arrow-parens */
/* eslint-disable implicit-arrow-linebreak */
// even if you "fix" these linting errors, the prettier pre-commit hook will break them again
const fetchLaunchDarklyFlag = (flagName = '', defaultValue = false) => {
  try {
    return window.ldClient.variation(flagName, defaultValue);
  } catch (err) {
    console.error(err);
    return defaultValue;
  }
};

export default fetchLaunchDarklyFlag;
