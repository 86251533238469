import React from 'react';
import NavLink from 'components/utility-components/nav-link';
import LinkExternal from 'components/utility-components/link-external';
import { device } from 'utilities';
import jumpTo from './helpers/jump-to';
import './link-to.scss';

const isAndroid = device === 'android';


const LinkTo = ({
  className,
  to,
  children,
  role,
  label,
  id,
  onClick,
  attrs,
}) => {
  const attributes = Object.assign({
    className,
    role,
    id,
    onClick,
  }, attrs);

  if (/^(https?|ally):\/\//.test(to)) {
    // external
    attributes.href = to;
    attributes['aria-label'] = label;
    return <LinkExternal {...{ attributes, children }} />;
  } if (/^#/.test(to)) {
    // anchor tag
    attributes.href = to;
    if (!isAndroid) {
      attributes.onClick = jumpTo(to);
    }
    return <a {...attributes}>{ children }</a>;
  }
  // internal
  // keeps custom device URL for internal navigation
  attributes.to = `${to}`;
  attributes['aria-label'] = label;
  attributes['data-track-trigger'] = 'internal';

  if (className && className.includes('card')) {
    attributes['data-track-elem'] = 'card';
  } else {
    attributes['data-track-elem'] = 'link';
  }

  return (
    <NavLink {...{
      attributes, to, children,
    }}
    />
  );
};

export default LinkTo;
