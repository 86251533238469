import React, { Component } from 'react';
import CssLoader from 'components/utility-components/css-loader';
import ForceReloadButton from 'components/utility-components/force-reload-button';
import { observer } from 'mobx-react';
import { productStore } from 'stores';
import { SET_AS_LOADING } from 'actions/products';
import './content-loader.scss';

class ContentLoader extends Component {
  constructor() {
    super();
    this.Store = productStore();
  }

  // fade does not occur if the element is not rendered prior to the fade.
  // The CssLoader element needs to live alongside the element it is
  // replacing, so both are rendered if the placeholder loadAction is passed
  checkForLoadAction({
    loadAction,
    className,
    flag,
    label,
    focusType,
  }) {
    if (loadAction === 'placeholder') {
      if (this.Store.fetched[flag] === 'loading' || this.Store.fetched[flag] === '') {
        return <CssLoader className={className} />;
      }
    } else if (loadAction === 'tapOnFail') {
      if (this.Store.fetched[flag] === 'failed') {
        return <ForceReloadButton focusType={focusType} label={label} className={`${className} retry-button text-center`} />;
      }
    }
    return null;
  }

  // observer will trigger the animation as the fetched flag is toggled
  applyLoadStatusClass({ flag }) {
    return this.Store.fetched[flag] || 'loading';
  }

  renderContentToLoad({ flag, children }) {
    // checks the stored data for existing data based on the passed flag prop
    // as well as making sure that rate call has not already begun (prevents duplicate API calls)
    if (this.Store.fetched[flag] === 'loading') {
      // hide the content from screen readers but still keep blocked space
      return <div aria-hidden="true">{ children }</div>;
    } if (!this.Store.fetched[flag] && this.Store.fetched[flag] !== 'loading') {
      SET_AS_LOADING({ flag });
      // runs the action needed for to fetch the corresponding flag data
      this.Store.fetch[flag]();
      // hide the content from screen readers but still keep blocked space
      return <div aria-hidden="true">{ children }</div>;
    }
    return children;
  }

  render() {
    const {
      className, loadAction,
    } = this.props;

    return (
      <React.Fragment>
        { this.checkForLoadAction(this.props) }
        <div className={`${className || ''} ${loadAction || ''} ${this.applyLoadStatusClass(this.props)}`}>{this.renderContentToLoad(this.props)}</div>
      </React.Fragment>
    );
  }
}

export default observer(ContentLoader);
