import React from 'react';
import { dejargMap } from 'stores';

const dejargs = dejargMap();

const DejargRows = ({ rows, name }) => rows.map(({ dejargKey }) => (
  <p className={`${name}-content`} key={dejargKey}>{dejargs[dejargKey].body}</p>
));

export default DejargRows;
