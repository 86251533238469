import { action } from 'satcheljs';

export const GET_OFFERS = action(
  'GET_OFFERS',
);

export const STORE_OFFERS = action(
  'STORE_OFFERS',
  (mboxName, offersResponse) => ({ [mboxName]: offersResponse }),
);
