import React from 'react';
import AdaText from 'components/ada-text';
import SubCopy from 'components/sub-copy';
import InlineDejarg from 'components/inline-dejarg';

const Row = React.memo(({
  heading,
  content,
  dejargKey,
  subCopy = '',
  subCopy2 = '',
}) => {
  const name = 'GenerateListRows';
  const key = `row-${heading}`;
  const adaContent = `${heading} ${subCopy} ${content} ${subCopy2}`;
  const TopHeading = dejargKey ? InlineDejarg : SubCopy;
  const styles = {
    letterSpacing: '70px',
    whiteSpace: 'nowrap',
  };

  return (
    <li className={`${name}-item`} key={key}>
      <AdaText styles={styles}>
        {adaContent}
      </AdaText>
      <div className="GenerateListRows-display-row display-row">
        <TopHeading column={heading} copy={subCopy} dejargKey={dejargKey} />
        <SubCopy column={content} copy={subCopy2} />
      </div>
    </li>
  );
});

const GenerateListRows = React.memo(({ rows }) => rows
  .map(section => <Row key={`row-${section.heading}`} {...section} />));

export default GenerateListRows;
