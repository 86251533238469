import React from 'react';
import resolveImagePath from '../../utilities/resolve-image-path';
import Copy from '../utility-components/copy';
import SimpleList from '../simple-list';
import ValueProp from '../valueprop';
import fetchLaunchDarklyFlag from '../../utilities/fetch-feature-flags';
import './img-with-content.scss';

/**
 * Creates an image with a list from Props passed in through data store
 *
 * @param {String} title centered and bolded title for component `Check out this cool product`
 * @param {String} subtitle centered text directly under the title `Short blurb about said product`
 * @param {String} description left aligned paragraph at top of component `This is a paragraph`
 * @param {path} imgSrc path to image to go along side of the list `other/STK-EPR-Graph-OSA.png`
 * @param {String} imgClassName className to apply to image `img-left`
 * @param {path} bulletIcon path to image to be used in place of bullets `icons/icon-check.svg`
 * @param {Array} list array of strings - content for the list items `['Trades', 'Services']`
 * @param {String} info paragraph on the right of the image (rather than bullets) `Paragraph text`
 *
 * @returns JSX element with image and list styling
 */

const ImgWithContent = ({
  modifier = '',
  title,
  titleAria,
  subtitle,
  description,
  descriptionFlag,
  descriptionStyles,
  imgSrc,
  imgAltText,
  imgClassName,
  imgWidth,
  ulClassName,
  bulletIcon,
  list,
  listNoFF,
  valueProps,
  info,
  infoNoFF,
  flag,
  listHeading = '',
}) => {
  let outerClass;

  if (ulClassName) {
    outerClass = `listContainer ${ulClassName}`;
  } else {
    outerClass = 'listContainer';
  }

  return (
    <section className={`imgContentPair ${modifier}`}>
      <header className="imgContentPair-header">
        {title && (
          <h3 aria-label={titleAria} className="imgContentPair-title">
            <Copy>{title}</Copy>
          </h3>
        )}
        {subtitle && (
          <p className="imgContentPair-subtitle">
            <Copy>{subtitle}</Copy>
          </p>
        )}
      </header>
      {description && (
        <p className={`imgContentPair-description ${descriptionStyles}`}>
          <Copy>{descriptionFlag && fetchLaunchDarklyFlag(descriptionFlag.name) ? descriptionFlag.text : description}</Copy>
        </p>
      )}
      <div className="imgContentPair-flexWrapper">
        {imgSrc && (
          <div className="imgContentPair-image">
            {imgAltText ? (
              <img
                width={imgWidth}
                alt={imgAltText}
                className={imgClassName}
                src={resolveImagePath(imgSrc)}
              />
            ) : (
              <img
                aria-hidden="true"
                alt=" "
                className={imgClassName}
                src={resolveImagePath(imgSrc)}
              />
            )}
          </div>
        )}
        <div className="imgContentPair-content">
          {list && (
            <SimpleList
              ulClassName={outerClass}
              liClassName="listItem"
              info={flag && !fetchLaunchDarklyFlag(flag) ? listNoFF : list}
              bulletIcon={bulletIcon}
              listHeading={listHeading}
            />
          )}
          {valueProps && valueProps.map(prop => <ValueProp {...prop} />)}
          {info && (
            <p className="info">
              <Copy>{flag && !fetchLaunchDarklyFlag(flag) ? infoNoFF : info}</Copy>
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default ImgWithContent;
