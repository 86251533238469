/* eslint-disable react/jsx-one-expression-per-line, react/no-danger, react/prop-types */
import React from 'react';
import './rate-table-hero.scss';
import ProductRatings from '@ally/auth-sales-shared/components/ProductRatings';
import Rate from '@ally/auth-sales-shared/components/Rate';
import { convertMarkdown } from '@ally/auth-sales-shared/utilities';
import ContentLoader from '../utility-components/content-loader';
import { DATE } from '../../utilities';
import Button from '../button';

const MEASURE = 'Annual Percentage Yield'; // deciding at this time that measure is not configurable by json offers

const RateTableHero = ({
  headline,
  subhead,
  cta: { link, text, ariaLabel },
  apy,
  apySubtext,
  overallRating,
  productName,
  productTitle,
  reviewCount,
  ...offer
}) => {
  const effectiveDate = offer.effectiveDate || DATE;
  const disclosure = offer.disclosure || `Annual Percentage Yield (APY) accurate as of ${effectiveDate}.`;

  const productRatingsAttrs = {
    className: 'rate-table-hero__rating',
    overallRating,
    productName,
    reviewCount,
    showTextInfo: true,
  };

  return (
    <article className="rate-table-hero">
      <h2 className="rate-table-hero__title" dangerouslySetInnerHTML={{ __html: convertMarkdown(productTitle) }} />
      <ContentLoader flag="rating">
        <ProductRatings {...productRatingsAttrs} />
      </ContentLoader>
      <section className="rate-table-hero__rate-block">
        <div>
          <ContentLoader flag="rate">
            <Rate apy={apy.toFixed(2)} measure={MEASURE} className="rate-table-hero__rate" />
            {apySubtext
              && (
                <p
                  className="rate-table-hero__apySubtext"
                  dangerouslySetInnerHTML={{ __html: convertMarkdown(apySubtext) }}
                />
              )
            }
          </ContentLoader>
        </div>
        <Button to={link} button={text} skin={'hero'}/>
      </section>
      <footer>
        <ContentLoader flag="rate">
          <p
            className="rate-table-hero__apy-disclosure"
            dangerouslySetInnerHTML={{ __html: convertMarkdown(disclosure) }}
          />
        </ContentLoader>
      </footer>
    </article>
  );
};

export default RateTableHero;
