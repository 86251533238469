import React from 'react';
import ItemTitle from 'components/menu-item/helpers/item-title';
import Tiles from './helpers/tiles';
import './menu-item.scss';


const MenuItem = ({
  className,
  title,
  titleLevel,
  tiles,
  children,
  index,
  backLink,
}) => {
  const Title = title ? ItemTitle : () => null;
  const Content = tiles ? Tiles : () => null;

  return (
    <section className={`menu-item ${className || ''}`}>
      <Title {...{ title, titleLevel, backLink }} />
      <Content {...{ index, tiles }} />
      {children }
    </section>
  );
};

export default MenuItem;
