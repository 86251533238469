import { mutator } from 'satcheljs';
import irahycdContent from 'stores/pages/iras/products/irahycd';
import iraryrcdContent from 'stores/pages/iras/products/ira-raise-your-rate';
import hycdContent from 'stores/pages/cds/products/hycd';
import ryrcdContent from 'stores/pages/cds/products/ryrcd';
import UPDATE_TERM_TIER from 'actions/pages';

const productPageMap = {
  iraHighYield: (updating, updateTo) => {
    irahycdContent().hero.config[updating] = updateTo;
  },
  iraRaiseYourRate: (updating, updateTo) => {
    iraryrcdContent().hero.config[updating] = updateTo;
  },
  cd: (updating, updateTo) => {
    hycdContent().hero.config[updating] = updateTo;
  },
  raiseYourRateCD: (updating, updateTo) => {
    ryrcdContent().hero.config[updating] = updateTo;
  },
  default: () => {
    // console.log(`${updating} ${updateTo} unmatched`);
  },
};

export default mutator(UPDATE_TERM_TIER, ({ updating, updateTo, product }) => {
  (productPageMap[product] || productPageMap.default)(updating, updateTo);
});
