import React from 'react';
import resolveImagePath from 'utilities/resolve-image-path';
import PlainText from 'components/plain-text';
import HeadingLevel from 'components/utility-components/heading-level';
import './showcase-tile.scss';

const ShowcaseTile = ({
  modifier,
  heading,
  cards,
}) => (
  <div className="showcase-tile">
    {heading && <HeadingLevel titleLevel="h3" titleClass="heading" title={heading} />}
    <div className="cardWrapper">
      {cards.map(({ imgSrc, title, description }) => (
        <div key={`ShowcaseTile-${imgSrc}-${title}-${description}`} className={`innerContainer ${modifier}`}>
          <div className="textWrapper">
            <HeadingLevel titleLevel="h4" titleClass="title" title={title} />
            <PlainText className="description" copy={description} />
          </div>
          <div className="imageWrapper">
            <img className="showcase-image" aria-hidden="true" alt=" " src={resolveImagePath(imgSrc)} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default ShowcaseTile;
