import { createStore } from 'satcheljs';

const roboPortfoliosContent = {
  hero: {
    pageTitle: 'Robo Portfolios',
    config: {
      template: 'text-with-accent',
      product: 'onlineSavings',
    },
    content: {
      bgColor: 'invest',
      topCopy: 'Start investing with as little as',
      accent: '++$++100',
      label: 'Start investing with as little as $100.',
      cta: {
        skin: 'outline',
        text: 'Create Your Plan',
        url: 'ally://account_opening/INVA',
      },
    },
  },
  intro: {
    top: 'Automated investing.',
    bottom: 'No experience required.',
    label: 'Automated investing. No experience required.',
  },
  tile: {
    template: 'three-row-text',
    type: 'invest',
    copy: [
      {
        top: 'Start investing with',
        rate: '$100',
        bottom: 'No advisory fee. Professionally managed. 24/7 support.',
      },
    ],
  },
  cardSection: {
    title: 'our favorite features',
    titleLevel: 'h3',
    class: 'favorite-features',
    cards: [
      {
        icon: 'invest/dollarsign.svg',
        title: 'No advisory fee. No fine print. No, really.',
        copy: 'There are no advisory fees, annual charges or rebalancing fees, and 30% of your portfolio is set aside as an interest-earning cash buffer.',
      },
      {
        icon: 'invest/performance.svg',
        title: 'Professionally managed.',
        copy: 'We’ll recommend either a core, income, tax-optimized or socially-responsible portfolio based on what matters to you.',
      },
      {
        icon: 'invest/247support.svg',
        title: '24/7 support.',
        copy: 'Call, chat, or email us. General support is available, day or night. Plus, our portfolio specialists are here to help Monday – Friday, 8 am – 5:30 pm ET.',
      },
    ],
  },
  cashBufferTitle: {
    ptClassName: 'cash-buffer-title',
    title: 'An interest-earning cash cushion to sit on.',
  },
  cashBufferTopCopy: {
    copy: 'The cash we set aside earns interest at %s, and is meant to balance out potential risk should market conditions change. We’ll rebalance and add more money to your other investments as your cash grows, but your portfolio will always have about 30% cash.',
    className: 'cash-buffer-top',
  },
  cashBufferImg: {
    image: 'invest/cash-buffer.svg',
    class: 'center',
  },
  cashBufferBottomCopy: {
    className: 'cash-buffer-bottom',
    copy: 'The cash buffer is a permanent part of your portfolio and can’t be accessed like money in a traditional savings account. Rate is accurate as of %s, it’s variable and may change after the account is opened.',
  },
  goals: [
    'Tell us about your goals.',
    'We’ll recommend a personalized portfolio.',
  ],
  cta: {
    skin: 'blue',
    url: 'ally://account_opening/INVA',
    text: 'Create Your Plan',
  },
};

export default createStore('roboPortfoliosContent', roboPortfoliosContent);
