/* eslint-disable react/prop-types */
import React from 'react';
import PageTitleHeading from 'components/page-title-heading';
import './hero-card.scss';

const HeroCard = ({
  pageTitle,
  children,
  color = 'plum',
}) => (
  <header className={`hero-card hero-card--${color}`}>
    <PageTitleHeading title={pageTitle} />
    <div className="hero-card-content">
      {children}
    </div>
  </header>
);

export default HeroCard;
