import { action } from 'satcheljs';

// rates
export const GET_RATES_FIRST_TIME = action(
  'GET_RATES_FIRST_TIME',
);

export const STORE_RATES = action(
  'STORE_RATES',
  ratesResponse => (ratesResponse),
);

// ratings
export const GET_RATINGS_FIRST_TIME = action(
  'GET_RATINGS_FIRST_TIME',
);

export const STORE_RATINGS = action(
  'STORE_RATINGS',
  ratingsResponse => (ratingsResponse),
);

// loading/failure
export const SET_AS_LOADING = action(
  'SET_AS_LOADING',
  itemLoading => (itemLoading),
);

export const LOAD_FAILURE = action(
  'LOAD_FAILURE',
  failedAPI => (failedAPI),
);
