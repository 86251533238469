import React from 'react';
import { focusLoader } from '@ally/auth-sales-shared/utilities';
import { DATE, device } from 'utilities';
import Button from 'components/button';
import ContentLoader from '../../../../utility-components/content-loader';

/**
 * Returns a component for the main content area of the product rate hero template
 *   includes: rate, tier info, cta, effective date and what to do on rate fail
 *
 * @param {*} children info passed to the content loader either rate info or fail text and label
 * @param {string} tierInfo text displayed below the main rate `On all balance tiers`
 * @param {Button} cta button which requires `text` and `url`
 * @param {string} effectiveDate effective date of the rates (undefined will return current date)
 * @param {string} failTextLabel string to display if the rates should not load `Great rates on all balance tiers.`
 * @param {boolean} didLoad trigger to identify if rates loaded or failed
 * @param {string} focusType tells the focus-loader what to focus `exploreRate`
 */

// TODO: determine why effective date observer isn't working
const ProductRateBody = ({
  children,
  tierInfo,
  cta,
  effectiveDate,
  failTextLabel,
  didLoad,
  focusType,
}) => (
  <React.Fragment>
    <div
      className="row product-rate-info-row"
      ref={(element) => {
        focusLoader.loadFocus(element, focusType);
      }}
      aria-label={(!didLoad) ? failTextLabel : ''}
      tabIndex="0"
    >
      <ContentLoader
        label={failTextLabel}
        className="product-rate-info"
        flag="rate"
        loadAction="placeholder"
      >
        {children}
        <p aria-hidden={didLoad ? 'false' : 'true'} className="tier-info">{tierInfo}</p>
      </ContentLoader>

      { device === 'ios' && (
      <div className="text-center product-button">
        <Button to={cta.url} button={cta.text} />
      </div>
      ) }
    </div>
    <div className="row">
      <ContentLoader label={failTextLabel} className="product-disclosure" flag="rate" focusType={focusType} loadAction="tapOnFail">
        {`Annual Percentage Yield (APY) accurate as of ${effectiveDate || DATE}.`}
      </ContentLoader>
    </div>
    { device === 'android' && (
    <div className="text-center product-button">
      <Button to={cta.url} button={cta.text} />
    </div>
    ) }
  </React.Fragment>
);

export default ProductRateBody;
