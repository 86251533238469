import resolveImagePath from 'utilities/resolve-image-path';

const deconstructor = ({
  image,
  productName,
  product,
  reviewText,
  a11yText,
  cta,
  tierInfo,
  failText,
  failTextLabel,
  term,
  tier,
},
{
  effectiveDate,
  terms,
  ratingInfo,
},
didLoad) => {
  const termObj = { terms, term, tier };
  const ratesContent = !didLoad ? { failText, failTextLabel } : termObj;
  const img = resolveImagePath(image);
  const {
    overallRating,
    reviewCount,
  } = ratingInfo;

  return {
    productName,
    ratesInfo: {
      product,
      overallRating,
      reviewCount,
      reviewText,
      a11yText,
    },
    bodyInfo: {
      tierInfo,
      cta,
      effectiveDate,
      failTextLabel,
      didLoad,
    },
    ratesContent,
    headingContent: {
      img,
      productName,
    },
  };
};

export default deconstructor;
