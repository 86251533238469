import React from 'react';
import PlainText from 'components/plain-text';
import Copy from 'components/utility-components/copy';
import './bullet-list.scss';

const generateBullets = bullets => bullets.map((bullet, index) => <li key={`bullet-${index}`}><Copy>{bullet}</Copy></li>);

const BulletList = ({ title, bullets }) => (
  <div className="bullet-list">
    {title && <PlainText className="title" title={title} />}
    <ul className="bullet-list-items">
      {generateBullets(bullets)}
    </ul>
  </div>
);

export default BulletList;
