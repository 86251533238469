import { createStore } from 'satcheljs';
import { device } from 'utilities';

const isDroid = device === 'android';

const tierInfo = {
  ios: {
    tier1: 'Less than $5,000 opening deposit',
    tier2: '$5,000 minimum opening deposit',
    tier3: '$25,000 minimum opening deposit',
  },
  android: {
    tier1: 'Less than $5,000 opening deposit',
    tier2: '$5,000 minimum opening deposit',
    tier3: '$25,000 minimum opening deposit',
  },
};

const noPenaltyContent = {
  hero: {
    pageTitle: 'No Penalty CD',
    config: {
      bottomColor: 'cds',
      template: 'product-page-header',
      product: 'noPenaltyCD',
      extraInfo: '11-month term',
      includeRating: true,
    },
    content: {
      rateText: `%s${isDroid ? '++%++' : '%'} APY`,
      reviewText: '%s (%s Reviews)',
      a11yText: 'Rated %s out of 5 stars, based on %s reviews.',
      tiers: {
        ios: [
          'Less than $5,000',
          '$5,000 Minimum',
          '$25,000 Minimum',
        ],
        android: [
          'Less than $5,000',
          '$5,000 Minimum',
          '$25,000 Minimum',
        ],
      },
      tierInfo,
      hiddenText: tierInfo[device] || tierInfo,
      cta: {
        text: 'Open Account',
        url: 'ally://account_opening/NCD-%s',
      },
    },
    disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s.',
  },
  intro: {
    title: 'Smart savings. Penalty free.',
    titleLevel: 'h2',
    ptClassName: 'padding-bottom-small',
    className: 'not-nested',
    copy: 'Withdraw your full balance and interest earned any time after the first 6 days of funding your CD. Our No Penalty CD is available in the name of a @@Trust::https://www.ally.com/learn/trust-account/::@@.',
  },
  bullets: [
    'No minimum deposit required to open your account',
    'Grow your money faster with ##interest compounded daily::compoundedInterest::##',
    'Your deposits are insured by the ##FDIC::fdic::## up to the maximum allowed by law. @@Maximize your coverage::https://www.ally.com/bank/fdic/::@@',
  ],
  infoCardArea: {
    title: 'our favorite features',
    cards: [
      {
        icon: 'cds/greatrates.svg',
        title: 'Great rates',
        copy: 'Get the best rate we offer for your CD term and balance tier with the ##Ally Ten Day Best Rate Guarantee::bestRateGuarantee::## when you fund within 10 days of opening your CD. The Ally Ten Day Best Rate Guarantee also applies at renewal.',
      },
      {
        icon: 'cds/peaceofmind.svg',
        title: 'Peace of mind',
        copy: 'Our award-winning customer care is available 24/7.',
      },
      {
        icon: 'cds/manageonline.svg',
        title: 'Manage your CD online',
        copy: 'When your CD is ready for renewal, it’s easy to let us know what you want to do with your money online.',
      },
      {
        icon: 'cds/check.svg',
        title: 'Make deposits',
        copy: 'Deposit checks remotely with @@Ally eCheck Deposit℠::https://www.ally.com/bank/online-banking/how-to-bank-with-ally/::@@, or transfer money from an account you have at another institution.',
      },
      {
        icon: 'cds/nomaintenance.svg',
        title: 'No monthly maintenance fees',
        copy: "We don't charge monthly maintenance fees for your Ally Bank CD.",
      },
      {
        icon: 'cds/10day.svg',
        title: 'Ally 10 Day Best Rate Guarantee',
        copy: "When your CD matures, you can add or withdraw your money at no charge during a 10-day grace period. If you decide to renew your CD, don't do a thing, and we'll automatically renew it.",
      },
    ],
  },
  noHiddenFees: {
    title: 'Early Withdrawals',
    class: 'no-hidden-fees',
    text: [
      {
        ptClassName: 'padding-bottom',
        className: 'not-nested',
        copy: 'With our No Penalty CD, you will not be charged a penalty for early withdrawal.',
      },
      {
        ptClassName: 'padding-bottom',
        className: 'not-nested',
        copy: 'You can withdraw your full balance and interest any time after the first 6 days following the date you funded the account.',
      },
      {
        className: 'not-nested',
        copy: 'If you decide to renew your CD, don’t do a thing, and we’ll automatically renew it.',
      },
    ],
  },
};

export default createStore('noPenaltyContent', noPenaltyContent);
