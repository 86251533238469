import { orchestrator } from 'satcheljs';
import { GET_OFFERS, STORE_OFFERS } from 'actions/mbox';
import fetchMboxContent from '@ally/auth-sales-shared/utilities/js-utils/fetch-mbox';

// Validate stuff
import Ajv from 'ajv';
import heroSchema from '@ally/schema/src/hero.schema.json';
import hexSchema from '@ally/schema/src/hex.schema.json';

const ajv = new Ajv({
  schemas: [hexSchema],
});

// Generate heroSchema validator
const isHeroValid = ajv.compile(heroSchema);

// This will need to be moved to its own file as it grows
const activeOffers = [
  'ally-epnr-our-services-hero',
];

export default
orchestrator(GET_OFFERS, () => {
  activeOffers.forEach((mboxName) => {
    fetchMboxContent({ mboxName })
      .then((response) => {
        if (isHeroValid(response)) {
          STORE_OFFERS(mboxName, response);
        }
      });
  });
});
