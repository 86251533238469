import { afterDraw } from '@ally/auth-sales-shared/utilities';

/**
 * This class is exported as a singleton that will inject text into
 * an aria-live element. This element should be on the initial page,
 * as screen readers wont all track the changes if the element is injected
 * after page load
 *
 * @class Annoucer
 */
class Annoucer {
  /**
   * if there is no aria live element found or passed in,
   * throw an error
   *
   * @static
   * @memberof Annoucer
   */
  static throw() {
    throw new Error('No aria-live element to update');
  }

  /**
   * Creates an instance of Annoucer.
   *
   * @param {*} announcer
   * @memberof Annoucer
   */
  constructor(announcer) {
    this.name = 'ariaAnnounce';
    this.setAnnouncer(announcer);
    if (!this.isAriaElement()) {
      this.throw();
    }
  }

  /**
   * Creates a new announcer element if there is not one on the page
   *
   * NOTE: aria live works more reliably if there is an aria-live element in the page
   * when it initially loads, this is just a fallback for if it is missing for the browser
   * and screen reader combos that support it
   *
   * @returns
   * @memberof Annoucer
   */
  makeAnouncer() {
    const element = document.createElement('div');
    element.setAttribute('id', this.name);
    element.setAttribute('aria-live', 'polite');
    element.setAttribute('data-sr-only', '');
    document.body.append(element);
    return element;
  }

  /**
   * Checks if there is an aria live element to update
   *
   * @returns {Boolean} true if if there is an aria live element to update,
   * @memberof Annoucer
   */
  isAriaElement() {
    return this.announcer && this.announcer.hasAttribute('aria-live');
  }

  /**
   * sets the aria element that will recieve the text change
   *
   * @param {HTMLElement} announcer the aria live element to recieve text changes
   * @memberof Annoucer
   */
  setAnnouncer(announcer) {
    this.announcer = announcer || document.getElementById(this.name) || this.makeAnouncer();
  }

  /**
   * Checks if the element is correct and present,
   * and updates the text
   *
   * @param {*} text
   * @memberof Annoucer
   */
  announce(text) {
    if (this.isAriaElement()) {
      this.updateText(text);
    } else {
      this.throw();
    }
  }

  /**
   * updates the text of the aria live element in a manner that ensures it will read
   *
   * @param {String} text - the text to read
   * @memberof Annoucer
   */
  updateText(text) {
    this.announcer.innerText = '';
    afterDraw(() => {
      this.announcer.innerText = text;
      afterDraw(() => {
        this.announcer.innerText = '';
      });
    });
  }
}

const announcer = new Annoucer();

export default announcer;
