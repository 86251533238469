import { createStore } from 'satcheljs';

const homeRefinanceContent = {
  hero: {
    pageTitle: 'Mortgage Refinancing',
    config: {
      template: 'text-with-accent',
    },
    content: {
      bgColor: 'plum',
      topCopy: 'Lender fees are out. Saving money is in.',
      topCopyClass: 'title-full-width',
      description:
        "We believe money's better spent on a home. That's why, with us, lender fees are a thing of the past.",
      cta: {
        skin: 'outline',
        text: 'Get Pre-approved',
        // This URL is part of a handoff to the mobile app (Angry Nerds)
        // They redirect the user based on logic on their end

        // The URLs will need to be switched once Angry Nerds
        // finalize their changes for Mortgage Prefill
        url: 'ally://homeRefinance',
      },
    },
  },
  seeTheDifference: {
    modifier: 'imgContentPair--center',
    title: 'See the difference a new loan can make.',
    subtitle: "Find what's best for your home with our modern mortgage experience.",
    imgSrc: 'home/mortgage-devices.svg',
    imgClassName: 'img-left',
    ulClassName: 'custom-icon',
    bulletIcon: 'other/icon-plum-check.svg',
    listHeading:
      'Refinancing is simple. Complete your application 100% online anytime, from anywhere.',
    list: [
      'No lender fees - no application, origination, processing, or underwriting fees (unlike most big banks and lenders)',
      'Q&A application you can complete in as little as 15 minutes',
      'State-of-the-art technology that helps you avoid excessive paperwork',
      'Reliable support from a team of home loan experts',
    ],
  },
  refiForTheRightReason: {
    titleLevel: 'h2',
    title: 'Refinance for the right reason.',
    classNameTitle: 'title-large',
    ptClassName: 'center padding-bottom-small',
  },
  lowerPayments: {
    imgSrc: 'home/refinance_payments.svg',
    title: 'Lower monthly payments',
    copy: 'You may be able to get a lower rate so you can save money each month.',
    list: ['Increase monthly cash flow', 'Save for a live event or long-term goal'],
  },
  fasterPayoff: {
    imgSrc: 'home/refinance_payoff.svg',
    title: 'Pay off your loan faster.',
    copy: 'Change to a shorter term to pay off your home sooner.',
    list: ['Become mortgage-free faster', 'Lower the total amount of interest you pay over time'],
  },
  takeCashOut: {
    imgSrc: 'home/refinance_equity.svg',
    title: 'Take cash out.',
    copy: "Leverage your home's equity and get cash to use however you want.",
    list: [
      'Make home improvements',
      'Pay down high-interest credit card or personal loan',
      'Pay for yourself or someone else to go to school',
    ],
  },
  refiCalculator: {
    imgSrc: 'home/refinance_calc.svg',
    title: 'Refinance calculator',
    subTitle: 'Get a better idea of money matters before you take the next step.',
    copy:
      "To estimate how much refinancing could change your payment, we'll take a look at your current loan details and suggest a new loan term that may better fit your financial goals.",
    button: {
      skin: 'blue',
      button: 'Crunch the Numbers',
      to: 'https://www.ally.com/home-loans/refinance-calculator/',
      label: 'Crunch the Numbers',
    },
  },
};

export default createStore('homeRefinanceContent', homeRefinanceContent);
