import * as React from 'react';
import Hero from 'components/hero';
import Main from 'components/main';
import checkingContent from 'stores/pages/checking-product';
import productStore from 'stores/products/';
import MenuItem from 'components/menu-item';
import TableSections from 'components/table-section';
import { InfoCards } from 'components/info-card';
import BulletList from 'components/bullet-list';
import './checking.scss';
import { observer } from 'mobx-react';
import PlainText from 'components/plain-text/plain-text';

class Checking extends React.Component {
  constructor() {
    super();
    this.Content = checkingContent();
    this.Product = productStore().checking;
  }

  render() {
    const { effectiveDate } = this.Product;
    const {
      hero,
      introBlurb,
      bullets,
      infoCards: {
        title,
        cards,
      },
      tableSections,
    } = this.Content;
    const { match } = this.props;

    return (
      <div className="Checking">
        <Hero {...hero} {...match} effectiveDate={effectiveDate} />

        <Main>
          <PlainText {...introBlurb} />
          <BulletList bullets={bullets} />
          <MenuItem title={title}>
            <InfoCards cards={cards} />
          </MenuItem>
          <TableSections sections={tableSections} />
        </Main>
      </div>
    );
  }
}

export default observer(Checking);
