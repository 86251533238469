import { createStore } from 'satcheljs';
import { device } from 'utilities';

const isDroid = device === 'android';

const tierInfo = {
  ios: {
    tier1: 'Less than $5,000 opening deposit',
    tier2: '$5,000 minimum opening deposit',
    tier3: '$25,000 minimum opening deposit',
  },
  android: {
    tier1: 'Less than $5,000 opening deposit',
    tier2: '$5,000 minimum opening deposit',
    tier3: '$25,000 minimum opening deposit',
  },
};

const hycdContent = {
  hero: {
    pageTitle: 'High Yield CD',
    config: {
      bottomColor: 'cds',
      template: 'product-page-header',
      product: 'cd',
      termSelector: true,
      includeRating: true,
    },
    content: {
      rateText: `%s${isDroid ? '++%++' : '%'} APY`,
      reviewText: '%s (%s Reviews)',
      a11yText: 'Rated %s out of 5 stars, based on %s reviews.',
      tiers: {
        ios: [
          'Less than $5,000',
          '$5,000 Minimum',
          '$25,000 Minimum',
        ],
        android: [
          'Less than $5,000',
          '$5,000 Minimum',
          '$25,000 Minimum',
        ],
      },
      tierInfo,
      hiddenText: tierInfo[device] || tierInfo,
      cta: {
        text: 'Open Account',
        url: 'ally://account_opening/CD-%s',
      },
    },
    disclaimer: 'All Annual Percentage Yields (APY) accurate as of %s. Early withdrawal penalty will apply.',
  },
  intro: {
    ptClassName: 'padding-bottom',
    title: 'Maximize your earnings. Period.',
    titleLevel: 'h2',
    className: 'not-nested',
    copy: 'Our rates are consistently among the most competitive in the country, and our High Yield CD is available in the name of a @@Trust::https://www.ally.com/learn/trust-account/::@@.',
  },
  bullets: [
    'No minimum deposit required to open your account',
    'Grow your money faster with ##interest compounded daily::compoundedInterest::##',
    'Your deposits are insured by the ##FDIC::fdic::## up to the maximum allowed by law. @@Maximize your coverage::https://www.ally.com/bank/fdic/::@@',
    'APY is fixed for the term of the CD and is subject to change upon renewal',
  ],
  infoCardArea: {
    title: 'our favorite features',
    cards: [
      {
        icon: 'cds/greatrates.svg',
        title: 'Great rates',
        copy: 'Get the best rate we offer for your CD term and balance tier with the ##Ally Ten Day Best Rate Guarantee::bestRateGuarantee::## when you fund within 10 days of opening your CD. The Ally Ten Day Best Rate Guarantee also applies at renewal.',
      },
      {
        icon: 'cds/peaceofmind.svg',
        title: 'Peace of mind',
        copy: 'Our award-winning customer care is available 24/7.',
      },
      {
        icon: 'cds/manageonline.svg',
        title: 'Manage your CD online',
        copy: 'When your CD is ready for renewal, it’s easy to let us know what you want to do with your money online.',
      },
      {
        icon: 'cds/check.svg',
        title: 'Make deposits',
        copy: 'Deposit checks remotely with @@Ally eCheck Deposit℠::https://www.ally.com/bank/online-banking/how-to-bank-with-ally/::@@, or transfer money from an account you have at another institution.',
      },
      {
        icon: 'cds/nomaintenance.svg',
        title: 'No monthly maintenance fees',
        copy: "We don't charge monthly maintenance fees for your Ally Bank CD.",
      },
      {
        icon: 'cds/10day.svg',
        title: 'Ally 10 Day Best Rate Guarantee',
        copy: "When your CD matures, you can add or withdraw your money at no charge during a 10-day grace period. If you decide to renew your CD, don't do a thing, and we'll automatically renew it.",
      },
    ],
  },
  outro: {
    title: 'Early Withdrawals',
    content: [
      {
        ptClassName: 'padding-bottom',
        className: 'not-nested',
        copy: 'Withdrawals before the maturity date are subject to penalties. Partial withdrawals aren\'t allowed. ##Are there exceptions?::penaltyExceptions::##',
      },
      {
        ptClassName: 'padding-bottom',
        className: 'not-nested',
        copy: 'If you redeem your CD before the maturity date, the penalty we charge will depend on your CD term (see penalty schedule). ##How is the penalty calculated?::penaltyCalculation::##',
      },
      {
        ptClassName: 'padding-bottom',
        className: 'not-nested',
        copy: 'See the @@Ally Bank Deposit Agreement::https://www.ally.com/docs/bank/deposit-agreement/::@@ for full details on how we calculate the withdrawal penalty.',
      },
    ],
  },
  tableSections: [
    {
      sectionClass: 'no-dejarg',
      title: 'Purchased or Renewed Before 12/07/13',
      rows: [
        {
          heading: 'All CDs',
          content: '60 days of interest',
        },
      ],
    },
    {
      sectionClass: 'no-dejarg',
      title: 'Purchased or Renewed After 12/07/13',
      rows: [
        {
          heading: '24 months or less',
          content: '60 days of interest',
        },
        {
          heading: '25 – 36 months',
          content: '90 days of interest',
        },
        {
          heading: '37 – 48 months',
          content: '120 days of interest',
        },
        {
          heading: '49 months or longer',
          content: '150 days of interest',
        },
      ],
    },
  ],
  purchasedOrRenewed: {
    class: 'purchased-or-renewed',
    title: 'Purchased or Renewed',
    tabbedText: {
      heading: {
        title: 'Purchased or Renewed',
        titleLevel: 'h3',
      },
      product: 'purchased-or-renewed',
      template: 'list-item-template',
      tiers: [
        'Before 12/07/13',
        'After 12/07/13',
      ],
      tierInfo: {
        tier1: [
          {
            title: 'All CDs',
            copy: '60 days of interest',
          },
        ],
        tier2: [
          {
            title: '24 months or less',
            copy: '60 days of interest',
          },
          {
            title: '25 - 36 months',
            copy: '90 days of interest',
          },
          {
            title: '37 - 48 months',
            copy: '120 days of interest',
          },
          {
            title: '49 months or longer',
            copy: '150 days of interest',
          },
        ],
      },
      tableDisclaimer: 'The penalty we charge will depend on your CD term.',
    },
  },
};

export default createStore('hycdContent', hycdContent);
