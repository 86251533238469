/**
 *  Function used to add focus ability to first element of new page. Adds to button attributes.
 *  Will move focus and scroll to location of product and tier when button is clicked.
 *  Prevents page reload.
 *
 * @param {string} href '/savings/osa/'.
 *
 */

function jumpTo(href) {
  return () => {
    event.preventDefault();
    const target = document.querySelector(href);

    if (target) {
      target.focus();
      target.scrollIntoView();
    }
  };
}

export default jumpTo;
