import React from 'react';
import LinkTo from 'components/utility-components/link-to';
import './card.scss';
import { swapContent } from 'utilities';
import { cardLink } from 'components/utility-components/track';
import { TwoRowIcon, TwoRowText, ThreeRowText } from './templates';

const getTermLink = (link, tier, term) => {
  const configuredLink = swapContent(link, tier);
  return `${configuredLink}${term ? `/${term}` : ''}`;
};

const ApplyTemplate = ({
  template,
  copy,
  image,
  product,
  tier,
}) => {
  switch (template) {
    case 'two-row-icon':
      return <TwoRowIcon {...{ copy, image }} />;
    case 'three-row-text':
      return <ThreeRowText {...{ copy }} />;
    default: // default template
      return <TwoRowText {...{ copy, product, tier }} />;
  }
};

const Card = ({
  template,
  link,
  type,
  copy,
  image,
  product,
  tier,
}) => {
  const menuItemClass = `menu-item-link ${template || 'two-row-text'}`;
  const cardBottomClass = `bottom-color ${type}`;
  const templateParams = {
    template,
    copy,
    image,
    product,
    tier,
  };
  const { term } = copy;

  // NOTE: it is assumed that the icon cards do not contain dynamic content
  const nochange = template === 'two-row-icon';

  const linkAttrs = link ? {
    attrs: {
      schema: cardLink,
      nochange: nochange || null,
      text: copy,
    },
    to: getTermLink(link, tier, term),
    className: 'menu-item-wrapper card',
  }
    : null;

  const CardBody = (
    <div className="link-wrapper">
      <div className={menuItemClass}>
        <ApplyTemplate {...templateParams} />
      </div>
      <div className={cardBottomClass} />
    </div>
  );

  if (link) {
    return (
      <LinkTo {...linkAttrs}>
        {CardBody}
      </LinkTo>
    );
  }
  return (
    <div className="card">
      {CardBody}
    </div>
  );
};

export default Card;
