import React from 'react';
import Main from 'components/main';
import PageTitleHeading from 'components/page-title-heading';
import RenderList from './helpers/render-list';

import './selection-list.scss';

const SelectionList = ({
  match,
  history,
  title = 'Select term length',
}) => {

  return (
    <div className="SelectionList">
      <PageTitleHeading title={title} />
      <Main>
        <ul>
          <RenderList match={match} history={history} />
        </ul>
      </Main>
    </div>
  );
};

export default SelectionList;
