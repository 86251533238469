import React from 'react';
import './main.scss';

export default function Main({ children }) {
  return (
    <main id="main" className="main-content">
      {children}
    </main>
  );
}
