import * as React from 'react';
import Main from 'components/main';
import AccountType from 'components/account-type';
import PageTitleHeading from 'components/page-title-heading';
import UPDATE_TERM_TIER from 'actions/pages';
import { observer } from 'mobx-react';

const updateTo = undefined;
class Category extends React.Component {
  constructor({ categoryContent }) {
    super();
    const { accountTypes } = categoryContent();
    // clear any stored term/tiers
    // TODO: build a history/state manager for tiers and terms
    accountTypes.forEach(({ config: { product } }) => {
      UPDATE_TERM_TIER({ updating: 'term', updateTo, product });
      UPDATE_TERM_TIER({ updating: 'tier', updateTo, product });
    });
  }

  renderAcctTypes() {
    const { categoryContent, match: { params } } = this.props;
    const { accountTypes } = categoryContent();
    return accountTypes
      .map((account, index) => <AccountType key={index} {...params} {...account} />);
  }

  render() {
    const { title } = this.props;
    return (
      <div className="Category">
        <header>
          <PageTitleHeading title={title} />
        </header>
        <Main>
          { this.renderAcctTypes() }
        </Main>
      </div>
    );
  }
}

export default observer(Category);
