const swapContent = (string, dynamicContent) => {
  if (Array.isArray(dynamicContent)) {
    dynamicContent.forEach(itemReplacing => {
      // this will replace the first %s in string with the item from the array
      string = string.replace('%s', itemReplacing);
    });
    return string;
  }
  // this will replace all instances of %s with the string
  return string.replace(/%s/g, dynamicContent);
};

export default swapContent;
