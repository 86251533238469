import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { DATE } from 'utilities';
import Card from 'components/card';
import productStore from 'stores/products';
import SegmentedControl from 'components/segmented-control';
import ContentLoader from 'components/utility-components/content-loader';
import ProductHeader from 'components/product-header';
import DynamicContent from 'components/utility-components/dynamic-content/dynamic-content';
import { productRegion } from 'components/utility-components/track';
import { withRouter } from 'react-router-dom';
import AndroidButton from './helpers/android-button';

import './account-type.scss';

const { get: getSchema } = productRegion;

const ApyDisclaimer = ({ storeProduct = {}, disclaimer, product }) => {
  // TODO: Figure out why the date is not properly passed down sometimes
  const effectiveDate = storeProduct.effectiveDate || DATE;

  return (
    <ContentLoader flag="rate" className="disclaimer" loadAction="tapOnFail" id={product}>
      <span><DynamicContent dynamicContent={effectiveDate}>{disclaimer}</DynamicContent></span>
    </ContentLoader>
  );
};

class AccountType extends Component {
  constructor({ tier = 'tier1' }) {
    super();
    this.state = { tier };
    this.updateTier = this.updateTier.bind(this);
    this.Store = productStore();
  }

  updateTier(tier) {
    this.setState({ tier });
    this.props.history.replace(`/${this.props.history.location.pathname.split('/')[1]}/${tier}`);
  }

  renderCards(tiles, config) {
    if (tiles.length > 1) {
      return (
        <ul role="list" className="horizontal-list">
          {/* Although redundant - ul and li roles are needed to fix Safari A11y
          bug where it doesn't read as a list when ul is set to display: inline */}
          {tiles.map((tile, index) => (
            <li role="listitem" key={index} className="horizontal-list-item">
              <Card {...this.state} {...config} {...tile} />
            </li>
          ))}
        </ul>
      );
    }
    return tiles.map((tile, index) => <Card key={index} {...this.state} {...config} {...tile} />);
  }


  render() {
    const {
      template,
      region,
      header,
      cta,
      tiles,
      tiers,
      intro,
      disclosure,
      config,
      disclaimer,
      hiddenText,
      tier,
      subheading,
    } = this.props;

    const { product = false } = config;
    const storeProduct = this.Store[product];
    const overallRating = storeProduct ? storeProduct.ratingInfo.overallRating : null;
    const reviewCount = storeProduct ? storeProduct.ratingInfo.reviewCount : null;
    const sectionClass = `accountType ${product} ${template || ''}`;

    return (
      <section {...getSchema({ text: region })} className={sectionClass}>
        <div className="menu-content-wrapper">
          <ProductHeader
            {...config}
            cta={cta}
            header={header}
            overallRating={overallRating}
            reviewCount={reviewCount}
            subheading={subheading}
          />

          {intro && <p className="intro">{intro}</p>}

          {template === 'multi-tile-scroll'
              && (
              <SegmentedControl
                product={product}
                tiers={tiers}
                handleClick={this.updateTier}
                hiddenText={hiddenText}
                selectedTier={tier}
              />
              )
            }

          <div className="tile-area-wrapper">
            <div className="tile-area">
              {this.renderCards(tiles, config)}
            </div>
          </div>

          {product && (
            <ApyDisclaimer
              storeProduct={storeProduct}
              disclaimer={disclaimer}
              product={product}
            />
          )}

          {disclosure && <p className="disclosure">{disclosure}</p>}

          <AndroidButton {...cta} />

        </div>
      </section>
    );
  }
}

export default withRouter(observer(AccountType));
