import React from 'react';
import CustomIcon from './helpers/custom-icon';
import RegularList from './helpers/regular-list';
import HeadingLevel from '../utility-components/heading-level';

/**
 * Creates list based on props passed in
 *
 * @param {Array} info array of strings - content for the list items `info = ['Trades', 'Services']`
 * @param {String} classname classname for ul `className = 'List'`
 * @param {url} listImage url to an icon to replace bullets `listImage = icons/icon-check.svg`
 *
 * @returns JSX elements if info exists
 *
 * <ul className="List">
 *    <li>`Trades`</li>
 *    <li>`Services`</li>
 * </ul>
 */

const SimpleList = ({
  info, ulClassName, liClassName, bulletIcon, listHeading = ""
}) => {
  if (!info) {
    return null;
  }

  return (
    // Although redundant - ul and li roles are needed to fix Safari A11y
    // bug where it doesn't read as a list when ul is set to display: inline
    <div className="outer-list-container">
      {listHeading && <HeadingLevel titleLevel="h3" title={listHeading} titleClass="title simple-list-heading" />}
      <ul role="list" className={ulClassName}>
        {bulletIcon
          ? <CustomIcon className={liClassName} bulletIcon={bulletIcon} info={info} />
          : <RegularList liClassName={liClassName} info={info} />}
      </ul>
    </div>
  );
};

export default SimpleList;
