const envMap = {
  production: 'https://explore-products-rates.ally.com/auth-sales-widget/1.0.0/',
  widget: 'http://localhost:9191/auth-sales-widget/1.0.0/',
  development: 'https://dev.explore-products-rates.ally.com/auth-sales-widget/1.0.0/',
  staging: 'https://mod.explore-products-rates.ally.com/auth-sales-widget/1.0.0/',
};

const root = (() => {
  const { origin, hostname } = window.location;

  const isProduction = hostname === 'secure.ally.com';
  const isLocal = hostname === 'localhost';
  const isCit3 = hostname.includes('secure-cit3');
  // yarn start for auth sales widget will run on 10000,
  // and for auth sales web it is 9098
  const isLocalSite = origin.includes('10000') || origin.includes('9098');
  // true if running locally inside of AOS
  const isLocalWidget = isLocal && !isLocalSite;
  // `secure-fix` is the current dev/testing lle
  // `secure-cit3.ally.com` is an exception to the `isMod` rule, as it is also used for dev
  const isDev = hostname.includes('dev') || hostname.includes('fix') || isCit3;
  // mod/staging environment
  const isMod = hostname.match(/^secure-\w+\.(int\.)?ally.com$/);

  if (isProduction) {
    return envMap.production;
  }

  if (isLocalWidget) {
    return envMap.widget;
  }

  if (isDev) {
    return envMap.development;
  }

  if (isMod) {
    return envMap.staging;
  }

  if (isLocalSite) {
    if (origin[origin.length - 1] !== '/') {
      return `${origin}/`;
    }

    return origin;
  }

  // if for some reason no cases are matched,
  // we default to production
  return envMap.production;
})();

// receive an image path and return the relevant url
// paths could be absolute or relative
const resolveImagePath = (path) => {
  const absolutePathRegex = /^https?:\/\/.+$/;
  const prefixedRelativePathRegEx = /^assets\/images\/.+$/;

  // if we were provided a path that looks like a URL, let's assume it is
  // and return the path so we can load the image
  if (absolutePathRegex.test(path)) {
    return path;
  }

  // if we were provided a relative path with the assets/images
  // directories, we can just require that image directly
  if (prefixedRelativePathRegEx.test(path)) {
    return root + require(`${path}`);
  }

  // if we were provided a relative path without the assets/images prefix
  // we'll need to generate the correct relative path and require the image
  return root + require(`assets/images/${path}`);
};

export default resolveImagePath;
