import React from 'react';
import HeadingLevel from 'components/utility-components/heading-level';
import Copy from 'components/utility-components/copy';
import './block-quote.scss';

const BlockQuote = ({
  title, titleLevel, subTitle, text, bgColor, modifier,
}) => (
  <div className={`quoteContainer ${bgColor} ${modifier}`}>
    {title && <HeadingLevel title={title} titleLevel={titleLevel} titleClass="quoteTitle" />}
    {subTitle && <p className="quoteSubTitle">{subTitle}</p>}
    {text && text.map(line => <p key={`BlockQuote-text-${line}`} className="quoteText"><Copy>{line}</Copy></p>)}
  </div>
);

export default BlockQuote;
