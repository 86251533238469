import React from 'react';
import {
  downloadLink,
  interstitialLink,
  standardLink,
  getChildText,
} from 'components/utility-components/track';


/**
 * Check if a string begins with certain substring(s)
 *
 * NOTE: IE doesn't support Array.prototype.startsWith and babel-polyfill is a big asset
 *
 * @param {String} baseString - the base string to check the beginning of
 * @param {Array} terms - an array of strings to check if the base string begins with
 * @returns {Boolean} - true if the base string begins with any of the given terms
 */
function startsWith(baseString, terms) {
  return terms.some(term => baseString.substring(0, term.length) === term);
}

/**
 * Check if a string ends with certain substring(s)
 *
 * NOTE: IE doesn't support Array.prototype.endsWith and babel-polyfill is a big asset
 *
 * @param {String} baseString - the base string to check the beginning of
 * @param {Array} terms - an array of strings to check if the base string begins with
 * @returns {Boolean} - true if the base string begins with any of the given terms
 */
function endsWith(baseString, terms) {
  const baseLength = baseString.length;

  return terms.some((term) => {
    const termLength = term.length;

    if (termLength > baseLength) {
      return false;
    }

    return baseString.substring(baseLength - termLength, baseLength) === term;
  });
}


/**
 * retrieve the correct tagging attributes for a download link
 *
 * @param {String} [href=''] - the location to the file
 * @returns {Object} - the data attibutes to give to a download link for tagging
 */
function downloadAttrs(href = '') {
  const { valFrom, get: getSchema } = downloadLink;
  return getSchema({ text: valFrom({ href }) });
}


/**
 * retrieve the correct tagging attributes for a standard link
 *
 * @param {JSXElement} children - the child nodes or text content of the link
 * @param {Object} schema - the tagging schema associated with whatever link type attributes are needed
 * @returns {Object} - the data attibutes to give to a link for tagging
 */
function linkAttrs(children, schema) {
  const { get: getSchema } = schema;
  const text = getChildText(children);
  return getSchema({ text });
}


/**
 * Determine the appropriate tagging attributes to retrieve, and return them
 *
 * @param {JSXElement} children - the child nodes or text content of the link
 * @param {Object} { href:String } - the href value of the link being rendered
 * @returns {Object} - the data attibutes to give to a link for tagging
 */
function getAttrs(children, { href }) {
  if (endsWith(href, ['.pdf'])) {
    return downloadAttrs(href);
  }
  return linkAttrs(children, interstitialLink);
}


/**
 * determine if the tagging attributes needed are for a standard link or a non standard link,
 * and return the appropriate data attributes
 *
 * @param {String} href - the href value of the link being rendered
 * @param {JSXElement} children - the child nodes or text content of the link
 * @param {Object} attributes - the element attributes for the link being rendered
 * @returns {Object} - the data attibutes to give to a link for tagging
 */
function findSchema(href, children, attributes) {
  if (startsWith(href, ['https://www.ally.com/', 'http://www.ally.com/', 'ally://'])) {
    return linkAttrs(children, standardLink);
  }

  return getAttrs(children, attributes);
}


/**
 * Create a standard anchor tag with the appropriate tagging attributes
 * and other html attributes based on the type of link it is (as determined by the href)
 *
 * @param {Object} props = {
 *  attributes:Object - the base html attributes to use for this link
 *  children:JSXElement - the child nodes or text content of the link
 * }
 *
 * example input:
 *
 * <LinkExternal
 *   attributes={{
 *     'aria-label': 'example label'
 *     className: "some-class"
 *     'data-track-trigger': 'interstitial'
 *     href: 'http://www.nfa.futures.org/'
 *   }}
 * />
 *   <span>Link text and stuff</span>
 * </LinkExternal>
 *
 * example output:
 * <a aria-label="example label" class="some-class" data-track-elem="link" data-track-trigger="interstitial" href="http://www.nfa.futures.org/" data-track-name="" target="_blank">
 *    <span>Link text and stuff</span>
 * </a>
 *
 * @returns {JSXElement} - an anchor link with the appropriate html attributes and tagging specific data attributes
 */
function LinkExternal({ attributes, children }) {
  const { href } = attributes;
  const track = findSchema(href, children, attributes);

  const attrs = Object.assign(attributes, track);

  const trackAttrs = {
    'data-track-elem': 'link',
    'data-track-trigger': 'internal',
  };

  return <a {...trackAttrs} {...attrs} target="_blank">{children}</a>;
}

export default LinkExternal;
