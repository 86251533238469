import { orchestrator } from 'satcheljs';
import { GET_RATINGS_FIRST_TIME, STORE_RATINGS, LOAD_FAILURE } from 'actions/products';
import { testReviews, globalParams } from 'utilities';
import { RATINGS_URL } from '../utility/environment-variables';

const axios = require('axios');

const {
  device,
  cif,
} = globalParams;

export default
orchestrator(GET_RATINGS_FIRST_TIME, () => {
  axios.get(RATINGS_URL, {
    // timeout is set to 20s per UX
    timeout: 20000,
    headers: {
      'X-device': device,
      'X-cif': cif,
    },
  })
    .then((response, err) => {
      if (err) throw err;

      return response.data;
    }).then((jsonResponse) => {
      // testReviews is a globalVar for testing
      // TODO: move testReviews to dev environment store
      const REVIEWS = testReviews;

      if (REVIEWS !== 'loading') {
        if (REVIEWS === 'failed') {
          LOAD_FAILURE({ api: 'rating' });
        } else {
          STORE_RATINGS(jsonResponse);
        }
      }
    }).catch((err) => {
      console.log('Get ratings failed', err);
      LOAD_FAILURE({ api: 'rating' });
    });
});
